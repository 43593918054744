import React from 'react'

function Footer() {
  return (
    <div> <footer class="main-footer">
    <strong>Copyright &copy; 2020 <a href="#">Samadhan Academy, Indore</a>.</strong>
    All rights reserved.

    
</footer></div>
  )
}

export default Footer