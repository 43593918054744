import React, { useState, useEffect } from "react";
import Sidebar from "../Headerfooter/Sidebar";
import axios from "axios";
import yourhandle from "countrycitystatejson";
import storage from "./Firebase";

function Registration(props) {
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("newtoken") ? true : false
    if (isLoggedIn===false) {
      props.history.push("/")
    }
  }, []);
  const [list, setList] = useState(1);
  const eno = `SAR-${list}`;
  const getData = () => {
    axios
      .get("https://ajiosys.in/api/studentdetails")
      .then((res) =>
        res.data.data.filter((i) => i.Register === true)
          ? setList(res.data.data.filter((i) => i.Register === true).length + 1)
          : null
      );
  };

  useEffect(() => {
    getData();
  }, []);

  const uploadImage1 = (x) => {
    const image1 = x;
    if (image1 !== "") {
      const uploadTask = storage.ref(`images/${image1.name}`).put(image1);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          setProgress1(
            Math.ceil((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
          );
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(image1.name)
            .getDownloadURL()
            .then((url) =>
              setPostItem({
                ...postItem,
                Photo: url,
              })
            );
        }
      );
    } else {
      alert("no image selected");
    }
  };

  const uploadImage2 = (x) => {
    const image1 = x;
    if (image1 !== "") {
      const uploadTask = storage.ref(`images/${image1.name}`).put(image1);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          setProgress2(
            Math.ceil((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
          );
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(image1.name)
            .getDownloadURL()
            .then((url) =>
              setPostItem({
                ...postItem,
                IDProof: url,
              })
            );
        }
      );
    } else {
      alert("no image selected");
    }
  };
  const uploadImage3 = (x) => {
    const image1 = x;
    if (image1 !== "") {
      const uploadTask = storage.ref(`images/${image1.name}`).put(image1);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          setProgress3(
            Math.ceil((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
          );
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(image1.name)
            .getDownloadURL()
            .then((url) =>
              setPostItem({
                ...postItem,
                AddressProof: url,
              })
            );
        }
      );
    } else {
      alert("no image selected");
    }
  };

  const [progress1, setProgress1] = useState("");
  const [progress2, setProgress2] = useState("");
  const [progress3, setProgress3] = useState("");
  const [activeTab, setActiveTab] = useState(1);
  const getStateList = () => {
    const list = yourhandle.getStatesByShort("IN");

    return list.map((i) => <option>{i}</option>);
  };
  const getCityList = () => {
    const list = yourhandle.getCities("IN", postItem.State);
    return list.map((i) => <option>{i}</option>);
  };
  useEffect(() => {
    getCourses();
  }, []);
  const getCourses = () => {
    axios
      .get("https://ajiosys.in/api/courses")
      .then((res) => setCourses(res.data.data));
  };

  const [courses, setCourses] = useState([]);
  const [postItem, setPostItem] = useState({});
  const handleChange = (e) => {
    setPostItem({ ...postItem, [e.target.name]: e.target.value });
  };
  const postData = () => {
    const item = { ...postItem, Register: true, EnrollmentNumber: eno };
    axios
      .post("https://ajiosys.in/api/studentdetails", item)
      .then(() => props.history.push(""));
  };
  return (
    <div>
      {" "}
      <Sidebar props={props} />
      <section className="content" style={{ paddingLeft: "20%" }}>
        <div className="container-fluid">
          <div className="row">
            {/* /.col */}
            <div className="col-md-12">
              <div className="card">
                <div className="card-header p-2">
                  <ul className="nav nav-pills">
                    <li className="nav-item" onClick={() => setActiveTab(1)}>
                      <a
                        className={`${
                          activeTab === 1 ? "active" : ""
                        } nav-link`}
                        data-toggle="tab"
                      >
                        Student Registration
                      </a>
                    </li>
                    <li className="nav-item" onClick={() => setActiveTab(2)}>
                      <a
                        className={`${
                          activeTab === 2 ? "active" : ""
                        } nav-link`}
                        data-toggle="tab"
                      >
                        Fees Structure
                      </a>
                    </li>
                  </ul>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  <div className="tab-content">
                    <div
                      className={`${activeTab === 1 ? "active" : ""} tab-pane`}
                      id="activity"
                    >
                      <section className="content">
                        <div className="container-fluid">
                          {/* SELECT2 EXAMPLE */}
                          <div className="card card-default">
                            <div className="card-header">
                              <h3 className="card-title">
                                Student Course Details
                              </h3>
                            </div>
                            {/* /.card-header */}
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Enrollment Number</label>
                                    <input
                                      value={eno}
                                      readOnly="readOnly"
                                      className="form-control"
                                      placeholder="Enrollment Number"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Enrollment Date</label>
                                    <input
                                      type="date"
                                      name="EnrollmentDate"
                                      value={postItem.EnrollmentDate}
                                      onChange={handleChange}
                                      className="form-control"
                                      placeholder="Enrollment Date"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Course</label>
                                    <select
                                      name="Course"
                                      value={postItem.Course}
                                      onChange={handleChange}
                                      className="form-control select2"
                                      style={{ width: "100%" }}
                                    >
                                      <option value="" disabled selected>
                                        -Select-
                                      </option>
                                      {courses.map((item) => (
                                        <option value="UPSC">
                                          {item.title}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Medium</label>
                                    <select
                                      name="ctl00$First$ddMedium"
                                      id="First_ddMedium"
                                      className="form-control select2"
                                      style={{ width: "100%" }}
                                    >
                                      <option value="Hindi">Hindi</option>
                                      <option value="English">English</option>
                                    </select>
                                  </div>
                                  {/* /.form-group */}
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Batch</label>
                                    <select
                                      name="Batch"
                                      value={postItem.Batch}
                                      onChange={handleChange}
                                      className="form-control select2"
                                      style={{ width: "100%" }}
                                    >
                                      <option value="Morning">Morning</option>
                                      <option value="Afternoon">
                                        Afternoon
                                      </option>
                                      <option value="Evening">Evening</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Batch Time</label>
                                    <input
                                      type="time"
                                      name="BatchTime"
                                      value={postItem.BatchTime}
                                      onChange={handleChange}
                                      className="form-control"
                                      placeholder="Batch Time"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section className="content">
                        <div className="container-fluid">
                          <div className="card card-default">
                            <div className="card-header">
                              <h3 className="card-title">
                                Student General Details
                              </h3>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Student Name</label>
                                    <input
                                      name="StudentName"
                                      value={postItem.StudentName}
                                      onChange={handleChange}
                                      className="form-control"
                                      placeholder="Student Name"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Date of Birth</label>
                                    <input
                                      name="DateofBirth"
                                      value={postItem.DateofBirth}
                                      onChange={handleChange}
                                      type="date"
                                      id="First_txtDOB"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Gender</label>
                                    <select
                                      name="Gender"
                                      value={postItem.Gender}
                                      onChange={handleChange}
                                      className="form-control select2"
                                      style={{ width: "100%" }}
                                    >
                                      <option value="" disabled selected>
                                        -Select-
                                      </option>
                                      <option value="Male">Male</option>
                                      <option value="Female">Female</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Blood Group</label>
                                    <select
                                      name="BloodGroup"
                                      value={postItem.BloodGroup}
                                      onChange={handleChange}
                                      className="form-control select2"
                                      style={{ width: "100%" }}
                                    >
                                      <option value="" disabled selected>
                                        -Select-
                                      </option>
                                      <option value="A+">A+</option>
                                      <option value="B+">A-</option>
                                      <option value="A+">B+</option>
                                      <option value="B+">B-</option>
                                      <option value="A+">O+</option>
                                      <option value="B+">O-</option>
                                      <option value="A+">AB+</option>
                                      <option value="B+">AB-</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Category</label>
                                    <select
                                      name="Category"
                                      value={postItem.Category}
                                      onChange={handleChange}
                                      className="form-control select2"
                                      style={{ width: "100%" }}
                                    >
                                      <option value="" disabled selected>
                                        -Select-
                                      </option>
                                      <option value="General">General</option>
                                      <option value="OBC">OBC</option>
                                      <option value="SC/ST">SC/ST</option>
                                      <option value="Others">Others</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Email ID</label>
                                    <input
                                      name="EmailID"
                                      value={postItem.EmailID}
                                      onChange={handleChange}
                                      className="form-control"
                                      placeholder="Email ID"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Mobile No.</label>
                                    <input
                                      name="MobileNo"
                                      value={postItem.MobileNo}
                                      onChange={handleChange}
                                      className="form-control"
                                      placeholder="Mobile"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Alternate No.</label>
                                    <input
                                      name="AlternateNo"
                                      value={postItem.AlternateNo}
                                      onChange={handleChange}
                                      className="form-control"
                                      placeholder="Mobile"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Qualification</label>
                                    <input
                                      name="Qualification"
                                      value={postItem.Qualification}
                                      onChange={handleChange}
                                      className="form-control"
                                      placeholder="Qualification"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Occupation</label>
                                    <input
                                      name="Occupation"
                                      value={postItem.Occupation}
                                      onChange={handleChange}
                                      className="form-control"
                                      placeholder="Occupation"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Father's Name</label>
                                    <input
                                      name="FathersName"
                                      value={postItem.FathersName}
                                      onChange={handleChange}
                                      className="form-control"
                                      placeholder="Father's Name"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Mother's Name</label>
                                    <input
                                      name="MotherName"
                                      value={postItem.MotherName}
                                      onChange={handleChange}
                                      className="form-control"
                                      placeholder="Mother's Name"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Father Occupation</label>
                                    <input
                                      name="FatherOccupation"
                                      value={postItem.FatherOccupation}
                                      onChange={handleChange}
                                      className="form-control"
                                      placeholder="Father's Occupation"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Father Contact</label>
                                    <input
                                      name="FatherContact"
                                      value={postItem.FatherContact}
                                      onChange={handleChange}
                                      type="number"
                                      className="form-control"
                                      placeholder="Father's Contact"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>State</label>
                                    <select
                                      name="State"
                                      value={postItem.State}
                                      onChange={handleChange}
                                      className="form-control select2"
                                      style={{ width: "100%" }}
                                    >
                                      <option value="" disabled selected>
                                        -Select State-
                                      </option>
                                      {getStateList()}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>City</label>

                                    <select
                                      name="City"
                                      value={postItem.City}
                                      onChange={handleChange}
                                      className="form-control"
                                    >
                                      <option value="" disabled selected>
                                        -Select City/Town-
                                      </option>
                                      {getCityList()}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Address</label>
                                    <input
                                      name="Address"
                                      value={postItem.Address}
                                      onChange={handleChange}
                                      className="form-control"
                                      placeholder="Address"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>PIN</label>
                                    <input
                                      type="number"
                                      name="PIN"
                                      value={postItem.PIN}
                                      onChange={handleChange}
                                      className="form-control"
                                      placeholder="PIN"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Active Status</label>
                                    <select
                                      name="PIN"
                                      value={postItem.PIN}
                                      onChange={handleChange}
                                      className="form-control select2"
                                      style={{ width: "100%" }}
                                    >
                                      <option>Yes</option>
                                      <option>No</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Password</label>
                                    <input
                                      name="Password"
                                      value={postItem.Password}
                                      onChange={handleChange}
                                      className="form-control"
                                      placeholder="Password"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section className="content">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-12">
                              <div className="card">
                                <div className="card-header">
                                  <h3 className="card-title">
                                    Upload Documents
                                  </h3>
                                </div>
                                {/* /.card-header */}
                                <div className="card-body">
                                  <div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>Photo</label>
                                        <br />
                                        <input
                                          type="file"
                                          onChange={(e) =>
                                            uploadImage1(e.target.files[0])
                                          }
                                        />
                                        <img
                                          src={postItem.Photo}
                                          style={{
                                            borderRadius: 20,
                                            height: 100,
                                            width: 100,
                                          }}
                                          alt=""
                                          className="img-responsive img-rounded"
                                        />
                                      </div>
                                      <div class="progress ">
                                        <div
                                          class="progress-bar"
                                          style={{ width: `${progress1}%` }}
                                        ></div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>ID Proof</label>
                                        <br />
                                        <input
                                          type="file"
                                          onChange={(e) =>
                                            uploadImage2(e.target.files[0])
                                          }
                                        />
                                        <img
                                          src={postItem.IDProof}
                                          style={{
                                            borderRadius: 20,
                                            height: 100,
                                            width: 100,
                                          }}
                                          alt=""
                                          className="img-responsive img-rounded"
                                        />
                                      </div>
                                      <div class="progress ">
                                        <div
                                          class="progress-bar"
                                          style={{ width: `${progress2}%` }}
                                        ></div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>Address Proof</label>
                                        <br />
                                        <input
                                          type="file"
                                          onChange={(e) =>
                                            uploadImage3(e.target.files[0])
                                          }
                                        />
                                        <img
                                          src={postItem.AddressProof}
                                          style={{
                                            borderRadius: 20,
                                            height: 100,
                                            width: 100,
                                          }}
                                          alt=""
                                          className="img-responsive img-rounded"
                                        />
                                      </div>
                                      <div class="progress ">
                                        <div
                                          class="progress-bar"
                                          style={{ width: `${progress3}%` }}
                                        ></div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <br />

                                      <div className="card-footer">
                                        <button
                                          onClick={() => postData()}
                                          className="btn btn-primary"
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* /.card-body */}
                              </div>
                              {/* /.card */}
                            </div>
                            {/* /.col */}
                          </div>
                          {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                      </section>
                      {/* /.post */}
                    </div>
                    {/* /.tab-pane */}
                    <div
                      className={`${activeTab === 2 ? "active" : ""} tab-pane`}
                      id="fees"
                    >
                      <section className="content">
                        <div className="container-fluid">
                          {/* SELECT2 EXAMPLE */}
                          <div className="card card-default">
                            <div className="card-header">
                              <h3 className="card-title">Fees Details</h3>
                              |
                              <span id="First_Label1" />
                            </div>
                            {/* /.card-header */}
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Course</label>
                                    <input
                                      name="ctl00$First$txtCourse"
                                      type="text"
                                      readOnly="readonly"
                                      id="First_txtCourse"
                                      className="form-control"
                                      placeholder="Course"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Course Duration</label>
                                    <input
                                      name="ctl00$First$txtDuration"
                                      type="text"
                                      id="First_txtDuration"
                                      className="form-control"
                                      placeholder="Duration"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Total Fees</label>
                                    <input
                                      name="ctl00$First$txtFees"
                                      type="number"
                                      id="First_txtFees"
                                      className="form-control"
                                      placeholder="Total Fees"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Tax</label>
                                    <select
                                      name="ctl00$First$ddTax"
                                      id="First_ddTax"
                                      className="form-control select2"
                                      style={{ width: "100%" }}
                                    >
                                      <option value="Tax Not Applied">
                                        Tax Not Applied
                                      </option>
                                      <option value={1}>1</option>
                                      <option value={2}>2</option>
                                      <option value={3}>3</option>
                                      <option value={4}>4</option>
                                      <option value={5}>5</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Amount After Tax</label>
                                    <input
                                      name="ctl00$First$txtTaxAmt"
                                      type="number"
                                      id="First_txtTaxAmt"
                                      className="form-control"
                                      placeholder="Amount After Tax"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Discount</label>
                                    <input
                                      name="ctl00$First$txtDiscount"
                                      type="number"
                                      id="First_txtDiscount"
                                      className="form-control"
                                      placeholder="Discount"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Received Amount</label>
                                    <input
                                      name="ctl00$First$txtRamt"
                                      type="number"
                                      id="First_txtRamt"
                                      className="form-control"
                                      placeholder="Received Amount"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Net Amount</label>
                                    <input
                                      name="ctl00$First$txtNAmt"
                                      type="number"
                                      id="First_txtNAmt"
                                      className="form-control"
                                      placeholder="Net Amount"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <select
                                      name="ctl00$First$ddInstallments"
                                      id="First_ddInstallments"
                                      className="form-control select2"
                                      style={{ width: "100%" }}
                                    >
                                      <option value="Full Payment">
                                        Full Payment
                                      </option>
                                      <option value={2}>2</option>
                                      <option value={3}>3</option>
                                      <option value={4}>4</option>
                                      <option value={5}>5</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group"></div>
                                </div>
                              </div>
                              {/* /.row */}
                              {/* /.row */}
                            </div>
                            {/* /.card-body */}
                          </div>
                          {/* /.card */}
                          {/* SELECT2 EXAMPLE */}
                          {/* /.card */}
                          {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                      </section>
                      <section className="content">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-md-10">
                              <div className="card">
                                <div className="card-header">
                                  <h3 className="card-title">
                                    Installment Details:{" "}
                                  </h3>
                                </div>
                                {/* /.card-header */}
                                <div className="card-body">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th style={{ width: 10 }}>#</th>
                                        <th>Installment Date</th>
                                        <th>Amount</th>
                                        <th style={{ width: 40 }}>Paid</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>1.</td>
                                        <td>########</td>
                                        <td>
                                          <span className="badge bg-danger">
                                            0000.00
                                          </span>
                                        </td>
                                        <td>
                                          <span className="badge bg-danger">
                                            0000.00
                                          </span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>2.</td>
                                        <td>########</td>
                                        <td>
                                          <span className="badge bg-primary">
                                            0000.00
                                          </span>
                                        </td>
                                        <td>
                                          <span className="badge bg-primary">
                                            0000.00
                                          </span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>3.</td>
                                        <td>########</td>
                                        <td>
                                          <span className="badge bg-success">
                                            0000.00
                                          </span>
                                        </td>
                                        <td>
                                          <span className="badge bg-success">
                                            0000.00
                                          </span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>4.</td>
                                        <td>########</td>
                                        <td>
                                          <span className="badge bg-danger">
                                            0000.00
                                          </span>
                                        </td>
                                        <td>
                                          <span className="badge bg-danger">
                                            0000.00
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                {/* /.card-body */}
                              </div>
                              {/* /.card */}
                              {/* /.card */}
                            </div>
                            <div className="col-md-6">
                              <br />
                              <div className="card-footer">
                                <button
                                  onClick={() => postData()}
                                  className="btn btn-primary"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                  {/* /.tab-content */}
                </div>
                {/* /.card-body */}
              </div>
              {/* /.nav-tabs-custom */}
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>
      {/* /.row */}
      {/* /.row */}
    </div>
  );
}

export default Registration;
