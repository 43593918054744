import React, { useState } from "react";
import logo from "../assets/img/AdminLTELogo.png";
import user from "../assets/img/user2-160x160.jpg";

function Sidebar(props) {
  const [navTree, setNavTreeData] = useState("");
  const setNavTree = (x) => {
    if (x !== navTree) {
      setNavTreeData(x);
    } else {
      setNavTreeData("");
    }
  };
  const [showNav, setShowNav] = useState(
    window.innerWidth > 700 ? true : false
  );

  const logout = () => {
    localStorage.removeItem("newtoken")
    props.props.history.push("")
  }

             
              
              
              
              
              
              
              
              
            
  return (
    <div style={{ position: "fixed", height: "100vh" }}>
      <div>
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
          <ul className="navbar-nav">
            <li
              className="nav-item showbars"
              onClick={() => setShowNav(!showNav)}
            >
              {window.innerWidth > 700 ? null : <i className="fa fa-bars" />}
            </li>
          </ul>
        </nav>
      </div>
      {showNav ? (
        <aside
          className={`${window.innerWidth > 700 ? "main-sidebar" : ""
            } sidebar-dark-primary elevation-4`}
        >
          <a href="index3.html" className="brand-link">
            <img
              src={logo}
              alt="AdminLTE Logo"
              className="brand-image img-circle elevation-3"
              style={{ opacity: ".8" }}
            />
            <span className="brand-text font-weight-light">Admin Panel</span>
          </a>

          <div className="sidebar">
            <div className="user-panel mt-3 pb-3 mb-3 d-flex">
              <div className="image">
                <img
                  src={user}
                  className="img-circle elevation-2"
                  alt="User Image"
                />
              </div>
              <div className="info">
                <a className="d-block">Mr. Amit Ojha</a>
              </div>
            </div>

            <nav className="mt-2">
              <ul
                className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="false"
              >
                <li className="nav-item has-treeview menu-open">
                  <a
                    onClick={() => props.props.history.push("")}
                    className="nav-link active"
                  >
                    <i className="nav-icon fa fa-tachometer-alt" />
                    <p className="nav-icon1">Dashboard</p>
                  </a>
                </li>

                <li className="nav-item has-treeview">
                  <a
                    className="nav-link"
                    onClick={() => setNavTree("Students")}
                  >
                    <p className="nav-icon1">
                      Students
                      <i className="right fa fa-angle-down" />
                    </p>
                  </a>
                  <ul className={navTree === "Students" ? "" : "nav-treeview"}>
                   {localStorage.getItem("studentEnquiryview")==="yes"? <li className="navitem1">
                   {localStorage.getItem("studentEnquiryadd")==="yes"?
                      <a
                        onClick={() => props.props.history.push("/enquiry")}
                        className="nav-link1"
                      >
                        <i className="nav-icon fa fa-plus" />
                      </a>:<a
                        
                        className="nav-link1"
                      >
                        <i className="nav-icon fa" />
                      </a>}
                      <a
                        onClick={() => props.props.history.push("/enquirylist")}
                        className="nav-link1"
                      >
                        <p className="nav-icon1">Enquiry</p>
                      </a>
                    </li>:null}
                    {localStorage.getItem("studentDemoview")==="yes"? <li className="navitem1">
                    {localStorage.getItem("studentDemoadd")==="yes"?
                      <a
                        onClick={() => props.props.history.push("/demo")}
                        className="nav-link1"
                      >
                        <i className="nav-icon fa fa-plus" />
                      </a>:<a
                        
                        className="nav-link1"
                      >
                        <i className="nav-icon fa" />
                      </a>}
                      <a
                        onClick={() => props.props.history.push("/demolist")}
                        className="nav-link1"
                      >
                        <p className="nav-icon1">Demo</p>
                      </a>
                    </li>:null}
                    {localStorage.getItem("studentRegistrationview")==="yes"? <li className="navitem1">
                    {localStorage.getItem("studentRegistrationadd")==="yes"?
                      <a
                        onClick={() =>
                          props.props.history.push("/registration")
                        }
                        className="nav-link1"
                      >
                        <i className="nav-icon fa fa-plus" />
                      </a>:<a
                        
                        className="nav-link1"
                      >
                        <i className="nav-icon fa" />
                      </a>}
                      <a
                        onClick={() =>
                          props.props.history.push("/registrationlist")
                        }
                        className="nav-link1"
                      >
                        <p className="nav-icon1">Registration</p>
                      </a>
                    </li>:null}
                    {localStorage.getItem("studentIcardview")==="yes"?<li className="navitem1">
                    {localStorage.getItem("studentIcardadd")==="yes"?
                      <a
                        onClick={() => props.props.history.push("/icard")}
                        className="nav-link1"
                      >
                        <i className="nav-icon fa fa-plus" />
                      </a>:<a
                        
                        className="nav-link1"
                      >
                        <i className="nav-icon fa" />
                      </a>}
                      <a
                        onClick={() => props.props.history.push("/icard")}
                        className="nav-link1"
                      >
                        <p className="nav-icon1">I-card</p>
                      </a>
                    </li>:null}
                    {/* <li className="navitem1">
                      <a
                        onClick={() => props.props.history.push("/attendence")}
                        className="nav-link1"
                      >
                        <i className="nav-icon fa fa-plus" />
                      </a>
                      <a
                        onClick={() => props.props.history.push("/attendence")}
                        className="nav-link1"
                      >
                        <p className="nav-icon1">Attendence</p>
                      </a>
                    </li>

                    <li className="navitem1">
                      <a
                        onClick={() => props.props.history.push("/exam")}
                        className="nav-link1"
                      >
                        <i className="nav-icon fa fa-plus" />
                      </a>
                      <a
                        onClick={() => props.props.history.push("/exam")}
                        className="nav-link1"
                      >
                        <p className="nav-icon1">Exam</p>
                      </a>
                    </li>
                    <li className="navitem1">
                      <a
                        onClick={() => props.props.history.push("/results")}
                        className="nav-link1"
                      >
                        <i className="nav-icon fa fa-plus" />
                      </a>
                      <a
                        onClick={() => props.props.history.push("/results")}
                        className="nav-link1"
                      >
                        <p className="nav-icon1">results</p>
                      </a>
                    </li> */}
                  </ul>
                </li>
                <li className="nav-item has-treeview">
                  <a className="nav-link" onClick={() => setNavTree("Staff")}>
                    <p className="nav-icon1">
                      Staff
                      <i className="fa fa-angle-down right" />
                    </p>
                  </a>
                  <ul className={navTree === "Staff" ? "" : "nav-treeview"}>
                  {localStorage.getItem("staffApplicationview")==="yes"? <li className="navitem1">
                  {localStorage.getItem("staffApplicationadd")==="yes"?
                      <a
                        onClick={() => props.props.history.push("/application")}
                        className="nav-link1"
                      >
                        <i className="nav-icon fa fa-plus" />
                      </a>:<a
                        
                        className="nav-link1"
                      >
                        <i className="nav-icon fa" />
                      </a>}
                      <a
                        onClick={() =>
                          props.props.history.push("/applicationlist")
                        }
                        className="nav-link1"
                      >
                        <p className="nav-icon1">Application</p>
                      </a>
                    </li>:null}

                    {localStorage.getItem("staffDemoview")==="yes"? <li className="navitem1">
                    {localStorage.getItem("staffDemoadd")==="yes"?
                      <a
                        onClick={() => props.props.history.push("/staffdemo")}
                        className="nav-link1"
                      >
                        <i className="nav-icon fa fa-plus" />
                      </a>:<a
                        
                        className="nav-link1"
                      >
                        <i className="nav-icon fa" />
                      </a>}
                      <a
                        onClick={() =>
                          props.props.history.push("/staffdemolist")
                        }
                        className="nav-link1"
                      >
                        <p className="nav-icon1">Demo</p>
                      </a>
                    </li>:null}
                    {localStorage.getItem("staffRegistrationview")==="yes"? <li className="navitem1">
                    {localStorage.getItem("staffRegistrationadd")==="yes"?
                      <a
                        onClick={() =>
                          props.props.history.push("/staffregistration")
                        }
                        className="nav-link1"
                      >
                        <i className="nav-icon fa fa-plus" />
                      </a>:<a
                        
                        className="nav-link1"
                      >
                        <i className="nav-icon fa" />
                      </a>}
                      <a
                        onClick={() =>
                          props.props.history.push("/staffregistrationlist")
                        }
                        className="nav-link1"
                      >
                        <p className="nav-icon1">Registration</p>
                      </a>
                    </li>:null}

                    {localStorage.getItem("staffSalaryview")==="yes"? <li className="navitem1">
                    {localStorage.getItem("staffSalaryadd")==="yes"?
                      <a
                        onClick={() => props.props.history.push("/salary")}
                        className="nav-link1"
                      >
                        <i className="nav-icon fa fa-plus" />
                      </a>:<a
                        
                        className="nav-link1"
                      >
                        <i className="nav-icon fa" />
                      </a>}
                      <a
                        onClick={() => props.props.history.push("/salary")}
                        className="nav-link1"
                      >
                        <p className="nav-icon1">Salary</p>
                      </a>
                    </li>:null}
                  </ul>
                </li>

                <li className="nav-item has-treeview">
                  <a
                    className="nav-link"
                    onClick={() => setNavTree("Inventory")}
                  >
                    <p className="nav-icon1">
                      Accounts
                      <i className="fa fa-angle-down right" />
                    </p>
                  </a>
                  <ul className={navTree === "Inventory" ? "" : "nav-treeview"}>
                  {localStorage.getItem("accountsFeesCollectionview")==="yes"?<li className="navitem1">
                  {localStorage.getItem("accountsFeesCollectionadd")==="yes"?
                      <a
                        onClick={() =>
                          props.props.history.push("/feescollection")
                        }
                        className="nav-link1"
                      >
                        <i className="nav-icon fa fa-plus" />
                      </a>:<a
                        
                        className="nav-link1"
                      >
                        <i className="nav-icon fa" />
                      </a>}
                      <a
                        onClick={() =>
                          props.props.history.push("/feescollection")
                        }
                        className="nav-link1"
                      >
                        <p className="nav-icon1">Fees collection</p>
                      </a>
                    </li>:null}
                    {localStorage.getItem("accountsSalaryDistributionview")==="yes"?<li className="navitem1">
                    {localStorage.getItem("accountsSalaryDistributionadd")==="yes"?
                      <a
                        onClick={() =>
                          props.props.history.push("/distribution")
                        }
                        className="nav-link1"
                      >
                        <i className="nav-icon fa fa-plus" />
                      </a>:<a
                        
                        className="nav-link1"
                      >
                        <i className="nav-icon fa" />
                      </a>}
                      <a
                        onClick={() =>
                          props.props.history.push("/distribution")
                        }
                        className="nav-link1"
                      >
                        <p className="nav-icon1"> Salary distribution</p>
                      </a>
                    </li>:null}
                    {localStorage.getItem("accountsDailyExpensesview")==="yes"?<li className="navitem1">
                    {localStorage.getItem("accountsDailyExpensesadd")==="yes"?
                      <a
                        onClick={() =>
                          props.props.history.push("/dailyexpences")
                        }
                        className="nav-link1"
                      >
                        <i className="nav-icon fa fa-plus" />
                      </a>:<a
                        
                        className="nav-link1"
                      >
                        <i className="nav-icon fa" />
                      </a>}
                      <a
                        onClick={() =>
                          props.props.history.push("/dailyexpences")
                        }
                        className="nav-link1"
                      >
                        <p className="nav-icon1">Daily expences</p>
                      </a>
                    </li>:null}
                    {localStorage.getItem("accountsHandoverAmountview")==="yes"?<li className="navitem1">
                    {localStorage.getItem("accountsHandoverAmountadd")==="yes"?
                      <a
                        onClick={() =>
                          props.props.history.push("/handoveramount")
                        }
                        className="nav-link1"
                      >
                        <i className="nav-icon fa fa-plus" />
                      </a>:<a
                        
                        className="nav-link1"
                      >
                        <i className="nav-icon fa" />
                      </a>}
                      <a
                        onClick={() =>
                          props.props.history.push("/handoveramount")
                        }
                        className="nav-link1"
                      >
                        <p className="nav-icon1">Handover amount</p>
                      </a>
                    </li>:null}
                  </ul>
                </li>
                {localStorage.getItem("roletitle")==="Super Admin"? <li className="nav-item has-treeview">
                <a
                    className="nav-link"
                    onClick={() =>
                      props.props.history.push("/role")
                    }
                  >
                    <p className="nav-icon1">
                      Role
                      <i className="fa fa-angle-right right" />
                    </p>
                  </a>
                </li>:
                localStorage.getItem("rolesview")==="yes"?<li className="nav-item has-treeview">
                  <a
                    className="nav-link"
                    onClick={() =>
                      props.props.history.push("/roles")
                    }
                  >
                    <p className="nav-icon1">
                      Role
                      <i className="fa fa-angle-right right" />
                    </p>
                  </a>
                </li>:null}
                {localStorage.getItem("roletitle")==="Super Admin"? <li className="nav-item has-treeview">
                  <a className="nav-link" onClick={() =>
                    props.props.history.push("/user")
                  }>
                    <p className="nav-icon1">
                      User
                      <i className="fa fa-angle-right right" />
                    </p>
                  </a>
                </li>:
                localStorage.getItem("userview")==="yes"?<li className="nav-item has-treeview">
                  <a className="nav-link" onClick={() =>
                    props.props.history.push("/user1")
                  }>
                    <p className="nav-icon1">
                      User
                      <i className="fa fa-angle-right right" />
                    </p>
                  </a>
                </li>:null}
                
                <li className="nav-item has-treeview">
                  <a className="nav-link" onClick={() => logout()}>
                    <p className="nav-icon1">
                      Logout
                      <i className="fa fa-sign-out right" />
                    </p>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </aside>
      ) : null}
    </div>
  );
}

export default Sidebar;
