import axios from "axios";
import React, { useState, useEffect } from "react";
import {  useParams } from "react-router-dom";
import Sidebar from "../Headerfooter/Sidebar";
import { baseUrl } from '../BaseUrl'


const AccessModal = (props) => {
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("newtoken") ? true : false
    if (isLoggedIn===false) {
      props.history.push("/")
    }
  }, []);
  const config = {
    headers: {
      "Content-Type": "Application/json",
      "x-auth-token": `${localStorage.getItem("token")}`,
    },
  };

  const params = useParams();
  const [form, setForm] = useState({
    roleTitle: "Admin",
    adminbranch: "",
    permissions: {
      studentEnquiry: [],
      studentDemo: [],
      studentRegistration: [],
      studentIcard: [],
      staffApplication: [],
      staffDemo: [],
      staffRegistration: [],
      staffSalary: [],
      accountsFeesCollection: [],
      accountsSalaryDistribution: [],
      accountsDailyExpenses: [],
      accountsHandoverAmount: [],
      roles: [],
      user: [],
    },
  });
  const allpermissions = 
    {
      roleTitle: "Admin",
      adminbranch: form.adminbranch,
      permissions: {
      studentEnquiry: ["view","add","edit","delete"],
      studentDemo: ["view","add","edit","delete"],
      studentRegistration: ["view","add","edit","delete"],
      studentIcard: ["view","add","edit","delete"],
      staffApplication: ["view","add","edit","delete"],
      staffDemo: ["view","add","edit","delete"],
      staffRegistration: ["view","add","edit","delete"],
      staffSalary: ["view","add","edit","delete"],
      accountsFeesCollection: ["view","add","edit","delete"],
      accountsSalaryDistribution: ["view","add","edit","delete"],
      accountsDailyExpenses: ["view","add","edit","delete"],
      accountsHandoverAmount: ["view","add","edit","delete"],
      roles: ["view","add","edit","delete"],
      user: ["view","add","edit","delete"],
    }
    }

  useEffect(() => {
    if (params.id) {
      getRoleById()
    }
  }, []);

  const getRoleById = () => {
   axios
      .get(baseUrl+`/api/role/${params.id}`, config)
      .then((response) => {
        if (response.data) {
         setForm(response.data.data)
        
        }
      })
      .catch((err) => {
        alert("error");
      });
  };
  const handleChange1 = (e) => {
    const form_ = { ...form };
    form_.adminbranch = e.target.value;
    setForm(form_);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked, value } = e.target;
    let form_ = { ...form };
    let permissions = form_.permissions;
    if (checked) {
      permissions[name].push(value);
    } else {
      permissions[name] = permissions[name].filter((item) => item !== value);
    }
    setForm(form_);
  };

  const handleSubmit = () => {
    if(form.adminbranch!==""){
    if(params.id){
      axios
        .put(baseUrl+"/api/role/"+params.id, form)
        .then((response) => {
          props.history.push("/role");
        })
        .catch(function (error) {
          alert("error");
        });
    }else{
      axios
        .post(baseUrl+"/api/role", form)
        .then((response) => {
          props.history.push("/role");
        })
        .catch(function (error) {
          alert("error");
        });
    }}
    
  };

  const closeModal = () => {
    props.history.push("/roles");
  };

  return (
    <>
    
      <Sidebar props={props}/>
    
    <div className="content-wrapper" style={{marginLeft:"15%"}}>
      <div className="modal-wrapper">
        <h3 className="modal-title">{params?.id ? "View" : "Add"} Role</h3>
        <div>
          <div class="container" style={{ textAlign: "center" }}>
            < input style={{marginRight:15}}
            name="adminbranch"
            type="text"
            placeholder="Enter Branch Code"
            className="modal-input"
            value={form?.adminbranch}
            onChange={handleChange1}
          />
            <div class="row" style={{ padding: "50px", paddingTop: "unset" }}>
              <div class="col-12">
                <button onClick={()=>setForm(allpermissions)}>select all</button>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col"><h5 style={{width:100}}> Sr.no</h5></th>
                      <th scope="col"><h5 style={{width:100}}> Permission</h5></th>
                      <th scope="col"><h5 style={{width:100}}> View </h5></th>
                      <th scope="col"><h5 style={{width:100}}>  Add </h5></th>
                      <th scope="col"><h5 style={{width:100}}> Edit </h5></th>
                      <th scope="col"><h5 style={{width:100}}>Delete</h5></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Student Enquiry</td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="studentEnquiry"
                            value="view"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.studentEnquiry?.includes(
                              "view"
                            )}
                          />
                          <label htmlFor="">View</label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="studentEnquiry"
                            value="add"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.studentEnquiry?.includes(
                              "add"
                            )}
                          />
                          <label htmlFor="">Add</label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="studentEnquiry"
                            value="edit"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.studentEnquiry?.includes(
                              "edit"
                            )}
                          />
                          <label htmlFor="">Edit</label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="studentEnquiry"
                            value="delete"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.studentEnquiry?.includes(
                              "delete"
                            )}
                          />
                          <label htmlFor="">Delete</label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Student Demo</td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="studentDemo"
                            value="view"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.studentDemo?.includes(
                              "view"
                            )}
                          />
                          <label htmlFor="">View</label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="studentDemo"
                            value="add"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.studentDemo?.includes(
                              "add"
                            )}
                          />
                          <label htmlFor="">Add</label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="studentDemo"
                            value="edit"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.studentDemo?.includes(
                              "edit"
                            )}
                          />
                          <label htmlFor="">Edit</label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="studentDemo"
                            value="delete"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.studentDemo?.includes(
                              "delete"
                            )}
                          />
                          <label htmlFor="">Delete</label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Student Registration</td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="studentRegistration"
                            value="view"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.studentRegistration?.includes(
                              "view"
                            )}
                          />
                          <label htmlFor="">View</label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="studentRegistration"
                            value="add"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.studentRegistration?.includes(
                              "add"
                            )}
                          />
                          <label htmlFor="">Add</label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="studentRegistration"
                            value="edit"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.studentRegistration?.includes(
                              "edit"
                            )}
                          />
                          <label htmlFor="">Edit</label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="studentRegistration"
                            value="delete"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.studentRegistration?.includes(
                              "delete"
                            )}
                          />
                          <label htmlFor="">Delete</label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Student Icard</td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="studentIcard"
                            value="view"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.studentIcard?.includes(
                              "view"
                            )}
                          />
                          <label htmlFor="">View</label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="studentIcard"
                            value="add"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.studentIcard?.includes(
                              "add"
                            )}
                          />
                          <label htmlFor="">Add</label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="studentIcard"
                            value="edit"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.studentIcard?.includes(
                              "edit"
                            )}
                          />
                          <label htmlFor="">Edit</label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="studentIcard"
                            value="delete"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.studentIcard?.includes(
                              "delete"
                            )}
                          />
                          <label htmlFor="">Delete</label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Staff Application</td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="staffApplication"
                            value="view"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.staffApplication?.includes("view")}
                          />
                          <label htmlFor="">View</label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="staffApplication"
                            value="add"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.staffApplication?.includes("add")}
                          />
                          <label htmlFor="">Add</label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="staffApplication"
                            value="edit"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.staffApplication?.includes("edit")}
                          />
                          <label htmlFor="">Edit</label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="staffApplication"
                            value="delete"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.staffApplication?.includes(
                              "delete"
                            )}
                          />
                          <label htmlFor="">Delete</label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Staff Demo</td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="staffDemo"
                            value="view"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.staffDemo?.includes(
                              "view"
                            )}
                          />
                          <label htmlFor="">View</label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="staffDemo"
                            value="add"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.staffDemo?.includes(
                              "add"
                            )}
                          />
                          <label htmlFor="">Add</label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="staffDemo"
                            value="edit"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.staffDemo?.includes(
                              "edit"
                            )}
                          />
                          <label htmlFor="">Edit</label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="staffDemo"
                            value="delete"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.staffDemo?.includes(
                              "delete"
                            )}
                          />
                          <label htmlFor="">Delete</label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Staff Registration</td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="staffRegistration"
                            value="view"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.staffRegistration?.includes(
                              "view"
                            )}
                          />
                          <label htmlFor="">View</label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="staffRegistration"
                            value="add"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.staffRegistration?.includes(
                              "add"
                            )}
                          />
                          <label htmlFor="">Add</label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="staffRegistration"
                            value="edit"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.staffRegistration?.includes(
                              "edit"
                            )}
                          />
                          <label htmlFor="">Edit</label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="staffRegistration"
                            value="delete"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.staffRegistration?.includes(
                              "delete"
                            )}
                          />
                          <label htmlFor="">Delete</label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>Staff Salary</td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="staffSalary"
                            value="view"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.staffSalary?.includes(
                              "view"
                            )}
                          />
                          <label htmlFor="">View</label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="staffSalary"
                            value="add"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.staffSalary?.includes(
                              "add"
                            )}
                          />
                          <label htmlFor="">Add</label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="staffSalary"
                            value="edit"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.staffSalary?.includes(
                              "edit"
                            )}
                          />
                          <label htmlFor="">Edit</label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="staffSalary"
                            value="delete"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.staffSalary?.includes(
                              "delete"
                            )}
                          />
                          <label htmlFor="">Delete</label>
                        </div>
                      </td>
                      
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>Accounts Fees Collection</td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="accountsFeesCollection"
                            value="view"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.accountsFeesCollection?.includes(
                              "view"
                            )}
                          />
                          <label htmlFor="">View</label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="accountsFeesCollection"
                            value="add"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.accountsFeesCollection?.includes(
                              "add"
                            )}
                          />
                          <label htmlFor="">Add</label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="accountsFeesCollection"
                            value="edit"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.accountsFeesCollection?.includes(
                              "edit"
                            )}
                          />
                          <label htmlFor="">Edit</label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="accountsFeesCollection"
                            value="delete"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.accountsFeesCollection?.includes(
                              "delete"
                            )}
                          />
                          <label htmlFor="">Delete</label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>Accounts Salary Distribution</td>
                      <td >
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="accountsSalaryDistribution"
                            value="view"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.accountsSalaryDistribution?.includes(
                              "view"
                            )}
                          />
                          <label htmlFor="">View</label>
                        </div>
                      </td>
                      <td >
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="accountsSalaryDistribution"
                            value="add"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.accountsSalaryDistribution?.includes(
                              "add"
                            )}
                          />
                          <label htmlFor="">Add</label>
                        </div>
                      </td>
                      <td >
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="accountsSalaryDistribution"
                            value="edit"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.accountsSalaryDistribution?.includes(
                              "edit"
                            )}
                          />
                          <label htmlFor="">Edit</label>
                        </div>
                      </td>
                      <td >
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="accountsSalaryDistribution"
                            value="delete"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.accountsSalaryDistribution?.includes(
                              "delete"
                            )}
                          />
                          <label htmlFor="">Delete</label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>Accounts Daily Expences</td>
                      <td >
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="accountsDailyExpenses"
                            value="view"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.accountsDailyExpenses?.includes(
                              "view"
                            )}
                          />
                          <label htmlFor="">View</label>
                        </div>
                      </td>
                      <td >
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="accountsDailyExpenses"
                            value="add"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.accountsDailyExpenses?.includes(
                              "add"
                            )}
                          />
                          <label htmlFor="">Add</label>
                        </div>
                      </td>
                      <td >
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="accountsDailyExpenses"
                            value="edit"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.accountsDailyExpenses?.includes(
                              "edit"
                            )}
                          />
                          <label htmlFor="">Edit</label>
                        </div>
                      </td>
                      <td >
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="accountsDailyExpenses"
                            value="delete"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.accountsDailyExpenses?.includes(
                              "delete"
                            )}
                          />
                          <label htmlFor="">Delete</label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>Accounts Handover Amount</td>
                      <td >
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="accountsHandoverAmount"
                            value="view"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.accountsHandoverAmount?.includes(
                              "view"
                            )}
                          />
                          <label htmlFor="">View</label>
                        </div>
                      </td>
                      <td >
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="accountsHandoverAmount"
                            value="add"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.accountsHandoverAmount?.includes(
                              "add"
                            )}
                          />
                          <label htmlFor="">Add</label>
                        </div>
                      </td>
                      <td >
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="accountsHandoverAmount"
                            value="edit"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.accountsHandoverAmount?.includes(
                              "edit"
                            )}
                          />
                          <label htmlFor="">Edit</label>
                        </div>
                      </td>
                      <td >
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="accountsHandoverAmount"
                            value="delete"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.accountsHandoverAmount?.includes(
                              "delete"
                            )}
                          />
                          <label htmlFor="">Delete</label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>13</td>
                      <td>Roles</td>
                      <td >
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="roles"
                            value="view"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.roles?.includes(
                              "view"
                            )}
                          />
                          <label htmlFor="">View</label>
                        </div>
                      </td>
                      <td >
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="roles"
                            value="add"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.roles?.includes(
                              "add"
                            )}
                          />
                          <label htmlFor="">Add</label>
                        </div>
                      </td>
                      <td >
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="roles"
                            value="edit"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.roles?.includes(
                              "edit"
                            )}
                          />
                          <label htmlFor="">Edit</label>
                        </div>
                      </td>
                      <td >
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="roles"
                            value="delete"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.roles?.includes(
                              "delete"
                            )}
                          />
                          <label htmlFor="">Delete</label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>14</td>
                      <td>User</td>
                      <td >
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="user"
                            value="view"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.user?.includes(
                              "view"
                            )}
                          />
                          <label htmlFor="">View</label>
                        </div>
                      </td>
                      <td >
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="user"
                            value="add"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.user?.includes(
                              "add"
                            )}
                          />
                          <label htmlFor="">Add</label>
                        </div>
                      </td>
                      <td >
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="user"
                            value="edit"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.user?.includes(
                              "edit"
                            )}
                          />
                          <label htmlFor="">Edit</label>
                        </div>
                      </td>
                      <td >
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="user"
                            value="delete"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.user?.includes(
                              "delete"
                            )}
                          />
                          <label htmlFor="">Delete</label>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {/* {JSON.stringify(form)} */}
                <button
                  onClick={()=>handleSubmit()}
                  style={{ margin: "0 auto" }}
                  className="btn btn-success"
                >Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default AccessModal;
