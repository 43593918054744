import React, { useState, useEffect } from "react";
import Sidebar from "../Headerfooter/Sidebar";
import axios from "axios";
import yourhandle from "countrycitystatejson";

// EnquiryNumber:
// Course:
// StudentName:
// Medium:
// DateofBirth:
// EmailID:
// FathersName:
// MotherName:
// MobileNo:
// Gender:
// Category:
// Address:
// City:
// State:
// Qualification:
// Occupation:
// Reference:
// CourseFees:
// Discount:
// AlternateNo:
// NetAmount:
// ContactNo:
// Remark:
// DemoNumber:
// DemoDate:
// Batch:
// BatchTime:
// EnrollmentNumber:
// EnrollmentDate:
// FatherOccupation:
// FatherContact:
// PIN:
// ActiveStatus:
// Password:
// Photo:
// IDProof:
// AddressProof:

function Enquiry(props) {
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("newtoken") ? true : false
    if (isLoggedIn===false) {
      props.history.push("/")
    }
  }, []);
  const [list, setList] = useState(1);
  const eno = `SAE-${list}`;
  const getData = () => {
    axios
      .get("https://ajiosys.in/api/studentdetails")
      .then((res) =>
        res.data.data.filter((i) => i.Enquiry === true)
          ? setList(res.data.data.filter((i) => i.Enquiry === true).length + 1)
          : null
      );
  };

  useEffect(() => {
    getData();
  }, []);

  const getStateList = () => {
    const list = yourhandle.getStatesByShort("IN");

    return list.map((i) => <option>{i}</option>);
  };
  const getCityList = () => {
    const list = yourhandle.getCities("IN", postItem.State);
    return list.map((i) => <option>{i}</option>);
  };
  useEffect(() => {
    getCourses();
  }, []);
  const getCourses = () => {
    axios
      .get("https://ajiosys.in/api/courses")
      .then((res) => setCourses(res.data.data));
  };
  const d = new Date();
  const eDate = `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
  const [courses, setCourses] = useState([]);
  const [postItem, setPostItem] = useState({});
  const handleChange = (e) => {
    setPostItem({ ...postItem, [e.target.name]: e.target.value });
  };
  const postData = () => {
    const item = {
      ...postItem,
      EnquiryDate: eDate,
      Enquiry: true,
      EnquiryNumber: eno,
    };
    axios
      .post("https://ajiosys.in/api/studentdetails", item)
      .then(() => props.history.push(""));
  };
  return (
    <div>
      <Sidebar props={props} />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Student Enquiry</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right" />
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="tab-content">
                          <div className="active tab-pane" id="activity">
                            <section className="content">
                              <div className="container-fluid">
                                <div className="card card-default">
                                  <div className="card-header">
                                    <h3 className="card-title">
                                      Please enter new enquiry details:
                                    </h3>
                                    <span
                                      id="First_lblMsg"
                                      style={{
                                        color: "Red",
                                        fontWeight: "bold",
                                      }}
                                    />
                                  </div>
                                  <div className="card-body">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Enquiry Number</label>
                                          <input
                                            value={eno}
                                            readOnly="readOnly"
                                            className="form-control"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Course</label>
                                          <select
                                            name="Course"
                                            value={postItem.Course}
                                            onChange={handleChange}
                                            className="form-control select2"
                                            style={{ width: "100%" }}
                                          >
                                            <option value="" disabled selected>
                                              -Select-
                                            </option>
                                            {courses.map((item) => (
                                              <option value="UPSC">
                                                {item.title}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Student Name</label>
                                          <input
                                            name="StudentName"
                                            value={postItem.StudentName}
                                            onChange={handleChange}
                                            id="First_txtName"
                                            className="form-control"
                                            placeholder="Student Name"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Medium</label>
                                          <select
                                            name="Medium"
                                            value={postItem.Medium}
                                            onChange={handleChange}
                                            className="form-control select2"
                                            style={{ width: "100%" }}
                                          >
                                            <option value="" disabled selected>
                                              -Select-
                                            </option>
                                            <option value="Hindi">Hindi</option>
                                            <option value="English">
                                              English
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Date of Birth</label>
                                          <input
                                            name="DateofBirth"
                                            value={postItem.DateofBirth}
                                            onChange={handleChange}
                                            type="date"
                                            id="First_txtDOB"
                                            className="form-control"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Email ID</label>
                                          <input
                                            name="EmailID"
                                            value={postItem.EmailID}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder="Email ID"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Father's Name</label>
                                          <input
                                            name="FathersName"
                                            value={postItem.FathersName}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder="Father's Name"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Mother's Name</label>
                                          <input
                                            name="MotherName"
                                            value={postItem.MotherName}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder="Mother's Name"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Mobile No.</label>
                                          <input
                                            name="MobileNo"
                                            value={postItem.MobileNo}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder="Mobile"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Alternate No.</label>
                                          <input
                                            name="AlternateNo"
                                            value={postItem.AlternateNo}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder="Mobile"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Gender</label>
                                          <select
                                            name="Gender"
                                            value={postItem.Gender}
                                            onChange={handleChange}
                                            className="form-control select2"
                                            style={{ width: "100%" }}
                                          >
                                            <option value="" disabled selected>
                                              -Select-
                                            </option>
                                            <option value="Male">Male</option>
                                            <option value="Female">
                                              Female
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Category</label>
                                          <select
                                            name="Category"
                                            value={postItem.Category}
                                            onChange={handleChange}
                                            className="form-control select2"
                                            style={{ width: "100%" }}
                                          >
                                            <option value="" disabled selected>
                                              -Select-
                                            </option>
                                            <option value="General">
                                              General
                                            </option>
                                            <option value="OBC">OBC</option>
                                            <option value="SC/ST">SC/ST</option>
                                            <option value="Others">
                                              Others
                                            </option>
                                          </select>
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>State</label>
                                          <select
                                            name="State"
                                            value={postItem.State}
                                            onChange={handleChange}
                                            className="form-control select2"
                                            style={{ width: "100%" }}
                                          >
                                            <option value="" disabled selected>
                                              -Select State-
                                            </option>
                                            {getStateList()}
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>City</label>

                                          <select
                                            name="City"
                                            value={postItem.City}
                                            onChange={handleChange}
                                            className="form-control"
                                          >
                                            <option value="" disabled selected>
                                              -Select City/Town-
                                            </option>
                                            {getCityList()}
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Address</label>
                                          <input
                                            name="Address"
                                            value={postItem.Address}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder="Address"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Qualification</label>
                                          <input
                                            name="Qualification"
                                            value={postItem.Qualification}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder="Qualification"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Occupation</label>
                                          <input
                                            name="Occupation"
                                            value={postItem.Occupation}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder="Occupation"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Reference</label>
                                          <input
                                            name="Reference"
                                            value={postItem.Reference}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder="Reference"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Course Fees:</label>
                                          <input
                                            name="CourseFees"
                                            value={postItem.CourseFees}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder="Course Fees"
                                            style={{
                                              color: "Red",
                                              fontWeight: "bold",
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Discount:</label>
                                          <input
                                            name="Discount"
                                            value={postItem.Discount}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder="Discount"
                                            style={{
                                              color: "Red",
                                              fontWeight: "bold",
                                            }}
                                          />
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Net Amount:</label>
                                          <input
                                            name="NetAmount"
                                            value={postItem.NetAmount}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder="Fees After Discount"
                                            style={{
                                              color: "Red",
                                              fontWeight: "bold",
                                            }}
                                          />
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Remark:</label>
                                          <input
                                            name="Remark"
                                            value={postItem.Remark}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder=""
                                            style={{
                                              color: "Red",
                                              fontWeight: "bold",
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6"></div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <div className="card-footer">
                                            <button
                                              onClick={() => postData()}
                                              className="btn btn-primary"
                                            >
                                              Submit
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Enquiry;
