import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Sidebar from "../Headerfooter/Sidebar";
import { baseUrl } from '../BaseUrl'


const UserModal = (props) => {
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("newtoken") ? true : false
    if (isLoggedIn===false) {
      props.history.push("/")
    }
  }, []);
  const config = {
    headers: {
      "Content-Type": "Application/json",
      "x-auth-token": `${localStorage.getItem("token")}`,
    },
  };

  const [confirmpassword, setConfirmpassword] = useState("")

  const params = useParams();
  const [form, setForm] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    password: "",
    role: "",
    roleType: "Admin" 
  });

  useEffect(() => {
    if (params.id) {
      getUserById()
    }
  }, []);

  const getUserById = () => {
    axios
      .get(baseUrl+`/api/user/${params.id}`, config)
      .then((response) => {
        if (response.data) {
          setForm(response.data.data)
        }
      })
      .catch((err) => {
        alert("error");
      });
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if (form.role !== "") {
      if (params.id) {
        axios
          .put(baseUrl+"/api/user", form)
          .then((response) => {
            props.history.push("/user");
          })
          .catch(function (error) {
            alert("error");
          });
      } else {
        axios
          .post(baseUrl+"/api/user", form)
          .then((response) => {
            props.history.push("/user");
          })
          .catch(function (error) {
            alert("error");
          });
      }
    }
  };


  const [roles, setRoles] = useState([]);

    useEffect(() => {
        getRoles();
    }, []);



    const getRoles = () => {
        const config = {
            headers: {
                "Content-Type": "Application/json",
                "x-auth-token": `${localStorage.getItem("token")}`,
            },
        };
        axios.get(baseUrl+"/api/role", config).then((res) => {
          setRoles(res.data.data.filter((i)=>i.roleTitle==="Admin"))
        });
    }


  return (
    <>

<Sidebar props={props}/>

      <div className="content-wrapper" style={{ marginLeft: "15%" }}>
        <div className="modal-wrapper">
          <h3 className="modal-title">{params?.id ? "View" : "Add"} User</h3>
          <div>
            <div className="container" style={{ textAlign: "center" }}>
              < input style={{ marginRight: 15, width:"50%" }}
                name="firstname"
                type="text"
                placeholder="First Name"
                className="modal-input1"
                value={form?.firstname}
                onChange={handleChange}
              />
              < input style={{ marginRight: 15, width:"50%" }}
                name="lastname"
                type="text"
                placeholder="Last Name"
                className="modal-input1"
                value={form?.lastname}
                onChange={handleChange}
              />
              < input style={{ marginRight: 15, width:"50%" }}
                name="email"
                type="text"
                placeholder="Email-ID"
                className="modal-input1"
                value={form?.email}
                onChange={handleChange}
              />
              < input style={{ marginRight: 15, width:"50%" }}
                name="phone"
                type="text"
                placeholder="Enter Phone Number"
                className="modal-input1"
                value={form?.phone}
                onChange={handleChange}
              />
              < input style={{ marginRight: 15 }}
                name="password"
                placeholder="Enter Password"
                className="modal-input1"
                type="password"
                value={form?.password}
                onChange={handleChange}
              />
              < input style={{ marginRight: 15 }}
                name="confirmpassword"
                placeholder="Confirm Password"
                className="modal-input1"
                type="password"
                value={confirmpassword}
                onChange={(e)=>setConfirmpassword(e.target.value)}
              />
              <select className="modal-input1"
              name="role"
                value={form?.role}
                onChange={handleChange} style={{ marginRight: "1.2%", height: "45px", border: "none" }}>
                <option >-- select role --</option>
                {roles.map((i)=><option value={i._id}>{i.roleTitle}({i.adminbranch})</option>)}
              </select>
            </div>
            {localStorage.getItem("useredit")==="yes"?<button
              onClick={() => handleSubmit()}
              style={{ margin: "0 auto", marginLeft: "47%" }}
              className="btn btn-success"
            >Save</button>:null}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserModal;
