import React,{useEffect} from 'react'
import Sidebar from "../Headerfooter/Sidebar";

function Dahsboar1(props) {
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("newtoken") ? true : false
    if (isLoggedIn===false) {
      props.history.push("/")
    }
  }, []);
  return (
    <div>
      <Sidebar props={props} />
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Dashboard</h1>
              </div>
              {/* /.col */}
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right" />
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>
        {/* /.content-header */}
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            {/* Info boxes */}
            <form
              method="post"
              action="https://samadhanacademy.com/samadhanadmin/Enquiry"
              id="ctl01"
            >
              <div className="aspNetHidden">
                <input
                  type="hidden"
                  name="__EVENTTARGET"
                  id="__EVENTTARGET"
                  defaultValue
                />
                <input
                  type="hidden"
                  name="__EVENTARGUMENT"
                  id="__EVENTARGUMENT"
                  defaultValue
                />
                <input
                  type="hidden"
                  name="__LASTFOCUS"
                  id="__LASTFOCUS"
                  defaultValue
                />
                <input
                  type="hidden"
                  name="__VIEWSTATE"
                  id="__VIEWSTATE"
                  defaultValue="c0t9YQZL6Toi/IIxKlYJsW/kM+5endAZgJg9sSB9fenThbFjP7u5I2GZqrRa2MyfhG5a9WjpCfyxbk+gDulARzkCN2nqJ4ENfmkvYTIMvlYzonZF8AgISTIqdoMUqtY1PDv6a1tgXJvAGgWC8BPK75BS9IWbbTTia1zD9b9LQz0="
                />
              </div>
              <div className="aspNetHidden">
                <input
                  type="hidden"
                  name="__VIEWSTATEGENERATOR"
                  id="__VIEWSTATEGENERATOR"
                  defaultValue="023F5689"
                />
                <input
                  type="hidden"
                  name="__EVENTVALIDATION"
                  id="__EVENTVALIDATION"
                  defaultValue="8fujjIdaR0/+xKhE629IE6RA1kJzVI1iMOR2aZT5q0IRXBF2j2WgWbL7/0E0IUEO8RV/x7s+FmDTD8GOdEi4F0EcZnLfIUsIxPIietdiHf4mOG1sslVfqOoj2pQ8guLWX//ELBSTUnx+VGN5RyaGNr5eEWvdRYMM76KS6kaz6lbasY4pN5cesTsGthPSzcqaw9gQ1fmcVxUVQOMR2jxnFJcDLjqpsTheKcdFxAKLDVEQSFNwYmQIOu8FaOlxOtVtWmpCtLZFu9Jla16NsSvH9cyCglxhkquf/jf/nSQ5mOQhpriPe3HypwW5bTktwdyule2Teoz8fvJv9bakgOFV6DUCk/aOVavXkjluIEzb6XfZNYu9rZXeB5UT6sh8nheI+1qrPDdbOFAPJsITRpvbOv9Jq2xvlRu91edXuKLn8HkRRGGY4mwfEdaUlAtEkSKOY0PV/iUi61kBqoHY6YzheYAjNWXYWB2NQSKUi2TEHKWuDMK/T/tiZR7ueuhGJA9NJ53c0tE2/QN2nDCNRmWZtujrKjCmjD0xzYBfK6e1iwWdjVYV/RfoqRoF0ewh03aZdAITFykPnJzvn+pURRLvZ8/Ghk2jEXh3pRj9NCdpT/XeYNTMH55XgVIsiUwPvIqWE/2iDHeWGT7rOa80VDESlnaOLSzCY99F6KVjed7wkhzb9DJG3Gcf+lqICx7aTj6klY13bWRUyeHExI7eLW+RSrLR0szbXb7bP0TGMsTceOGI8M6bGd2t6Q92TJQTOZmDXHDQLzdqD5neocAFpF+Y3p38d1mk4mnilc/bBbfCUX03FNJ5JmDGZggy6AIFKLVD3dMpzoQd1ss85i9cm36pkUH+Hd8heYQkY3T5ZLUDaHjO36YHhIkHLaeeFwn+Z/MqsD6M4mDQMUpCnvp+HfnzRAB3MDIRMxdn7XfJCU2U0e60EEmfQPgzMLL5GNifVvSMyRV2xLhqP3ahxWbRgFvItlFyQW4rEYUVKbi2jr9CLZ+MtUGXCgofApq+jlSgr6MV"
                />
              </div>
              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    {/* /.col */}
                    <div className="col-md-12">
                      <div className="card">
                        {/* <div className="card-header p-2">
                        <ul className="nav nav-pills">
                          <li className="nav-item">
                            <a
                              className="nav-link active"
                              href="#activity"
                              data-toggle="tab"
                            >
                              Staff
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              href="#timeline"
                              data-toggle="tab"
                            >
                              Student
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              href="#settings"
                              data-toggle="tab"
                            >
                              Online Enquiry
                            </a>
                          </li>
                        </ul>
                      </div> */}
                        {/* /.card-header */}
                        <div className="card-body">
                          <div className="tab-content">
                            <div className="active tab-pane" id="activity">
                              {/* Post */}
                              <section className="content">
                                <div className="container-fluid">
                                  {/* SELECT2 EXAMPLE */}
                                  <div className="card card-default">
                                    <div className="card-header">
                                      <h3 className="card-title">Salary</h3>
                                      <span
                                        id="First_lblMsg"
                                        style={{
                                          color: "Red",
                                          fontWeight: "bold",
                                        }}
                                      />
                                    </div>
                                    {/* /.card-header */}

                                    <div className="card-body">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div
                                            className="card"
                                            style={{ width: "15rem" }}
                                          >
                                            <img
                                              src="..."
                                              className="card-img-top"
                                              alt="..."
                                            />
                                            <div className="card-body">
                                              <h5 className="card-title">
                                                Enquiry
                                              </h5>
                                           
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div
                                            className="card"
                                            style={{ width: "15rem" }}
                                          >
                                            <img
                                              src="..."
                                              className="card-img-top"
                                              alt="..."
                                            />
                                            <div className="card-body">
                                              <h5 className="card-title">
                                                Demo
                                              </h5>
                                           
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div
                                            className="card"
                                            style={{ width: "15rem" }}
                                          >
                                            <img
                                              src="..."
                                              className="card-img-top"
                                              alt="..."
                                            />
                                            <div className="card-body">
                                              <h5 className="card-title">
                                                Addmission
                                              </h5>
                                           
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div
                                            className="card"
                                            style={{ width: "15rem" }}
                                          >
                                            <img
                                              src="..."
                                              className="card-img-top"
                                              alt="..."
                                            />
                                            <div className="card-body">
                                              <h5 className="card-title">
                                               Attendence
                                              </h5>
                                           
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div
                                            className="card"
                                            style={{ width: "15rem" }}
                                          >
                                            <img
                                              src="..."
                                              className="card-img-top"
                                              alt="..."
                                            />
                                            <div className="card-body">
                                              <h5 className="card-title">
                                               I-Card
                                              </h5>
                                           
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div
                                            className="card"
                                            style={{ width: "15rem" }}
                                          >
                                            <img
                                              src="..."
                                              className="card-img-top"
                                              alt="..."
                                            />
                                            <div className="card-body">
                                              <h5 className="card-title">
                                               Test
                                              </h5>
                                           
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div
                                            className="card"
                                            style={{ width: "15rem" }}
                                          >
                                            <img
                                              src="..."
                                              className="card-img-top"
                                              alt="..."
                                            />
                                            <div className="card-body">
                                              <h5 className="card-title">
                                                Fees
                                              </h5>
                                           
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div
                                            className="card"
                                            style={{ width: "15rem" }}
                                          >
                                            <img
                                              src="..."
                                              className="card-img-top"
                                              alt="..."
                                            />
                                            <div className="card-body">
                                              <h5 className="card-title">
                                               Staff
                                              </h5>
                                           
                                            </div>
                                          </div>
                                        </div>


                                        <div className="col-md-6">
                                          <div
                                            className="card"
                                            style={{ width: "15rem" }}
                                          >
                                            <img
                                              src="..."
                                              className="card-img-top"
                                              alt="..."
                                            />
                                            <div className="card-body">
                                              <h5 className="card-title">
                                                Salary
                                              </h5>
                                           
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-md-6">
                                          <div
                                            className="card"
                                            style={{ width: "15rem" }}
                                          >
                                            <img
                                              src="..."
                                              className="card-img-top"
                                              alt="..."
                                            />
                                            <div className="card-body">
                                              <h5 className="card-title">
                                                Inventory
                                              </h5>
                                           
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div
                                            className="card"
                                            style={{ width: "15rem" }}
                                          >
                                            <img
                                              src="..."
                                              className="card-img-top"
                                              alt="..."
                                            />
                                            <div className="card-body">
                                              <h5 className="card-title">
                                                Library
                                              </h5>
                                           
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div
                                            className="card"
                                            style={{ width: "15rem" }}
                                          >
                                            <img
                                              src="..."
                                              className="card-img-top"
                                              alt="..."
                                            />
                                            <div className="card-body">
                                              <h5 className="card-title">
                                                Notes
                                              </h5>
                                           
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div
                                            className="card"
                                            style={{ width: "15rem" }}
                                          >
                                            <img
                                              src="..."
                                              className="card-img-top"
                                              alt="..."
                                            />
                                            <div className="card-body">
                                              <h5 className="card-title">
                                                Expences
                                              </h5>
                                           
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-md-6">
                                          <div
                                            className="card"
                                            style={{ width: "15rem" }}
                                          >
                                            <img
                                              src="..."
                                              className="card-img-top"
                                              alt="..."
                                            />
                                            <div className="card-body">
                                              <h5 className="card-title">
                                              Advertisement
                                              </h5>
                                           
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div
                                            className="card"
                                            style={{ width: "15rem" }}
                                          >
                                            <img
                                              src="..."
                                              className="card-img-top"
                                              alt="..."
                                            />
                                            <div className="card-body">
                                              <h5 className="card-title">
                                                Fees Defaulter
                                              </h5>
                                           
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div
                                            className="card"
                                            style={{ width: "15rem" }}
                                          >
                                            <img
                                              src="..."
                                              className="card-img-top"
                                              alt="..."
                                            />
                                            <div className="card-body">
                                              <h5 className="card-title">
                                                Follow up
                                              </h5>
                                           
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div
                                            className="card"
                                            style={{ width: "15rem" }}
                                          >
                                            <img
                                              src="..."
                                              className="card-img-top"
                                              alt="..."
                                            />
                                            <div className="card-body">
                                              <h5 className="card-title">
                                               Feed Back
                                              </h5>
                                           
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-md-6">
                                          <div
                                            className="card"
                                            style={{ width: "15rem" }}
                                          >
                                            <img
                                              src="..."
                                              className="card-img-top"
                                              alt="..."
                                            />
                                            <div className="card-body">
                                              <h5 className="card-title">
                                                SMS/Mail
                                              </h5>
                                           
                                            </div>
                                          </div>
                                        </div>
                                        
                                        
                                       





                                        
                                      </div>
                                      {/* /.row */}
                                      {/* /.row */}
                                    </div>
                                    {/* /.card-body */}
                                  </div>
                                  {/* /.card */}

                                  {/* SELECT2 EXAMPLE */}
                                  {/* /.card */}
                                  {/* /.row */}
                                </div>
                                {/* /.container-fluid */}
                              </section>
                              {/* /.post */}
                            </div>
                            {/* /.tab-pane */}
                            <div className="tab-pane" id="timeline">
                              {/* The timeline */}
                              <section className="content">
                                <div className="container-fluid">
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="card">
                                        <div className="card-header">
                                          <h3 className="card-title">
                                            Student Enquiry Details
                                          </h3>
                                        </div>
                                        {/* /.card-header */}
                                        <div className="card-body">
                                          <table
                                            id="example1"
                                            className="table table-bordered table-striped"
                                          >
                                            <thead>
                                              <tr>
                                                <th>General</th>
                                                <th>Contact</th>
                                                <th>Parents</th>
                                                <th>Qualification</th>
                                                <th>Address</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td>
                                                  Ashutosh ojha
                                                  <br />
                                                  UPSC, Hindi
                                                  <br />
                                                  12/10/2020
                                                  <br />
                                                </td>
                                                <td>
                                                  ashutoshojha@gmail.com
                                                  <br />
                                                  9644433444
                                                  <br />
                                                  1983-01-02
                                                </td>
                                                <td>
                                                  Father: gauri shankar ojha
                                                  <br />
                                                  Mother: meena devi ojha
                                                  <br />
                                                  Cat: OBC
                                                  <br />
                                                  Gen: Male
                                                </td>
                                                <td>
                                                  Qual: B.E.
                                                  <br />
                                                  Occ.: JOB
                                                  <br />
                                                  Ref.: friend
                                                </td>
                                                <td>
                                                  Dk 181 City: INDORE
                                                  <br />
                                                  State: Madhya Pradesh
                                                  <br />
                                                  <a href="Demo92e2.html?enqid=4505">
                                                    Click For Demo
                                                  </a>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>
                                                  test
                                                  <br />
                                                  MPPSC, Hindi
                                                  <br />
                                                  1/24/2021
                                                  <br />
                                                  11000
                                                </td>
                                                <td>
                                                  rakesh@gmail.com
                                                  <br />
                                                  98989898
                                                  <br />
                                                  2019-10-10
                                                </td>
                                                <td>
                                                  Father: abc
                                                  <br />
                                                  Mother: xyz
                                                  <br />
                                                  Cat: General
                                                  <br />
                                                  Gen: Male
                                                </td>
                                                <td>
                                                  Qual: kljklj
                                                  <br />
                                                  Occ.: jkljkl
                                                  <br />
                                                  Ref.: kjklj
                                                </td>
                                                <td>
                                                  kjhjkh City: bhopal
                                                  <br />
                                                  State: Madhya Pradesh
                                                  <br />
                                                  <a href="Demo9518.html?enqid=4507">
                                                    Click For Demo
                                                  </a>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>
                                                  lakshmi ojha
                                                  <br />
                                                  MPPSC, Hindi
                                                  <br />
                                                  2/2/2021
                                                  <br />
                                                  10000
                                                </td>
                                                <td>
                                                  er.amitojha03@gmail.com
                                                  <br />
                                                  9977405256
                                                  <br />
                                                  1991-09-10
                                                </td>
                                                <td>
                                                  Father: naresh ojha
                                                  <br />
                                                  Mother: geeta ojha
                                                  <br />
                                                  Cat: General
                                                  <br />
                                                  Gen: Female
                                                </td>
                                                <td>
                                                  Qual: B.E.
                                                  <br />
                                                  Occ.: JOB
                                                  <br />
                                                  Ref.: friend
                                                </td>
                                                <td>
                                                  Dk 181 City: INDORE
                                                  <br />
                                                  State: Madhya Pradesh
                                                  <br />
                                                  <a href="Demofb12.html?enqid=4509">
                                                    Click For Demo
                                                  </a>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>
                                                  Lakshmi Ojha
                                                  <br />
                                                  MPPSC, Hindi
                                                  <br />
                                                  2/7/2022
                                                  <br />
                                                  11000
                                                </td>
                                                <td>
                                                  er.amitojha03@gmail.com
                                                  <br />
                                                  09977405256
                                                  <br />
                                                  1991-05-25
                                                </td>
                                                <td>
                                                  Father: Naresh <br />
                                                  Mother: Geeta
                                                  <br />
                                                  Cat: General
                                                  <br />
                                                  Gen: Female
                                                </td>
                                                <td>
                                                  Qual: Graduation
                                                  <br />
                                                  Occ.: student
                                                  <br />
                                                  Ref.: friend
                                                </td>
                                                <td>
                                                  Dk 181 City: INDORE
                                                  <br />
                                                  State: Madhya Pradesh
                                                  <br />
                                                  <a href="Demo9168.html?enqid=5513">
                                                    Click For Demo
                                                  </a>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>
                                                  Lakshmi Ojha
                                                  <br />
                                                  MPPSC, English
                                                  <br />
                                                  3/4/2022
                                                  <br />
                                                  10000
                                                </td>
                                                <td>
                                                  er.amitojha03@gmail.com
                                                  <br />
                                                  123456789
                                                  <br />
                                                  1991-05-25
                                                </td>
                                                <td>
                                                  Father: naresh <br />
                                                  Mother: Geeta
                                                  <br />
                                                  Cat: General
                                                  <br />
                                                  Gen: Female
                                                </td>
                                                <td>
                                                  Qual: Graduation
                                                  <br />
                                                  Occ.: student
                                                  <br />
                                                  Ref.: friend
                                                </td>
                                                <td>
                                                  Dk 181 City: INDORE
                                                  <br />
                                                  State: Madhya Pradesh
                                                  <br />
                                                  <a href="Demo219a.html?enqid=6513">
                                                    Click For Demo
                                                  </a>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                        {/* /.card-body */}
                                      </div>
                                      {/* /.card */}
                                    </div>
                                    {/* /.col */}
                                  </div>
                                  {/* /.row */}
                                </div>
                                {/* /.container-fluid */}
                              </section>
                              {/* timeline time label */}
                            </div>
                            {/* /.tab-pane */}
                            <div className="tab-pane" id="settings">
                              <section className="content">
                                <div className="container-fluid">
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="card">
                                        <div className="card-header">
                                          <h3 className="card-title">
                                            Student Online Enquiry Details
                                          </h3>
                                        </div>
                                        {/* /.card-header */}
                                        <div className="card-body">
                                          <table
                                            id="example1"
                                            className="table table-bordered table-striped"
                                          >
                                            <thead>
                                              <tr>
                                                <th>Student Details</th>
                                                <th>Contact</th>
                                                <th>Parents</th>
                                                <th>Others</th>
                                                <th>Address</th>
                                              </tr>
                                            </thead>
                                            <tbody />
                                          </table>
                                        </div>
                                        {/* /.card-body */}
                                      </div>
                                      {/* /.card */}
                                    </div>
                                    {/* /.col */}
                                  </div>
                                  {/* /.row */}
                                </div>
                                {/* /.container-fluid */}
                              </section>
                              {/* timeline time label */}
                            </div>
                            {/* /.tab-pane */}
                          </div>
                          {/* /.tab-content */}
                        </div>
                        {/* /.card-body */}
                      </div>
                      {/* /.nav-tabs-custom */}
                    </div>
                    {/* /.col */}
                  </div>
                  {/* /.row */}
                </div>
                {/* /.container-fluid */}
              </section>
            </form>
            {/* /.row */}
            {/* /.row */}
          </div>
          {/*/. container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </div>
  )
}

export default Dahsboar1
