import React,{useEffect} from 'react'
import Sidebar from "../Headerfooter/Sidebar";

function Admission1(props) {
    useEffect(() => {
        const isLoggedIn = localStorage.getItem("newtoken") ? true : false
        if (isLoggedIn===false) {
          props.history.push("/")
        }
      }, []);
    return (
        <div>
            <Sidebar props={props} />
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">

                            </div>
                            {/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right" />
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* Info boxes */}
                        <form method="post" action="https://samadhanacademy.com/samadhanadmin/Enquiry" id="ctl01">
                            <div className="aspNetHidden">
                                <input type="hidden" name="_EVENTTARGET" id="_EVENTTARGET" defaultValue />
                                <input type="hidden" name="_EVENTARGUMENT" id="_EVENTARGUMENT" defaultValue />
                                <input type="hidden" name="_LASTFOCUS" id="_LASTFOCUS" defaultValue />
                                <input type="hidden" name="_VIEWSTATE" id="_VIEWSTATE" defaultValue="c0t9YQZL6Toi/IIxKlYJsW/kM+5endAZgJg9sSB9fenThbFjP7u5I2GZqrRa2MyfhG5a9WjpCfyxbk+gDulARzkCN2nqJ4ENfmkvYTIMvlYzonZF8AgISTIqdoMUqtY1PDv6a1tgXJvAGgWC8BPK75BS9IWbbTTia1zD9b9LQz0=" />
                            </div>
                            <div className="aspNetHidden">
                                <input type="hidden" name="_VIEWSTATEGENERATOR" id="_VIEWSTATEGENERATOR" defaultValue="023F5689" />
                                <input type="hidden" name="_EVENTVALIDATION" id="_EVENTVALIDATION" defaultValue="8fujjIdaR0/+xKhE629IE6RA1kJzVI1iMOR2aZT5q0IRXBF2j2WgWbL7/0E0IUEO8RV/x7s+FmDTD8GOdEi4F0EcZnLfIUsIxPIietdiHf4mOG1sslVfqOoj2pQ8guLWX//ELBSTUnx+VGN5RyaGNr5eEWvdRYMM76KS6kaz6lbasY4pN5cesTsGthPSzcqaw9gQ1fmcVxUVQOMR2jxnFJcDLjqpsTheKcdFxAKLDVEQSFNwYmQIOu8FaOlxOtVtWmpCtLZFu9Jla16NsSvH9cyCglxhkquf/jf/nSQ5mOQhpriPe3HypwW5bTktwdyule2Teoz8fvJv9bakgOFV6DUCk/aOVavXkjluIEzb6XfZNYu9rZXeB5UT6sh8nheI+1qrPDdbOFAPJsITRpvbOv9Jq2xvlRu91edXuKLn8HkRRGGY4mwfEdaUlAtEkSKOY0PV/iUi61kBqoHY6YzheYAjNWXYWB2NQSKUi2TEHKWuDMK/T/tiZR7ueuhGJA9NJ53c0tE2/QN2nDCNRmWZtujrKjCmjD0xzYBfK6e1iwWdjVYV/RfoqRoF0ewh03aZdAITFykPnJzvn+pURRLvZ8/Ghk2jEXh3pRj9NCdpT/XeYNTMH55XgVIsiUwPvIqWE/2iDHeWGT7rOa80VDESlnaOLSzCY99F6KVjed7wkhzb9DJG3Gcf+lqICx7aTj6klY13bWRUyeHExI7eLW+RSrLR0szbXb7bP0TGMsTceOGI8M6bGd2t6Q92TJQTOZmDXHDQLzdqD5neocAFpF+Y3p38d1mk4mnilc/bBbfCUX03FNJ5JmDGZggy6AIFKLVD3dMpzoQd1ss85i9cm36pkUH+Hd8heYQkY3T5ZLUDaHjO36YHhIkHLaeeFwn+Z/MqsD6M4mDQMUpCnvp+HfnzRAB3MDIRMxdn7XfJCU2U0e60EEmfQPgzMLL5GNifVvSMyRV2xLhqP3ahxWbRgFvItlFyQW4rEYUVKbi2jr9CLZ+MtUGXCgofApq+jlSgr6MV" />
                            </div>
                            <section className="content">
                                <div className="container-fluid">
                                    <div className="row">
                                        {/* /.col */}
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-header p-2">
                                                    <ul className="nav nav-pills">
                                                        <li className="nav-item">
                                                            <h3>Admission:</h3>
                                                        </li>
                                                    </ul>
                                                </div>
                                                {/* /.card-header */}
                                                <div className="card-body">
                                                    <div className="tab-content">
                                                        <div className="active tab-pane" id="activity">
                                                            {/* Post */}
                                                            <section className="content">
                                                                <div className="container-fluid">
                                                                    {/* SELECT2 EXAMPLE */}
                                                                    <div className="card card-default">
                                                                        <div className="card-header">

                                                                            <span id="First_lblMsg" style={{ color: 'Red', fontWeight: 'bold' }} />
                                                                        </div>
                                                                        {/* /.card-header */}
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Inqury No.:</label>
                                                                                        <input name="ctl00$First$txtEnqNo" type="text"  id="First_txtEnqNo" className="form-control" placeholder="Enter Number" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Enrollment No:</label>
                                                                                        <input name="ctl00$First$txtEnqNo" type="text"  id="First_txtEnqNo" className="form-control" placeholder="Enter Number" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Student Name:</label>
                                                                                        <input name="ctl00$First$txtName" type="text" id="First_txtName" className="form-control" placeholder="Asset Name" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Select Course</label>
                                                                                        <select name="ctl00$First$ddState" id="First_ddState" className="form-control select2" style={{ width: '100%' }}>
                                                                                            <option value="Select">
                                                                                                Select1
                                                                                            </option>
                                                                                            <option value="Select">
                                                                                                select2
                                                                                            </option>
                                                                                            <option value="Others">
                                                                                                select3
                                                                                            </option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Schedule Time</label>
                                                                                        <select name="ctl00$First$ddState" id="First_ddState" className="form-control select2" style={{ width: '100%' }}>
                                                                                            <option value="Select">
                                                                                                Select1
                                                                                            </option>
                                                                                            <option value="Select">
                                                                                                select2
                                                                                            </option>
                                                                                            <option value="Others">
                                                                                                select3
                                                                                            </option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Batch Time</label>
                                                                                        <select name="ctl00$First$ddState" id="First_ddState" className="form-control select2" style={{ width: '100%' }}>
                                                                                            <option value="Select">
                                                                                                Select1
                                                                                            </option>
                                                                                            <option value="Select">
                                                                                                select2
                                                                                            </option>
                                                                                            <option value="Others">
                                                                                                select3
                                                                                            </option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    {/* /.form-group */}
                                                                                    <div className="form-group">
                                                                                        <label>Medium</label>
                                                                                        <select name="ctl00$First$ddState" id="First_ddState" className="form-control select2" style={{ width: '100%' }}>
                                                                                            <option value="Select">
                                                                                                English
                                                                                            </option>
                                                                                            <option value="Select">
                                                                                                Hindi
                                                                                            </option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                                {/* /.col */}
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Date of Birth:</label>
                                                                                        <input name="ctl00$First$txtEnqNo" type="date" defaultValue={6515} id="First_txtEnqNo" className="form-control" placeholder="Enter Number" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {/* /.row */}
                                                                            {/* /.row */}
                                                                        </div>
                                                                        {/* /.card-body */}

                                                                    </div>
                                                                    {/* /.card */}
                                                                    {/* SELECT2 EXAMPLE */}
                                                                    {/* /.card */}
                                                                    {/* /.row */}
                                                                </div>
                                                                {/* /.container-fluid */}
                                                            </section>
                                                            {/* /.post */}
                                                        </div>
                                                        {/* /.tab-pane */}


                                                        {/* /.tab-pane */}
                                                    </div>
                                                    {/* /.tab-content */}
                                                </div>
                                                {/* /.card-body */}
                                                <div className="card-body">
                                                    <div className="tab-content">
                                                        <div className="active tab-pane" id="activity">
                                                            {/* Post */}
                                                            <section className="content">
                                                                <div className="container-fluid">
                                                                    {/* SELECT2 EXAMPLE */}
                                                                    <div className="card card-default">
                                                                        <div className="card-header">
                                                                            <h3 className="card-title">
                                                                                Genral Detail:
                                                                            </h3>
                                                                            <span id="First_lblMsg" style={{ color: 'Red', fontWeight: 'bold' }} />
                                                                        </div>
                                                                        {/* /.card-header */}
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <label>Gender</label>
                                                                                    <select name="ctl00$First$ddState" id="First_ddState" className="form-control select2" style={{ width: '100%' }}>
                                                                                        <option value="Select">
                                                                                            Male
                                                                                        </option>
                                                                                        <option value="Select">
                                                                                            Female
                                                                                        </option>
                                                                                    </select>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <label>Category</label>
                                                                                    <select name="ctl00$First$ddState" id="First_ddState" className="form-control select2" style={{ width: '100%' }}>
                                                                                        <option value="Select">
                                                                                            Sc
                                                                                        </option>
                                                                                        <option value="Select">
                                                                                            St
                                                                                        </option>
                                                                                        <option value="Select">
                                                                                            Obc
                                                                                        </option>
                                                                                        <option value="Select">
                                                                                            Gen
                                                                                        </option>
                                                                                    </select>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Email Id:</label>
                                                                                        <input name="ctl00$First$txtEnqNo" type="Email" id="First_txtEnqNo" className="form-control" placeholder="Enter Id" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Contact No:</label>
                                                                                        <input name="ctl00$First$txtName" type="text" id="First_txtName" className="form-control" placeholder="Enter Number" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Altanat No:</label>
                                                                                        <input name="ctl00$First$txtName" type="text" id="First_txtName" className="form-control" placeholder="Enter Number" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <label>Qualification</label>
                                                                                    <select name="ctl00$First$ddState" id="First_ddState" className="form-control select2" style={{ width: '100%' }}>
                                                                                        <option value="Select">
                                                                                            select1
                                                                                        </option>
                                                                                        <option value="Select">
                                                                                            select2
                                                                                        </option>
                                                                                        <option value="Select">
                                                                                            Select3
                                                                                        </option>
                                                                                    </select>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>School/College:</label>
                                                                                        <input name="ctl00$First$txtName" type="text" id="First_txtName" className="form-control" placeholder="Enter Name" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Occuption</label>
                                                                                        <select name="ctl00$First$ddState" id="First_ddState" className="form-control select2" style={{ width: '100%' }}>
                                                                                            <option value="Select">
                                                                                                Select1
                                                                                            </option>
                                                                                            <option value="Select">
                                                                                                select2
                                                                                            </option>
                                                                                            <option value="Others">
                                                                                                select3
                                                                                            </option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Father Name:</label>
                                                                                        <input name="ctl00$First$txtName" type="text" id="First_txtName" className="form-control" placeholder="Enter Name" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Mother Name:</label>
                                                                                        <input name="ctl00$First$txtName" type="text" id="First_txtName" className="form-control" placeholder="Enter Name" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Father Contact:</label>
                                                                                        <input name="ctl00$First$txtName" type="text" id="First_txtName" className="form-control" placeholder="Enter Number" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Father Occ:</label>
                                                                                        <input name="ctl00$First$txtName" type="text" id="First_txtName" className="form-control" placeholder="Enter Name" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Permanent Address:</label>
                                                                                        <input name="ctl00$First$txtName" type="text" id="First_txtName" className="form-control" placeholder="Enter Address" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>City</label>
                                                                                        <select name="ctl00$First$ddState" id="First_ddState" className="form-control select2" style={{ width: '100%' }}>
                                                                                            <option value="Select">
                                                                                                Select1
                                                                                            </option>
                                                                                            <option value="Select">
                                                                                                select2
                                                                                            </option>
                                                                                            <option value="Others">
                                                                                                select3
                                                                                            </option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>State</label>
                                                                                        <select name="ctl00$First$ddState" id="First_ddState" className="form-control select2" style={{ width: '100%' }}>
                                                                                            <option value="Select">
                                                                                                Select1
                                                                                            </option>
                                                                                            <option value="Select">
                                                                                                select2
                                                                                            </option>
                                                                                            <option value="Others">
                                                                                                select3
                                                                                            </option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Pin Code:</label>
                                                                                        <input name="ctl00$First$txtName" type="text" id="First_txtName" className="form-control" placeholder="Enter Code" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <div className="form-group">
                                                                                            <label>Correspodance Address:</label>
                                                                                            <input name="ctl00$First$txtName" type="text" id="First_txtName" className="form-control" placeholder="Enter Address" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>State</label>
                                                                                        <select name="ctl00$First$ddState" id="First_ddState" className="form-control select2" style={{ width: '100%' }}>
                                                                                            <option value="Select">
                                                                                                Select1
                                                                                            </option>
                                                                                            <option value="Select">
                                                                                                select2
                                                                                            </option>
                                                                                            <option value="Others">
                                                                                                select3
                                                                                            </option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>City</label>
                                                                                        <select name="ctl00$First$ddState" id="First_ddState" className="form-control select2" style={{ width: '100%' }}>
                                                                                            <option value="Select">
                                                                                                Select1
                                                                                            </option>
                                                                                            <option value="Select">
                                                                                                select2
                                                                                            </option>
                                                                                            <option value="Others">
                                                                                                select3
                                                                                            </option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Pin Code:</label>
                                                                                        <input name="ctl00$First$txtName" type="text" id="First_txtName" className="form-control" placeholder="Enter Code" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row" style={{ width: "100%" }}>
                                                                                    <div className="col-md-4">
                                                                                        <div className="form-group">
                                                                                            <div class="btn-group" role="group" aria-label="Basic example" style={{ height: "50px" }}>
                                                                                                <button type="button" class="btn btn-primary">Save</button>
                                                                                                <button type="button" class="btn btn-primary">Refresh</button>
                                                                                                <button type="button" class="btn btn-primary">Done</button>
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* /.form-group */}
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <div className="form-group">
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                    <div className="form-group">
                                                                                            <div class="btn-group" role="group" aria-label="Basic example" style={{ height: "50px" }}>
                                                                                                <button type="button" class="btn btn-primary">FEES STRUCTURE</button>
                                                                                                <button type="button" class="btn btn-primary">PRINT  FROM</button>
                                                                                               
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                            {/* /.row */}
                                                                            {/* /.row */}
                                                                        </div>
                                                                        {/* /.card-body */}

                                                                    </div>
                                                                    {/* /.card */}
                                                                    {/* SELECT2 EXAMPLE */}
                                                                    {/* /.card */}
                                                                    {/* /.row */}
                                                                </div>
                                                                {/* /.container-fluid */}
                                                            </section>
                                                            {/* /.post */}
                                                        </div>
                                                        {/* /.tab-pane */}


                                                        {/* /.tab-pane */}
                                                    </div>
                                                    {/* /.tab-content */}
                                                </div>
                                                {/* /.card-body */}
                                                <div className="card-body">
                                                    <div className="tab-content">
                                                        <div className="active tab-pane" id="activity">
                                                            {/* Post */}
                                                            <section className="content">
                                                                <div className="container-fluid">
                                                                    {/* SELECT2 EXAMPLE */}
                                                                    <div className="card card-default">
                                                                        <div className="card-header">

                                                                            <span id="First_lblMsg" style={{ color: 'Red', fontWeight: 'bold' }} />
                                                                        </div>
                                                                        {/* /.card-header */}
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>ENROLLMENT No:</label>
                                                                                        <input name="ctl00$First$txtEnqNo" type="text" id="First_txtEnqNo" className="form-control" placeholder="Enter Number" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>ENQUARY NO:</label>
                                                                                        <input name="ctl00$First$txtName" type="text" id="First_txtName" className="form-control" placeholder="Enter Number" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>STUDENT NAME:</label>
                                                                                        <input name="ctl00$First$txtName" type="text" id="First_txtName" className="form-control" placeholder="Enter Name" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>COURSE:</label>
                                                                                        <input name="ctl00$First$txtName" type="text" id="First_txtName" className="form-control" placeholder="Enter Name" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>DURATION:</label>
                                                                                        <input name="ctl00$First$txtName" type="text" id="First_txtName" className="form-control" placeholder="Enter Month" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>TOTAL FEES:</label>
                                                                                        <input name="ctl00$First$txtName" type="text" id="First_txtName" className="form-control" placeholder="Enter Number" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>TEX APPLIED:</label>
                                                                                        <div class="form-check form-check-inline">
                                                                                            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"/>
                                                                                                <label class="form-check-label" for="inlineCheckbox1">yes</label>
                                                                                        </div>
                                                                                        <div class="form-check form-check-inline">
                                                                                            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"/>
                                                                                                <label class="form-check-label" for="inlineCheckbox2">no</label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>TAX PERSANTAGE:</label>
                                                                                        <input name="ctl00$First$txtName" type="text" id="First_txtName" className="form-control" placeholder="Enter Number" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>NET AMMOUNT:</label>
                                                                                        <input name="ctl00$First$txtName" type="text" id="First_txtName" className="form-control" placeholder="Enter Number" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>DISCOUNT:</label>
                                                                                        <input name="ctl00$First$txtName" type="text" id="First_txtName" className="form-control" placeholder="Enter Number" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>EXTRA CHARGE:</label>
                                                                                        <input name="ctl00$First$txtName" type="text" id="First_txtName" className="form-control" placeholder="Enter Number" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>FINAL AMOUNT:</label>
                                                                                        <input name="ctl00$First$txtName" type="text" id="First_txtName" className="form-control" placeholder="Enter Number" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>INSTALLMENT:</label>
                                                                                        <select name="ctl00$First$ddState" id="First_ddState" className="form-control select2" style={{ width: '100%' }}>
                                                                                            <option value="Select">
                                                                                                Select1
                                                                                            </option>
                                                                                            <option value="Select">
                                                                                                select2
                                                                                            </option>
                                                                                            <option value="Others">
                                                                                                select3
                                                                                            </option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>REMARK:</label>
                                                                                        <input name="ctl00$First$txtName" type="text" id="First_txtName" className="form-control" placeholder="Enter Number" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row" style={{ width: "100%" }}>
                                                                                    <div className="col-md-4">
                                                                                        <div className="form-group">
                                                                                            <div class="btn-group" role="group" aria-label="Basic example" style={{ height: "50px" }}>
                                                                                                <button type="button" class="btn btn-primary">Save</button>
                                                                                                <button type="button" class="btn btn-primary">Refresh</button>
                                                                                                <button type="button" class="btn btn-primary">Done</button>
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* /.form-group */}
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <div className="form-group">
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {/* /.row */}
                                                                            {/* /.row */}
                                                                        </div>
                                                                        {/* /.card-body */}

                                                                    </div>
                                                                    {/* /.card */}
                                                                    {/* SELECT2 EXAMPLE */}
                                                                    {/* /.card */}
                                                                    {/* /.row */}
                                                                </div>
                                                                {/* /.container-fluid */}
                                                            </section>
                                                            {/* /.post */}
                                                        </div>
                                                        {/* /.tab-pane */}


                                                        {/* /.tab-pane */}
                                                    </div>
                                                    {/* /.tab-content */}
                                                </div>
                                            </div>
                                            {/* /.nav-tabs-custom */}
                                        </div>
                                        {/* /.col */}
                                    </div>
                                    {/* /.row */}
                                </div>
                                {/* /.container-fluid */}
                            </section>
                        </form>
                        {/* /.row */}
                        {/* /.row */}
                    </div>
                    {/*/. container-fluid */}
                </section>
                {/* /.content */}
            </div>
        </div>
    )
}

export default Admission1