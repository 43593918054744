import React,{useEffect} from 'react'

function Testseries(props) {
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("newtoken") ? true : false
    if (isLoggedIn===false) {
      props.history.push("/")
    }
  }, []);
  return (
    <div>
      
    </div>
  )
}

export default Testseries
