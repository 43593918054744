import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyBmssP1-Wff_SgeTEP8a35bkUCqqSOeuYU",
  authDomain: "samadhan-ed759.firebaseapp.com",
  projectId: "samadhan-ed759",
  storageBucket: "samadhan-ed759.appspot.com",
  messagingSenderId: "221664211944",
  appId: "1:221664211944:web:978cbf3f32e3edce78ea5d",
  measurementId: "G-J57RLFN6QL",
};

firebase.initializeApp(firebaseConfig);
var storage = firebase.storage();
export default storage;
