import React,{useEffect} from "react";
import Sidebar from "../Headerfooter/Sidebar";

function Software(props) {
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("newtoken") ? true : false
    if (isLoggedIn===false) {
      props.history.push("/")
    }
  }, []);
  return (
    <div>
      <Sidebar props={props} />
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6"></div>
              {/* /.col */}
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right" />
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>
        {/* /.content-header */}
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            {/* Info boxes */}
            <form
              method="post"
              action="https://samadhanacademy.com/samadhanadmin/Enquiry"
              id="ctl01"
            >
              <div className="aspNetHidden">
                <input
                  type="hidden"
                  name="__EVENTTARGET"
                  id="__EVENTTARGET"
                  defaultValue
                />
                <input
                  type="hidden"
                  name="__EVENTARGUMENT"
                  id="__EVENTARGUMENT"
                  defaultValue
                />
                <input
                  type="hidden"
                  name="__LASTFOCUS"
                  id="__LASTFOCUS"
                  defaultValue
                />
                <input
                  type="hidden"
                  name="__VIEWSTATE"
                  id="__VIEWSTATE"
                  defaultValue="c0t9YQZL6Toi/IIxKlYJsW/kM+5endAZgJg9sSB9fenThbFjP7u5I2GZqrRa2MyfhG5a9WjpCfyxbk+gDulARzkCN2nqJ4ENfmkvYTIMvlYzonZF8AgISTIqdoMUqtY1PDv6a1tgXJvAGgWC8BPK75BS9IWbbTTia1zD9b9LQz0="
                />
              </div>
              <div className="aspNetHidden">
                <input
                  type="hidden"
                  name="__VIEWSTATEGENERATOR"
                  id="__VIEWSTATEGENERATOR"
                  defaultValue="023F5689"
                />
                <input
                  type="hidden"
                  name="__EVENTVALIDATION"
                  id="__EVENTVALIDATION"
                  defaultValue="8fujjIdaR0/+xKhE629IE6RA1kJzVI1iMOR2aZT5q0IRXBF2j2WgWbL7/0E0IUEO8RV/x7s+FmDTD8GOdEi4F0EcZnLfIUsIxPIietdiHf4mOG1sslVfqOoj2pQ8guLWX//ELBSTUnx+VGN5RyaGNr5eEWvdRYMM76KS6kaz6lbasY4pN5cesTsGthPSzcqaw9gQ1fmcVxUVQOMR2jxnFJcDLjqpsTheKcdFxAKLDVEQSFNwYmQIOu8FaOlxOtVtWmpCtLZFu9Jla16NsSvH9cyCglxhkquf/jf/nSQ5mOQhpriPe3HypwW5bTktwdyule2Teoz8fvJv9bakgOFV6DUCk/aOVavXkjluIEzb6XfZNYu9rZXeB5UT6sh8nheI+1qrPDdbOFAPJsITRpvbOv9Jq2xvlRu91edXuKLn8HkRRGGY4mwfEdaUlAtEkSKOY0PV/iUi61kBqoHY6YzheYAjNWXYWB2NQSKUi2TEHKWuDMK/T/tiZR7ueuhGJA9NJ53c0tE2/QN2nDCNRmWZtujrKjCmjD0xzYBfK6e1iwWdjVYV/RfoqRoF0ewh03aZdAITFykPnJzvn+pURRLvZ8/Ghk2jEXh3pRj9NCdpT/XeYNTMH55XgVIsiUwPvIqWE/2iDHeWGT7rOa80VDESlnaOLSzCY99F6KVjed7wkhzb9DJG3Gcf+lqICx7aTj6klY13bWRUyeHExI7eLW+RSrLR0szbXb7bP0TGMsTceOGI8M6bGd2t6Q92TJQTOZmDXHDQLzdqD5neocAFpF+Y3p38d1mk4mnilc/bBbfCUX03FNJ5JmDGZggy6AIFKLVD3dMpzoQd1ss85i9cm36pkUH+Hd8heYQkY3T5ZLUDaHjO36YHhIkHLaeeFwn+Z/MqsD6M4mDQMUpCnvp+HfnzRAB3MDIRMxdn7XfJCU2U0e60EEmfQPgzMLL5GNifVvSMyRV2xLhqP3ahxWbRgFvItlFyQW4rEYUVKbi2jr9CLZ+MtUGXCgofApq+jlSgr6MV"
                />
              </div>
              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    {/* /.col */}
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-header p-2">
                          <h3 style={{ textAlign: "center" }}>
                            SOFTWARE PERMISSION
                          </h3>
                        </div>
                        {/* /.card-header */}
                        <div className="card-body">
                          <div className="tab-content">
                            <div className="active tab-pane" id="activity">
                              {/* Post */}
                              <section className="content">
                                <div className="container-fluid">
                                  {/* SELECT2 EXAMPLE */}
                                  <div className="card card-default">
                                    {/* /.card-header */}
                                    <div className="card-body">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label>ID:</label>
                                            <input
                                              name="ctl00$First$txtEnqNo"
                                              type="text"
                                              id="First_txtEnqNo"
                                              className="form-control"
                                              placeholder="Enter Id"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label>NAME:</label>
                                            <input
                                              name="ctl00$First$txtEnqNo"
                                              type="text"
                                              id="First_txtEnqNo"
                                              className="form-control"
                                              placeholder="Enter Name"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      {/* /.row */}
                                      {/* /.row */}
                                    </div>
                                    {/* /.card-body */}
                                  </div>
                                  {/* /.card */}
                                  {/* SELECT2 EXAMPLE */}
                                  {/* /.card */}
                                  {/* /.row */}
                                </div>
                                {/* /.container-fluid */}
                              </section>
                              <section className="content">
                                <div className="container-fluid">
                                  {/* SELECT2 EXAMPLE */}
                                  <div className="card card-default">
                                    {/* /.card-header */}
                                    <div className="card-body">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label>Name:</label>
                                            <input
                                              name="ctl00$First$txtEnqNo"
                                              type="text"
                                              id="First_txtEnqNo"
                                              className="form-control"
                                              placeholder="Enter Name"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label>Id:</label>
                                            <input
                                              name="ctl00$First$txtEnqNo"
                                              type="text"
                                              id="First_txtEnqNo"
                                              className="form-control"
                                              placeholder="Enter Id"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label>Role:</label>
                                            <input
                                              name="ctl00$First$txtEnqNo"
                                              type="text"
                                              id="First_txtEnqNo"
                                              className="form-control"
                                              placeholder="Enter Role"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label>Destination:</label>
                                            <input
                                              name="ctl00$First$txtEnqNo"
                                              type="text"
                                              id="First_txtEnqNo"
                                              className="form-control"
                                              placeholder="Enter Destination"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      {/* /.row */}
                                      {/* /.row */}
                                    </div>
                                    {/* /.card-body */}
                                  </div>
                                  {/* /.card */}
                                  {/* SELECT2 EXAMPLE */}
                                  {/* /.card */}
                                  {/* /.row */}
                                </div>
                                {/* /.container-fluid */}
                              </section>
                              {/* /.post */}
                            </div>
                            {/* /.tab-pane */}

                            {/* /.tab-pane */}
                          </div>
                          {/* /.tab-content */}
                        </div>
                        {/* /.card-body */}
                      </div>
                      {/* /.nav-tabs-custom */}
                    </div>
                    {/* /.col */}
                  </div>
                  {/* /.row */}
                </div>
                {/* /.container-fluid */}
              </section>
            </form>
            {/* /.row */}
            {/* /.row */}
          </div>
          {/*/. container-fluid */}
        </section>
        {/* /.content */}
      </div>
    </div>
  );
}

export default Software;
