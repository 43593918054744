import React,{useEffect} from 'react'

function Newtest(props) {
    useEffect(() => {
        const isLoggedIn = localStorage.getItem("newtoken") ? true : false
        if (isLoggedIn===false) {
          props.history.push("/")
        }
      }, []);
    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">NEW-TEST</h1>
                            </div>
                            {/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right" />
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* Info boxes */}
                        <form method="post" action="https://samadhanacademy.com/samadhanadmin/Enquiry" id="ctl01">
                            <div className="aspNetHidden">
                                <input type="hidden" name="_EVENTTARGET" id="_EVENTTARGET" defaultValue />
                                <input type="hidden" name="_EVENTARGUMENT" id="_EVENTARGUMENT" defaultValue />
                                <input type="hidden" name="_LASTFOCUS" id="_LASTFOCUS" defaultValue />
                                <input type="hidden" name="_VIEWSTATE" id="_VIEWSTATE" defaultValue="c0t9YQZL6Toi/IIxKlYJsW/kM+5endAZgJg9sSB9fenThbFjP7u5I2GZqrRa2MyfhG5a9WjpCfyxbk+gDulARzkCN2nqJ4ENfmkvYTIMvlYzonZF8AgISTIqdoMUqtY1PDv6a1tgXJvAGgWC8BPK75BS9IWbbTTia1zD9b9LQz0=" />
                            </div>
                            <div className="aspNetHidden">
                                <input type="hidden" name="_VIEWSTATEGENERATOR" id="_VIEWSTATEGENERATOR" defaultValue="023F5689" />
                                <input type="hidden" name="_EVENTVALIDATION" id="_EVENTVALIDATION" defaultValue="8fujjIdaR0/+xKhE629IE6RA1kJzVI1iMOR2aZT5q0IRXBF2j2WgWbL7/0E0IUEO8RV/x7s+FmDTD8GOdEi4F0EcZnLfIUsIxPIietdiHf4mOG1sslVfqOoj2pQ8guLWX//ELBSTUnx+VGN5RyaGNr5eEWvdRYMM76KS6kaz6lbasY4pN5cesTsGthPSzcqaw9gQ1fmcVxUVQOMR2jxnFJcDLjqpsTheKcdFxAKLDVEQSFNwYmQIOu8FaOlxOtVtWmpCtLZFu9Jla16NsSvH9cyCglxhkquf/jf/nSQ5mOQhpriPe3HypwW5bTktwdyule2Teoz8fvJv9bakgOFV6DUCk/aOVavXkjluIEzb6XfZNYu9rZXeB5UT6sh8nheI+1qrPDdbOFAPJsITRpvbOv9Jq2xvlRu91edXuKLn8HkRRGGY4mwfEdaUlAtEkSKOY0PV/iUi61kBqoHY6YzheYAjNWXYWB2NQSKUi2TEHKWuDMK/T/tiZR7ueuhGJA9NJ53c0tE2/QN2nDCNRmWZtujrKjCmjD0xzYBfK6e1iwWdjVYV/RfoqRoF0ewh03aZdAITFykPnJzvn+pURRLvZ8/Ghk2jEXh3pRj9NCdpT/XeYNTMH55XgVIsiUwPvIqWE/2iDHeWGT7rOa80VDESlnaOLSzCY99F6KVjed7wkhzb9DJG3Gcf+lqICx7aTj6klY13bWRUyeHExI7eLW+RSrLR0szbXb7bP0TGMsTceOGI8M6bGd2t6Q92TJQTOZmDXHDQLzdqD5neocAFpF+Y3p38d1mk4mnilc/bBbfCUX03FNJ5JmDGZggy6AIFKLVD3dMpzoQd1ss85i9cm36pkUH+Hd8heYQkY3T5ZLUDaHjO36YHhIkHLaeeFwn+Z/MqsD6M4mDQMUpCnvp+HfnzRAB3MDIRMxdn7XfJCU2U0e60EEmfQPgzMLL5GNifVvSMyRV2xLhqP3ahxWbRgFvItlFyQW4rEYUVKbi2jr9CLZ+MtUGXCgofApq+jlSgr6MV" />
                            </div>
                            <section className="content">
                                <div className="container-fluid">
                                    <div className="row">
                                        {/* /.col */}
                                        <div className="col-md-12">
                                            <div className="card">

                                                <div className="card-body">
                                                    <div className="tab-content">
                                                        <div className="active tab-pane" id="activity">
                                                            {/* Post */}
                                                            <section className="content">
                                                                <div className="container-fluid">
                                                                    <div className="row">
                                                                        {/* /.col */}
                                                                        <div className="col-md-12">
                                                                            <div className="card">

                                                                                <div className="card-body">
                                                                                    <div class="container">
                                                                                        <div class="row">
                                                                                            <div class="col">
                                                                                                <button type="button" class="btn btn-primary">NEW TEST</button>
                                                                                            </div>
                                                                                            <div class="col">
                                                                                            </div>
                                                                                            <div class="col">
                                                                                                <button type="button" class="btn btn-primary">QUESTION PAPPER</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="card-body">
                                                                                    <div class="container">
                                                                                        <div class="row">
                                                                                            <div class="col">
                                                                                                <button type="button" class="btn btn-primary">SEARCH</button>
                                                                                            </div>
                                                                                            <div class="col">
                                                                                            </div>
                                                                                            <div class="col">
                                                                                                <button type="button" class="btn btn-primary">MARKS ENTRY</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* /.card-body */}</div>
                                                                            {/* /.nav-tabs-custom */}
                                                                        </div>
                                                                        {/* /.col */}
                                                                    </div>
                                                                    {/* /.row */}
                                                                </div>
                                                                {/* /.container-fluid */}
                                                            </section>
                                                            <section className="content">
                                                                <div className="container-fluid">
                                                                    {/* SELECT2 EXAMPLE */}
                                                                    <div className="card card-default">
                                                                        <div className="card-header">
                                                                            <h3 className="card-title">
                                                                                Please enter new test details:
                                                                            </h3>
                                                                            <span id="First_lblMsg" style={{ color: 'Red', fontWeight: 'bold' }} />
                                                                        </div>
                                                                        {/* /.card-header */}
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Course:</label>
                                                                                        <input name="ctl00$First$txtEnqNo" type="text"  id="First_txtEnqNo" className="form-control" placeholder="Enter Number" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Date:</label>
                                                                                        <input name="ctl00$First$txtEnqNo" type="text"  id="First_txtEnqNo" className="form-control" placeholder="Enter Number" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Batch:</label>
                                                                                        <input name="ctl00$First$txtName" type="text" id="First_txtName" className="form-control" placeholder="Asset Name" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <div className="form-group">
                                                                                            <label>Subject:</label>
                                                                                            <input name="ctl00$First$txtName" type="text" id="First_txtName" className="form-control" placeholder="Subject Name" />
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* /.form-group */}
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Teacher Name:</label>
                                                                                        <input name="ctl00$First$txtName" type="text" id="First_txtName" className="form-control" placeholder="Enter Name" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Test Code:</label>
                                                                                        <input name="ctl00$First$txtName" type="text" id="First_txtName" className="form-control" placeholder="Enter Code" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    {/* /.form-group */}
                                                                                    <div className="form-group">
                                                                                        <label>TOPIC:</label>
                                                                                        <input name="ctl00$First$txtFName" type="text" id="First_txtFName" className="form-control" placeholder="Enter Name" />
                                                                                    </div>
                                                                                </div>
                                                                                {/* /.col */}
                                                                                <div className="col-md-6">
                                                                                    {/* /.form-group */}
                                                                                    <div className="form-group">
                                                                                        <label>LEVEL:</label>
                                                                                        <input name="ctl00$First$txtFName" type="text" id="First_txtFName" className="form-control" placeholder="" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    {/* /.form-group */}
                                                                                    <div class="mb-3">
                                                                                        <label for="formFile" class="form-label">Upload Question Paper</label>
                                                                                        <input class="form-control" type="file" id="formFile" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    {/* /.form-group */}
                                                                                    <div class="mb-3">
                                                                                        <label for="formFile" class="form-label">Upload Answer Key</label>
                                                                                        <input class="form-control" type="file" id="formFile" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    {/* /.form-group */}
                                                                                    <div className="form-group">
                                                                                        <div className="card-footer">
                                                                                            <a class="btn btn-primary" href="#" role="button">Create</a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {/* /.row */}
                                                                            {/* /.row */}
                                                                        </div>
                                                                        {/* /.card-body */}

                                                                    </div>
                                                                    {/* /.card */}
                                                                    {/* SELECT2 EXAMPLE */}
                                                                    {/* /.card */}
                                                                    {/* /.row */}
                                                                </div>
                                                                {/* /.container-fluid */}
                                                            </section>
                                                            <section className="content">
                                                                <div className="container-fluid">
                                                                    <div className="row">
                                                                        {/* /.col */}
                                                                        <div className="col-md-12">
                                                                            <div className="card">
                                                                                <div className="card-header p-2">
                                                                                    <h3 style={{ textAlign: "center" }}>TEST QUESTION PAPPER-VIEW</h3>
                                                                                </div>
                                                                                {/* /.card-header */}
                                                                                <div className="card-body">
                                                                                    <div class="container">
                                                                                        <div class="row">
                                                                                            <div className="col-md-6">
                                                                                                {/* /.form-group */}
                                                                                                <div className="form-group">
                                                                                                    <label>COURSE:</label>
                                                                                                    <input name="ctl00$First$txtFName" type="text" id="First_txtFName" className="form-control" placeholder="Enter Name" />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col">
                                                                                            </div>
                                                                                            <div class="col">
                                                                                                <button type="button" class="btn btn-primary">ALL</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* /.card-body */}</div>
                                                                            {/* /.nav-tabs-custom */}
                                                                        </div>
                                                                        {/* /.col */}
                                                                    </div>
                                                                    {/* /.row */}
                                                                </div>
                                                                {/* /.container-fluid */}
                                                            </section>
                                                            <section className="content">
                                                                <div className="container-fluid">
                                                                    {/* SELECT2 EXAMPLE */}
                                                                    <div className="card card-default">
                                                                        <div className="card-header">
                                                                           
                                                                            <span id="First_lblMsg" style={{ color: 'Red', fontWeight: 'bold' }} />
                                                                        </div>
                                                                        {/* /.card-header */}
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Course:</label>
                                                                                        <input name="ctl00$First$txtEnqNo" type="text"  id="First_txtEnqNo" className="form-control" placeholder="Enter Name" />
                                                                                    </div>
                                                                                </div>
                                                                              
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label>Batch:</label>
                                                                                        <input name="ctl00$First$txtName" type="text" id="First_txtName" className="form-control" placeholder="" />
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                                <div className="col-md-6">
                                                                                    {/* /.form-group */}
                                                                                    <div className="form-group">
                                                                                        <div className="card-footer">
                                                                                            <a class="btn btn-primary" href="#" role="button">New Entry</a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    {/* /.form-group */}
                                                                                    <div className="form-group">
                                                                                        <div className="card-footer">
                                                                                            <a class="btn btn-primary" href="#" role="button">View</a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {/* /.row */}
                                                                            {/* /.row */}
                                                                        </div>
                                                                        {/* /.card-body */}

                                                                    </div>
                                                                    {/* /.card */}
                                                                    {/* SELECT2 EXAMPLE */}
                                                                    {/* /.card */}
                                                                    {/* /.row */}
                                                                </div>
                                                                {/* /.container-fluid */}
                                                            </section>
                                                            {/* /.post */}
                                                        </div>
                                                        {/* /.tab-pane */}


                                                        {/* /.tab-pane */}
                                                    </div>
                                                    {/* /.tab-content */}
                                                </div>
                                                {/* /.card-body */}
                                            </div>
                                            {/* /.nav-tabs-custom */}
                                        </div>
                                        {/* /.col */}
                                    </div>
                                    {/* /.row */}
                                </div>
                                {/* /.container-fluid */}
                            </section>
                        </form>
                        {/* /.row */}
                        {/* /.row */}
                    </div>
                    {/*/. container-fluid */}
                </section>
                {/* /.content */}
            </div>
        </div>
    )
}

export default Newtest