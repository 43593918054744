import "./App.css";
import { HashRouter, Switch, Route } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Footer from "./Headerfooter/Footer";
import Registration from "./Student/Registration";
import RegistrationEdit from "./Student/RegistrationEdit";
import Demo from "./Student/Demo";
import DemoEdit from "./Student/DemoEdit";
import StaffRegistration from "./Staff/StaffRegistration";
import StaffRegistrationEdit from "./Staff/StaffRegistrationEdit";
import StaffDemo from "./Staff/StaffDemo";
import StaffDemoEdit from "./Staff/StaffDemoEdit";
import Application from "./Staff/Application";
import ApplicationEdit from "./Staff/ApplicationEdit";
import Salary from "./Staff/Salary";
import Attendence from "./Student/Attendence";
import Enquiry from "./Student/Enquiry";
import EnquiryEdit from "./Student/EnquiryEdit";
import Exam from "./Student/Exam";
import Icard from "./Student/Icard";
import Results from "./Student/Results";
import Createtest from "./Test-Exam/Createtest";
import Publishtest from "./Test-Exam/Publishtest";
import Questionbank from "./Test-Exam/Questionbank";
import Addnotes from "./Notes/Addnotes";
import Distribution from "./Notes/Distribution";
import Lists from "./Notes/Lists";
import Dailyexpences from "./Inventory/Dailyexpences";
import Feescollection from "./Inventory/Feescollection";
import Handoveramount from "./Inventory/Handoveramount";
import Salarydistribution from "./Inventory/Salarydistribution";
import Career from "./Website/Career";
import Careerenquiry from "./Website/Careerenquiry";
import Dailyupdates from "./Website/Dailyupdates";
import Downloads from "./Website/Downloads";
import Testseries from "./Website/Testseries";
import Vacancies from "./Website/Vacancies";
import Fees from "./Student/Fees";
import Staff from "./Staff/Staff";
import Notice from "./Inventory/Notice";
import Expences from "./Inventory/Expences";
import Document from "./Staff/Document";
import Reports from "./Staff/Reports";
import Addmission from "./Student/Addmission";
import Todolist from "./Inventory/Todolist";
import Software from "./Student/Software";
import Demo1 from "./Student/Demo1";
import InventoryAdd from "./Inventory/InventoryAdd";
import Newtest from "./Test-Exam/Newtest";
import Profile from "./Student/Profile";
import Addmission1 from "./Student/Addmission1";
import Dahsboar1 from "./Staff/Dahsboar1";
import EnquiryList from "./Student/EnquiryList";
import DemoList from "./Student/DemoList";
import RegistrationList from "./Student/RegistrationList";
import ApplicationList from "./Staff/ApplicationList";
import StaffDemoList from "./Staff/StaffDemoList";
import StaffRegistrationList from "./Staff/StaffRegistrationList";
import StudentIcard from "./Student/StudentIcard";
import Role from "./Role/Role";
import Role1 from "./Role1/Role1";
import RoleModal from "./Role/RoleModal";
import RoleModal1 from "./Role1/RoleModal1";
import User from "./User/User";
import UserModal from "./User/UserModal";
import User1 from "./User1/User1";
import UserModal1 from "./User1/UserModal1";
import Login from "./Login/Login"
import ForgotPassword from "./Login/ForgotPassword"

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Switch>
          <Route exact path="/" component={Login}/>
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/registration" component={Registration} />
          <Route exact path="/registrationedit" component={RegistrationEdit} />
          <Route exact path="/demo" component={Demo} />
          <Route exact path="/demoedit" component={DemoEdit} />
          <Route exact path="/staffregistration" component={StaffRegistration}/>
          <Route exact path="/staffregistrationedit" component={StaffRegistrationEdit} />
          <Route exact path="/staffdemo" component={StaffDemo} />
          <Route exact path="/staffdemoedit" component={StaffDemoEdit} />
          <Route exact path="/application" component={Application} />
          <Route exact path="/applicationedit" component={ApplicationEdit} />
          <Route exact path="/salary" component={Salary} />
          <Route exact path="/attendence" component={Attendence} />
          <Route exact path="/enquiry" component={Enquiry} />
          <Route exact path="/enquiryedit" component={EnquiryEdit} />
          <Route exact path="/exam" component={Exam} />
          <Route exact path="/icard" component={Icard} />
          <Route exact path="/results" component={Results} />
          <Route exact path="/createtest" component={Createtest} />
          <Route exact path="/pubishtest" component={Publishtest} />
          <Route exact path="/quistionbank" component={Questionbank} />
          <Route exact path="/addnotes" component={Addnotes} />
          <Route exact path="/distribution" component={Distribution} />
          <Route exact path="/lists" component={Lists} />
          <Route exact path="/dailyexpences" component={Dailyexpences} />
          <Route exact path="/feescollection" component={Feescollection} />
          <Route exact path="/handoveramount" component={Handoveramount} />
          <Route exact path="/fees" component={Fees} />
          <Route exact path="/staff" component={Staff} />
          <Route exact path="/notice" component={Notice} />
          <Route exact path="/expences" component={Expences} />
          <Route exact path="/document" component={Document} />
          <Route exact path="/reports" component={Reports} />
          <Route exact path="/addmission" component={Addmission} />
          <Route exact path="/todolist" component={Todolist} />
          <Route exact path="/software" component={Software} />
          <Route exact path="/demo" component={Demo1} />
          <Route exact path="/inventoryadd" component={InventoryAdd} />
          <Route exact path="/newtest" component={Newtest} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/addmission1" component={Addmission1} />
          <Route exact path="/demo1" component={Demo1} />
          <Route exact path="/dashboard1" component={Dahsboar1} />
          <Route exact path="/salarydistribution" component={Salarydistribution}/>
          <Route exact path="/career" component={Career} />
          <Route exact path="/careerenquiry" component={Careerenquiry} />
          <Route exact path="/dailyupdates" component={Dailyupdates} />
          <Route exact path="/downloads" component={Downloads} />
          <Route exact path="/testseries" component={Testseries} />
          <Route exact path="/vacancies" component={Vacancies} />
          <Route exact path="/enquirylist" component={EnquiryList} />
          <Route exact path="/demolist" component={DemoList} />
          <Route exact path="/registrationlist" component={RegistrationList} />
          <Route exact path="/applicationlist" component={ApplicationList} />
          <Route exact path="/staffdemolist" component={StaffDemoList} />
          <Route exact path="/studenticard" component={StudentIcard} />
          <Route exact path="/staffregistrationlist" component={StaffRegistrationList}/>
          <Route exact path="/role" component={Role} />
          <Route exact path="/roles" component={Role1} />
          <Route exact path="/roleadd" component={RoleModal} />
          <Route exact path="/rolesadd" component={RoleModal1} />
          <Route exact path="/roleedit/:id" component={RoleModal} />
          <Route exact path="/rolesedit/:id" component={RoleModal1} />
          <Route exact path="/user" component={User} />
          <Route exact path="/useradd" component={UserModal} />
          <Route exact path="/useredit/:id" component={UserModal} />
          <Route exact path="/user1" component={User1} />
          <Route exact path="/useradd1" component={UserModal1} />
          <Route exact path="/useredit1/:id" component={UserModal1} />
          <Route exact path="/forgot" component={ForgotPassword} />
        </Switch>
        <Footer />
      </HashRouter>
    </div>
  );
}

export default App;
