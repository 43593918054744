import axios from "axios";
import React, { useState, useEffect } from "react";
import {  useParams } from "react-router-dom";
import Sidebar from "../Headerfooter/Sidebar";
import { baseUrl } from '../BaseUrl'


const AccessModal = (props) => {
  const config = {
    headers: {
      "Content-Type": "Application/json",
      "x-auth-token": `${localStorage.getItem("token")}`,
    },
  };
  const userbranch = localStorage.getItem("userbranch")
  const params = useParams();
  const [form, setForm] = useState({
    roleTitle: "",
    staffbranch: userbranch,
    permissions: {
      studentEnquiry: [],
      studentDemo: [],
      studentRegistration: [],
      studentIcard: [],
      staffApplication: [],
      staffDemo: [],
      staffRegistration: [],
      staffSalary: [],
      accountsFeesCollection: [],
      accountsSalaryDistribution: [],
      accountsDailyExpenses: [],
      accountsHandoverAmount: [],
      roles: [],
      user: [],
    },
  });

  useEffect(() => {
    if (params.id) {
      getRoleById()
    }
  }, []);
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("newtoken") ? true : false
    if (isLoggedIn===false) {
      props.history.push("/")
    }
  }, []);

  const getRoleById = () => {
   axios
      .get(baseUrl+`/api/role/${params.id}`, config)
      .then((response) => {
        if (response.data) {
         setForm(response.data.data)
        
        }
      })
      .catch((err) => {
        alert("error");
      });
  };
  const handleChange = (e) => {
    const form_ = { ...form };
    form_.roleTitle = e.target.value;
    setForm(form_);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked, value } = e.target;
    let form_ = { ...form };
    let permissions = form_.permissions;
    if (checked) {
      permissions[name].push(value);
    } else {
      permissions[name] = permissions[name].filter((item) => item !== value);
    }
    setForm(form_);
  };



  const handleSubmit = () => {
    if(params.id){
      axios
        .put(baseUrl+"/api/role/"+params.id, form)
        .then((response) => {
          props.history.push("/roles");
        })
        .catch(function (error) {
          alert("error");
        });
    }else{
      axios
        .post(baseUrl+"/api/role", form)
        .then((response) => {
          props.history.push("/roles");
        })
        .catch(function (error) {
          alert("error");
        });
    }
    
  };

  const closeModal = () => {
    props.history.push("/roles");
  };

  return (
    <>
    
    <Sidebar props={props}/>
    
    <div className="content-wrapper" style={{marginLeft:"15%"}}>
      <div className="modal-wrapper">
        <h3 className="modal-title">{params?.id ? "View" : "Add"} Role</h3>
        <div>
          <div class="container" style={{ textAlign: "center" }}>
            < input style={{marginRight:15}}
              name="roleTitle"
              type="text"
              placeholder="Enter Role Title"
              className="modal-input"
              value={form?.roleTitle}
              onChange={handleChange}
            />  
           
            <div class="row" style={{ padding: "50px", paddingTop: "unset" }}>
              <div class="col-12">
            
                <table class="table table-bordered">
                  <thead>
                    <tr>
                    
                      <th scope="col"><h5 style={{width:100}}> Permission</h5></th>
                      <th scope="col"><h5 style={{width:100}}> View </h5></th>
                      <th scope="col"><h5 style={{width:100}}>  Add </h5></th>
                      <th scope="col"><h5 style={{width:100}}> Edit </h5></th>
                      <th scope="col"><h5 style={{width:100}}>Delete</h5></th>
                    </tr>
                  </thead>
                  <tbody>
                   {localStorage.getItem("studentEnquiryview")==="yes"? <tr>
                      
                      <td>Student Enquiry</td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="studentEnquiry"
                            value="view"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.studentEnquiry?.includes(
                              "view"
                            )}
                          />
                          <label htmlFor="">View</label>
                        </div>
                      </td>
                      <td>
                      {localStorage.getItem("studentEnquiryadd")==="yes"? <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="studentEnquiry"
                            value="add"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.studentEnquiry?.includes(
                              "add"
                            )}
                          />
                          <label htmlFor="">Add</label>
                        </div>:null}
                      </td>
                      <td>
                      {localStorage.getItem("studentEnquiryedit")==="yes"?<div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="studentEnquiry"
                            value="edit"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.studentEnquiry?.includes(
                              "edit"
                            )}
                          />
                          <label htmlFor="">Edit</label>
                        </div>:null}
                      </td>
                      <td>
                      {localStorage.getItem("studentEnquirydelete")==="yes"?<div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="studentEnquiry"
                            value="delete"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.studentEnquiry?.includes(
                              "delete"
                            )}
                          />
                          <label htmlFor="">Delete</label>
                        </div>:null}
                      </td>
                    </tr>:null}
                    {localStorage.getItem("studentDemoview")==="yes"? <tr>
                      <td>Student Demo</td>
                      <td>
                      <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="studentDemo"
                            value="view"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.studentDemo?.includes(
                              "view"
                            )}
                          />
                          <label htmlFor="">View</label>
                        </div>
                      </td>
                      <td>
                      {localStorage.getItem("studentDemoadd")==="yes"?<div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="studentDemo"
                            value="add"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.studentDemo?.includes(
                              "add"
                            )}
                          />
                          <label htmlFor="">Add</label>
                        </div>:null}
                      </td>
                      <td>
                      {localStorage.getItem("studentDemoedit")==="yes"?<div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="studentDemo"
                            value="edit"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.studentDemo?.includes(
                              "edit"
                            )}
                          />
                          <label htmlFor="">Edit</label>
                        </div>:null}
                      </td>
                      <td>
                      {localStorage.getItem("studentDemodelete")==="yes"?<div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="studentDemo"
                            value="delete"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.studentDemo?.includes(
                              "delete"
                            )}
                          />
                          <label htmlFor="">Delete</label>
                        </div>:null}
                      </td>
                    </tr>:null}
                    {localStorage.getItem("studentRegistrationview")==="yes"?<tr>
                      <td>Student Registration</td>
                      <td>
                     <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="studentRegistration"
                            value="view"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.studentRegistration?.includes(
                              "view"
                            )}
                          />
                          <label htmlFor="">View</label>
                        </div>
                      </td>
                      <td>
                      {localStorage.getItem("studentRegistrationadd")==="yes"?<div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="studentRegistration"
                            value="add"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.studentRegistration?.includes(
                              "add"
                            )}
                          />
                          <label htmlFor="">Add</label>
                        </div>:null}
                      </td>
                      <td>
                      {localStorage.getItem("studentRegistrationedit")==="yes"? <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="studentRegistration"
                            value="edit"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.studentRegistration?.includes(
                              "edit"
                            )}
                          />
                          <label htmlFor="">Edit</label>
                        </div>:null}
                      </td>
                      <td>
                      {localStorage.getItem("studentRegistrationdelete")==="yes"? <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="studentRegistration"
                            value="delete"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.studentRegistration?.includes(
                              "delete"
                            )}
                          />
                          <label htmlFor="">Delete</label>
                        </div>:null}
                      </td>
                    </tr>:null}
                    {localStorage.getItem("studentIcardview")==="yes"?<tr>

                      <td>Student Icard</td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="studentIcard"
                            value="view"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.studentIcard?.includes(
                              "view"
                            )}
                          />
                          <label htmlFor="">View</label>
                        </div>
                      </td>
                      <td>
                        {localStorage.getItem("studentIcardadd")==="yes"?<div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="studentIcard"
                            value="add"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.studentIcard?.includes(
                              "add"
                            )}
                          />
                          <label htmlFor="">Add</label>
                        </div>:null}
                      </td>
                      <td>
                        {localStorage.getItem("studentIcardedit")==="yes"?<div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="studentIcard"
                            value="edit"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.studentIcard?.includes(
                              "edit"
                            )}
                          />
                          <label htmlFor="">Edit</label>
                        </div>:null}
                      </td>
                      <td>
                        {localStorage.getItem("studentIcarddelete")==="yes"?<div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="studentIcard"
                            value="delete"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.studentIcard?.includes(
                              "delete"
                            )}
                          />
                          <label htmlFor="">Delete</label>
                        </div>:null}
                      </td>
                    </tr>:null}
                    {localStorage.getItem("staffApplicationview")==="yes"?<tr>

                      <td>Staff Application</td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="staffApplication"
                            value="view"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.staffApplication?.includes("view")}
                          />
                          <label htmlFor="">View</label>
                        </div>
                      </td>
                      <td>
                        {localStorage.getItem("staffApplicationadd")==="yes"?<div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="staffApplication"
                            value="add"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.staffApplication?.includes("add")}
                          />
                          <label htmlFor="">Add</label>
                        </div>:null}
                      </td>
                      <td>
                        {localStorage.getItem("staffApplicationedit")==="yes"?<div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="staffApplication"
                            value="edit"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.staffApplication?.includes("edit")}
                          />
                          <label htmlFor="">Edit</label>
                        </div>:null}
                      </td>
                      <td>
                        {localStorage.getItem("staffApplicationdelete")==="yes"?<div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="staffApplication"
                            value="delete"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.staffApplication?.includes(
                              "delete"
                            )}
                          />
                          <label htmlFor="">Delete</label>
                        </div>:null}
                      </td>
                    </tr>:null}
                    {localStorage.getItem("staffDemoview")==="yes"?<tr>
                      
                      <td>Staff Demo</td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="staffDemo"
                            value="view"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.staffDemo?.includes(
                              "view"
                            )}
                          />
                          <label htmlFor="">View</label>
                        </div>
                      </td>
                      <td>
                        {localStorage.getItem("staffDemoadd")==="yes"?<div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="staffDemo"
                            value="add"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.staffDemo?.includes(
                              "add"
                            )}
                          />
                          <label htmlFor="">Add</label>
                        </div>:null}
                      </td>
                      <td>
                       {localStorage.getItem("staffDemoedit")==="yes"? <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="staffDemo"
                            value="edit"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.staffDemo?.includes(
                              "edit"
                            )}
                          />
                          <label htmlFor="">Edit</label>
                        </div>:null}
                      </td>
                      <td>
                        {localStorage.getItem("staffDemodelete")==="yes"?<div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="staffDemo"
                            value="delete"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.staffDemo?.includes(
                              "delete"
                            )}
                          />
                          <label htmlFor="">Delete</label>
                        </div>:null}
                      </td>
                    </tr>:null}
                    {localStorage.getItem("staffRegistrationview")==="yes"?<tr>
                      
                      <td>Staff Registration</td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="staffRegistration"
                            value="view"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.staffRegistration?.includes(
                              "view"
                            )}
                          />
                          <label htmlFor="">View</label>
                        </div>
                      </td>
                      <td>
                       {localStorage.getItem("staffRegistrationadd")==="yes"? <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="staffRegistration"
                            value="add"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.staffRegistration?.includes(
                              "add"
                            )}
                          />
                          <label htmlFor="">Add</label>
                        </div>:null}
                      </td>
                      <td>
                        {localStorage.getItem("staffRegistrationedit")==="yes"?<div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="staffRegistration"
                            value="edit"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.staffRegistration?.includes(
                              "edit"
                            )}
                          />
                          <label htmlFor="">Edit</label>
                        </div>:null}
                      </td>
                      <td>
                        {localStorage.getItem("staffRegistrationdelete")==="yes"?<div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="staffRegistration"
                            value="delete"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.staffRegistration?.includes(
                              "delete"
                            )}
                          />
                          <label htmlFor="">Delete</label>
                        </div>:null}
                      </td>
                    </tr>:null}
                   {localStorage.getItem("staffSalaryview")==="yes"? <tr>
                      
                      <td>Staff Salary</td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="staffSalary"
                            value="view"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.staffSalary?.includes(
                              "view"
                            )}
                          />
                          <label htmlFor="">View</label>
                        </div>
                      </td>
                      <td>
                        {localStorage.getItem("staffSalaryadd")==="yes"?<div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="staffSalary"
                            value="add"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.staffSalary?.includes(
                              "add"
                            )}
                          />
                          <label htmlFor="">Add</label>
                        </div>:null}
                      </td>
                      <td>
                        {localStorage.getItem("staffSalaryedit")==="yes"?<div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="staffSalary"
                            value="edit"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.staffSalary?.includes(
                              "edit"
                            )}
                          />
                          <label htmlFor="">Edit</label>
                        </div>:null}
                      </td>
                      <td>
                        {localStorage.getItem("staffSalarydelete")==="yes"?<div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="staffSalary"
                            value="delete"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.staffSalary?.includes(
                              "delete"
                            )}
                          />
                          <label htmlFor="">Delete</label>
                        </div>:null}
                      </td>
                      
                    </tr>:null}
                    {localStorage.getItem("accountsFeesCollectionview")==="yes"?<tr>
                      
                      <td>Accounts Fees Collection</td>
                      <td>
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="accountsFeesCollection"
                            value="view"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.accountsFeesCollection?.includes(
                              "view"
                            )}
                          />
                          <label htmlFor="">View</label>
                        </div>
                      </td>
                      <td>
                       {localStorage.getItem("accountsFeesCollectionadd")==="yes"? <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="accountsFeesCollection"
                            value="add"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.accountsFeesCollection?.includes(
                              "add"
                            )}
                          />
                          <label htmlFor="">Add</label>
                        </div>:null}
                      </td>
                      <td>
                       {localStorage.getItem("accountsFeesCollectionedit")==="yes"? <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="accountsFeesCollection"
                            value="edit"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.accountsFeesCollection?.includes(
                              "edit"
                            )}
                          />
                          <label htmlFor="">Edit</label>
                        </div>:null}
                      </td>
                      <td>
                       {localStorage.getItem("accountsFeesCollectiondelete")==="yes"? <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="accountsFeesCollection"
                            value="delete"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.accountsFeesCollection?.includes(
                              "delete"
                            )}
                          />
                          <label htmlFor="">Delete</label>
                        </div>:null}
                      </td>
                    </tr>:null}
                    {localStorage.getItem("accountsSalaryDistributionview")==="yes"?<tr>
                      
                      <td>Accounts Salary Distribution</td>
                      <td >
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="accountsSalaryDistribution"
                            value="view"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.accountsSalaryDistribution?.includes(
                              "view"
                            )}
                          />
                          <label htmlFor="">View</label>
                        </div>
                      </td>
                      <td >
                        {localStorage.getItem("accountsSalaryDistributionadd")==="yes"?<div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="accountsSalaryDistribution"
                            value="add"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.accountsSalaryDistribution?.includes(
                              "add"
                            )}
                          />
                          <label htmlFor="">Add</label>
                        </div>:null}
                      </td>
                      <td >
                        {localStorage.getItem("accountsSalaryDistributionedit")==="yes"?<div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="accountsSalaryDistribution"
                            value="edit"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.accountsSalaryDistribution?.includes(
                              "edit"
                            )}
                          />
                          <label htmlFor="">Edit</label>
                        </div>:null}
                      </td>
                      <td >
                        {localStorage.getItem("accountsSalaryDistributiondelete")==="yes"?<div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="accountsSalaryDistribution"
                            value="delete"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.accountsSalaryDistribution?.includes(
                              "delete"
                            )}
                          />
                          <label htmlFor="">Delete</label>
                        </div>:null}
                      </td>
                    </tr>:null}
                   {localStorage.getItem("accountsDailyExpensesview")==="yes"? <tr>
                      
                      <td>Accounts Daily Expences</td>
                      <td >
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="accountsDailyExpenses"
                            value="view"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.accountsDailyExpenses?.includes(
                              "view"
                            )}
                          />
                          <label htmlFor="">View</label>
                        </div>
                      </td>
                      <td >
                        {localStorage.getItem("accountsDailyExpensesadd")==="yes"?<div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="accountsDailyExpenses"
                            value="add"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.accountsDailyExpenses?.includes(
                              "add"
                            )}
                          />
                          <label htmlFor="">Add</label>
                        </div>:null}
                      </td>
                      <td >
                        {localStorage.getItem("accountsDailyExpensesedit")==="yes"?<div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="accountsDailyExpenses"
                            value="edit"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.accountsDailyExpenses?.includes(
                              "edit"
                            )}
                          />
                          <label htmlFor="">Edit</label>
                        </div>:null}
                      </td>
                      <td >
                        {localStorage.getItem("accountsDailyExpensesdelete")==="yes"?<div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="accountsDailyExpenses"
                            value="delete"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.accountsDailyExpenses?.includes(
                              "delete"
                            )}
                          />
                          <label htmlFor="">Delete</label>
                        </div>:null}
                      </td>
                    </tr>:null}
                   {localStorage.getItem("accountsHandoverAmountview")==="yes"? <tr>
                      
                      <td>Accounts Handover Amount</td>
                      <td >
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="accountsHandoverAmount"
                            value="view"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.accountsHandoverAmount?.includes(
                              "view"
                            )}
                          />
                          <label htmlFor="">View</label>
                        </div>
                      </td>
                      <td >
                        {localStorage.getItem("accountsHandoverAmountadd")==="yes"?<div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="accountsHandoverAmount"
                            value="add"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.accountsHandoverAmount?.includes(
                              "add"
                            )}
                          />
                          <label htmlFor="">Add</label>
                        </div>:null}
                      </td>
                      <td >
                        {localStorage.getItem("accountsHandoverAmountedit")==="yes"?<div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="accountsHandoverAmount"
                            value="edit"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.accountsHandoverAmount?.includes(
                              "edit"
                            )}
                          />
                          <label htmlFor="">Edit</label>
                        </div>:null}
                      </td>
                      <td >
                        {localStorage.getItem("accountsHandoverAmountdelete")==="yes"?<div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="accountsHandoverAmount"
                            value="delete"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.accountsHandoverAmount?.includes(
                              "delete"
                            )}
                          />
                          <label htmlFor="">Delete</label>
                        </div>:null}
                      </td>
                    </tr>:null}

                    {localStorage.getItem(" userview")==="yes"?  <tr>
                      
                      <td>User</td>
                      <td >
                        <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="user"
                            value="view"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.user?.includes(
                              "view"
                            )}
                          />
                          <label htmlFor="">View</label>
                        </div>
                      </td>
                      <td >
                      {localStorage.getItem("useradd")==="yes"?  <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="user"
                            value="add"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.user?.includes(
                              "add"
                            )}
                          />
                          <label htmlFor="">Add</label>
                        </div>:null}
                      </td>
                      <td >
                       {localStorage.getItem("useredit")==="yes"? <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="user"
                            value="edit"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.user?.includes(
                              "edit"
                            )}
                          />
                          <label htmlFor="">Edit</label>
                        </div>:null}
                      </td>
                      <td >
                       {localStorage.getItem("userdelete")==="yes"? <div className="checkbox-group">
                          < input style={{marginRight:15}}
                            type="checkbox"
                            name="user"
                            value="delete"
                            onChange={handleCheckboxChange}
                            checked={form?.permissions?.user?.includes(
                              "delete"
                            )}
                          />
                          <label htmlFor="">Delete</label>
                        </div>:null}
                      </td>
                    </tr>:null}
                   
                  </tbody>
                </table>
                {/* {JSON.stringify(form)} */}
                <button
                  onClick={()=>handleSubmit()}
                  style={{ margin: "0 auto" }}
                  className="btn btn-success"
                >Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default AccessModal;
