import React,{useEffect} from 'react'
import Sidebar from "../Headerfooter/Sidebar";

function Exam(props) {
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("newtoken") ? true : false
    if (isLoggedIn===false) {
      props.history.push("/")
    }
  }, []);
  return (
    <div><Sidebar props={props} />Exam</div>
  )
}

export default Exam