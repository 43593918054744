import React, { useState, useEffect } from "react";
import Sidebar from "../Headerfooter/Sidebar";
import axios from "axios";
import yourhandle from "countrycitystatejson";
import storage from "../Student/Firebase";
// RegistrationNumber
// ApplicantName
// MobileNo
// EmailID
// DateofBirth
// Category
// Gender
// FathersName
// MotherName
// Address
// City
// State
// Qualification
// Experience
// LastOffice
// DemoDate
// Post
// JobType
// BasicSalary
// JoiningDate
// BankName
// AccountNumber
// ApplicationNumber
// ApplicationFor
// UploadCV
// Name
// EnquiryNo
// EmployeeID
// EmployeeName
// Role
// Designation
// PreviousBalance
// Date
// Advance
// Month
// TotalAmount
// Deduction
// NetAmount
// CheckNo
// Bank

function Application(props) {
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("newtoken") ? true : false
    if (isLoggedIn===false) {
      props.history.push("/")
    }
  }, []);
  const uploadImage1 = (x) => {
    const image1 = x;
    if (image1 !== "") {
      const uploadTask = storage.ref(`images/${image1.name}`).put(image1);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          setProgress1(
            Math.ceil((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
          );
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(image1.name)
            .getDownloadURL()
            .then((url) =>
              setPostItem({
                ...postItem,
                UploadCV: url,
              })
            );
        }
      );
    } else {
      alert("no image selected");
    }
  };
  const [progress1, setProgress1] = useState("");
  const getStateList = () => {
    const list = yourhandle.getStatesByShort("IN");

    return list.map((i) => <option>{i}</option>);
  };
  const getCityList = () => {
    const list = yourhandle.getCities("IN", postItem.State);
    return list.map((i) => <option>{i}</option>);
  };
  useEffect(() => {
    getCourses();
  }, []);
  const getCourses = () => {
    axios
      .get("https://ajiosys.in/api/courses")
      .then((res) => setCourses(res.data.data));
  };

  const [courses, setCourses] = useState([]);
  const [postItem, setPostItem] = useState({});
  const handleChange = (e) => {
    setPostItem({ ...postItem, [e.target.name]: e.target.value });
  };
  const eId = localStorage.getItem("applicationid");
  const postData = () => {
    const item = { ...postItem, Application: true };
    axios
      .put(`https://ajiosys.in/api/staffdetails/${eId}`, item)
      .then(() => props.history.push(""));
  };

  const getData = () => {
    axios
      .get(`https://ajiosys.in/api/staffdetails/${eId}`)
      .then((res) => setPostItem(res.data.data));
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <Sidebar props={props} />
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">New Applications</h1>
              </div>
              {/* /.col */}
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right"></ol>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>
        {/* /.content-header */}
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  {/* /.col */}
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header p-2">
                        <ul className="nav nav-pills">
                          <li className="nav-item">
                            <a className="nav-link active">Add Application</a>
                          </li>
                        </ul>
                      </div>
                      {/* /.card-header */}
                      <div className="card-body">
                        <div className="tab-content">
                          <div className="active tab-pane" id="activity">
                            {/* Post */}
                            <section className="content">
                              <div className="container-fluid">
                                {/* SELECT2 EXAMPLE */}
                                <div className="card card-default">
                                  <div className="card-header">
                                    <h3 className="card-title">
                                      Please enter the required details:
                                    </h3>
                                  </div>
                                  {/* /.card-header */}
                                  <div className="card-body">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Application Number:</label>
                                          <input
                                            value={postItem.ApplicationNumber}
                                            readOnly="readOnly"
                                            className="form-control"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Application For:</label>
                                          <select
                                            name="ApplicationFor"
                                            value={postItem.ApplicationFor}
                                            onChange={handleChange}
                                            className="form-control select2"
                                            style={{ width: "100%" }}
                                          >
                                            <option value="Select">
                                              Select
                                            </option>
                                            <option value="Faculty">
                                              Faculty
                                            </option>
                                            <option value="Office Staff">
                                              Office Staff
                                            </option>
                                            <option value="Others">
                                              Others
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Applicant Name</label>
                                          <input
                                            name="ApplicantName"
                                            value={postItem.ApplicantName}
                                            onChange={handleChange}
                                            type="text"
                                            id="First_txtName"
                                            className="form-control"
                                            placeholder="Student Name"
                                          />
                                        </div>
                                        {/* /.form-group */}
                                        <div className="form-group">
                                          <label>Email ID</label>
                                          <input
                                            name="EmailID"
                                            value={postItem.EmailID}
                                            onChange={handleChange}
                                            type="text"
                                            id="First_txtEmail"
                                            className="form-control"
                                            placeholder="Email ID"
                                          />
                                        </div>
                                        {/* /.form-group */}
                                      </div>
                                      {/* /.col */}
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Mobile No.</label>
                                          <input
                                            name="MobileNo"
                                            value={postItem.MobileNo}
                                            onChange={handleChange}
                                            type="number"
                                            id="First_txtMobile"
                                            className="form-control"
                                            placeholder="Mobile"
                                          />
                                        </div>
                                        {/* /.form-group */}
                                        <div className="form-group">
                                          <label>Date of birth</label>
                                          <input
                                            name="Dateofbirth"
                                            value={postItem.Dateofbirth}
                                            onChange={handleChange}
                                            type="date"
                                            id="First_txtDOB"
                                            className="form-control"
                                            placeholder="Date of birth"
                                          />
                                        </div>
                                        {/* /.form-group */}
                                      </div>
                                      {/* /.col */}
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Category</label>
                                          <select
                                            name="Category"
                                            value={postItem.Category}
                                            onChange={handleChange}
                                            id="First_ddCategory"
                                            className="form-control select2"
                                            style={{ width: "100%" }}
                                          >
                                            <option value="Select">
                                              Select
                                            </option>
                                            <option value="General">
                                              General
                                            </option>
                                            <option value="OBC">OBC</option>
                                            <option value="SC/ST">SC/ST</option>
                                            <option value="Others">
                                              Others
                                            </option>
                                          </select>
                                        </div>

                                        {/* /.form-group */}
                                        <div>
                                          <span id="First_lblMsg" />
                                        </div>
                                        {/* /.form-group */}
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Gender</label>
                                          <select
                                            name="Gender"
                                            value={postItem.Gender}
                                            onChange={handleChange}
                                            id="First_ddGender"
                                            className="form-control select2"
                                            style={{ width: "100%" }}
                                          >
                                            <option value="Select">
                                              Select
                                            </option>
                                            <option value="Male">Male</option>
                                            <option value="Female">
                                              Female
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Address</label>
                                          <textarea
                                            name="Address"
                                            value={postItem.Address}
                                            onChange={handleChange}
                                            rows={5}
                                            cols={20}
                                            id="First_txtAdd"
                                            className="form-control"
                                            placeholder="Address"
                                            defaultValue={""}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>State</label>
                                          <select
                                            name="State"
                                            value={postItem.State}
                                            onChange={handleChange}
                                            className="form-control"
                                            style={{ width: "100%" }}
                                          >
                                            <option value="" disabled selected>
                                              -Select State-
                                            </option>
                                            {getStateList()}
                                          </select>
                                        </div>

                                        <div className="form-group">
                                          <label>City</label>

                                          <select
                                            name="City"
                                            value={postItem.City}
                                            onChange={handleChange}
                                            className="form-control"
                                          >
                                            <option value="" disabled selected>
                                              -Select City/Town-
                                            </option>
                                            {getCityList()}
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                            {/* /.post */}
                            <section className="content">
                              <div className="container-fluid">
                                {/* SELECT2 EXAMPLE */}
                                <div className="card card-default">
                                  <div className="card-header">
                                    <h3 className="card-title">
                                      Other details:
                                    </h3>
                                  </div>
                                  {/* /.card-header */}
                                  <div className="card-body">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Qualification</label>
                                          <input
                                            name="Qualification"
                                            value={postItem.Qualification}
                                            onChange={handleChange}
                                            type="text"
                                            className="form-control"
                                            placeholder="Qualification"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Experience</label>
                                          <input
                                            name="Experience"
                                            value={postItem.Experience}
                                            onChange={handleChange}
                                            type="text"
                                            id="First_txtExp"
                                            className="form-control"
                                            placeholder="Experience"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Last Office</label>
                                          <input
                                            name="LastOffice"
                                            value={postItem.LastOffice}
                                            onChange={handleChange}
                                            type="text"
                                            id="First_txtLOffice"
                                            className="form-control"
                                            placeholder="Last office name"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Upload C.V.</label>
                                          <br />
                                          <input
                                            type="file"
                                            name="ctl00$First$fileupload"
                                            id="First_fileupload"
                                          />
                                        </div>
                                      </div>
                                      {/* /.col */}
                                      {/* /.col */}
                                      <div className="card-footer">
                                      {localStorage.getItem("staffApplicationedit")==="yes"? <button
                                          onClick={() => postData()}
                                          className="btn btn-primary"
                                        >
                                          Submit
                                        </button>:null}
                                      </div>
                                    </div>
                                    {/* /.row */}
                                    {/* /.row */}
                                  </div>
                                  {/* /.card-body */}
                                </div>
                                {/* /.card */}
                                {/* SELECT2 EXAMPLE */}
                                {/* /.card */}
                                {/* /.row */}
                              </div>
                              {/* /.container-fluid */}
                            </section>
                          </div>
                        </div>
                        {/* /.tab-content */}
                      </div>
                      {/* /.card-body */}
                    </div>
                    {/* /.nav-tabs-custom */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>

            {/* /.row */}
            {/* /.row */}
          </div>
          {/*/. container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </div>
  );
}
export default Application;
