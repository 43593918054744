import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Login1.css";
import { baseUrl } from "../BaseUrl";

function Login(props) {
  useEffect(() => {
    getRoles();
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [roles, setRoles] = useState("");

  const addData = () => {
    const item = {
      email: email,
      password: password,
    };
    axios
      .post(baseUrl + "/api/login", item)
      .then((res) => {
        const userPermission = roles.filter(
          (i) => i._id === res.data.data.role
        )[0].permissions;
        const userBranch = roles.filter((i) => i._id === res.data.data.role)[0];
        localStorage.setItem(
          "studentEnquiryview",
          userPermission.studentEnquiry.includes("view") ? "yes" : "no"
        );
        localStorage.setItem(
          "studentEnquiryadd",
          userPermission.studentEnquiry.includes("add") ? "yes" : "no"
        );
        localStorage.setItem(
          "studentEnquiryedit",
          userPermission.studentEnquiry.includes("edit") ? "yes" : "no"
        );
        localStorage.setItem(
          "studentEnquirydelete",
          userPermission.studentEnquiry.includes("delete") ? "yes" : "no"
        );

        localStorage.setItem(
          "studentDemoview",
          userPermission.studentDemo.includes("view") ? "yes" : "no"
        );
        localStorage.setItem(
          "studentDemoadd",
          userPermission.studentDemo.includes("add") ? "yes" : "no"
        );
        localStorage.setItem(
          "studentDemoedit",
          userPermission.studentDemo.includes("edit") ? "yes" : "no"
        );
        localStorage.setItem(
          "studentDemodelete",
          userPermission.studentDemo.includes("delete") ? "yes" : "no"
        );

        localStorage.setItem(
          "studentRegistrationview",
          userPermission.studentRegistration.includes("view") ? "yes" : "no"
        );
        localStorage.setItem(
          "studentRegistrationadd",
          userPermission.studentRegistration.includes("add") ? "yes" : "no"
        );
        localStorage.setItem(
          "studentRegistrationedit",
          userPermission.studentRegistration.includes("edit") ? "yes" : "no"
        );
        localStorage.setItem(
          "studentRegistrationdelete",
          userPermission.studentRegistration.includes("delete") ? "yes" : "no"
        );

        localStorage.setItem(
          "studentIcardview",
          userPermission.studentIcard.includes("view") ? "yes" : "no"
        );
        localStorage.setItem(
          "studentIcardadd",
          userPermission.studentIcard.includes("add") ? "yes" : "no"
        );
        localStorage.setItem(
          "studentIcardedit",
          userPermission.studentIcard.includes("edit") ? "yes" : "no"
        );
        localStorage.setItem(
          "studentIcarddelete",
          userPermission.studentIcard.includes("delete") ? "yes" : "no"
        );

        localStorage.setItem(
          "staffApplicationview",
          userPermission.staffApplication.includes("view") ? "yes" : "no"
        );
        localStorage.setItem(
          "staffApplicationadd",
          userPermission.staffApplication.includes("add") ? "yes" : "no"
        );
        localStorage.setItem(
          "staffApplicationedit",
          userPermission.staffApplication.includes("edit") ? "yes" : "no"
        );
        localStorage.setItem(
          "staffApplicationdelete",
          userPermission.staffApplication.includes("delete") ? "yes" : "no"
        );

        localStorage.setItem(
          "staffDemoview",
          userPermission.staffDemo.includes("view") ? "yes" : "no"
        );
        localStorage.setItem(
          "staffDemoadd",
          userPermission.staffDemo.includes("add") ? "yes" : "no"
        );
        localStorage.setItem(
          "staffDemoedit",
          userPermission.staffDemo.includes("edit") ? "yes" : "no"
        );
        localStorage.setItem(
          "staffDemodelete",
          userPermission.staffDemo.includes("delete") ? "yes" : "no"
        );

        localStorage.setItem(
          "staffRegistrationview",
          userPermission.staffRegistration.includes("view") ? "yes" : "no"
        );
        localStorage.setItem(
          "staffRegistrationadd",
          userPermission.staffRegistration.includes("add") ? "yes" : "no"
        );
        localStorage.setItem(
          "staffRegistrationedit",
          userPermission.staffRegistration.includes("edit") ? "yes" : "no"
        );
        localStorage.setItem(
          "staffRegistrationdelete",
          userPermission.staffRegistration.includes("delete") ? "yes" : "no"
        );

        localStorage.setItem(
          "staffSalaryview",
          userPermission.staffSalary.includes("view") ? "yes" : "no"
        );
        localStorage.setItem(
          "staffSalaryadd",
          userPermission.staffSalary.includes("add") ? "yes" : "no"
        );
        localStorage.setItem(
          "staffSalaryedit",
          userPermission.staffSalary.includes("edit") ? "yes" : "no"
        );
        localStorage.setItem(
          "staffSalarydelete",
          userPermission.staffSalary.includes("delete") ? "yes" : "no"
        );

        localStorage.setItem(
          "accountsFeesCollectionview",
          userPermission.accountsFeesCollection.includes("view") ? "yes" : "no"
        );
        localStorage.setItem(
          "accountsFeesCollectionadd",
          userPermission.accountsFeesCollection.includes("add") ? "yes" : "no"
        );
        localStorage.setItem(
          "accountsFeesCollectionedit",
          userPermission.accountsFeesCollection.includes("edit") ? "yes" : "no"
        );
        localStorage.setItem(
          "accountsFeesCollectiondelete",
          userPermission.accountsFeesCollection.includes("delete")
            ? "yes"
            : "no"
        );

        localStorage.setItem(
          "accountsSalaryDistributionview",
          userPermission.accountsSalaryDistribution.includes("view")
            ? "yes"
            : "no"
        );
        localStorage.setItem(
          "accountsSalaryDistributionadd",
          userPermission.accountsSalaryDistribution.includes("add")
            ? "yes"
            : "no"
        );
        localStorage.setItem(
          "accountsSalaryDistributionedit",
          userPermission.accountsSalaryDistribution.includes("edit")
            ? "yes"
            : "no"
        );
        localStorage.setItem(
          "accountsSalaryDistributiondelete",
          userPermission.accountsSalaryDistribution.includes("delete")
            ? "yes"
            : "no"
        );

        localStorage.setItem(
          "accountsDailyExpensesview",
          userPermission.accountsDailyExpenses.includes("view") ? "yes" : "no"
        );
        localStorage.setItem(
          "accountsDailyExpensesadd",
          userPermission.accountsDailyExpenses.includes("add") ? "yes" : "no"
        );
        localStorage.setItem(
          "accountsDailyExpensesedit",
          userPermission.accountsDailyExpenses.includes("edit") ? "yes" : "no"
        );
        localStorage.setItem(
          "accountsDailyExpensesdelete",
          userPermission.accountsDailyExpenses.includes("delete") ? "yes" : "no"
        );

        localStorage.setItem(
          "accountsHandoverAmountview",
          userPermission.accountsHandoverAmount.includes("view") ? "yes" : "no"
        );
        localStorage.setItem(
          "accountsHandoverAmountadd",
          userPermission.accountsHandoverAmount.includes("add") ? "yes" : "no"
        );
        localStorage.setItem(
          "accountsHandoverAmountedit",
          userPermission.accountsHandoverAmount.includes("edit") ? "yes" : "no"
        );
        localStorage.setItem(
          "accountsHandoverAmountdelete",
          userPermission.accountsHandoverAmount.includes("delete")
            ? "yes"
            : "no"
        );

        localStorage.setItem(
          "rolesview",
          userPermission.roles.includes("view") ? "yes" : "no"
        );
        localStorage.setItem(
          "rolesadd",
          userPermission.roles.includes("add") ? "yes" : "no"
        );
        localStorage.setItem(
          "rolesedit",
          userPermission.roles.includes("edit") ? "yes" : "no"
        );
        localStorage.setItem(
          "rolesdelete",
          userPermission.roles.includes("delete") ? "yes" : "no"
        );

        localStorage.setItem(
          "userview",
          userPermission.user.includes("view") ? "yes" : "no"
        );
        localStorage.setItem(
          "useradd",
          userPermission.user.includes("add") ? "yes" : "no"
        );
        localStorage.setItem(
          "useredit",
          userPermission.user.includes("edit") ? "yes" : "no"
        );
        localStorage.setItem(
          "userdelete",
          userPermission.user.includes("delete") ? "yes" : "no"
        );

        localStorage.setItem(
          "userbranch",
          userBranch.adminbranch
            ? userBranch.adminbranch
            : userBranch.staffbranch
        );
        localStorage.setItem("roletitle", userBranch.roleTitle);
      })
      .then(() => {
        localStorage.setItem("newtoken", "newtoken");
        props.history.push("/dashboard");
      })
      .catch((err) => alert("Invalid credentials"));
  };
  const getRoles = () => {
    axios.get(baseUrl + "/api/role").then((res) => setRoles(res.data.data));
  };

  return (
    <div>
      <div style={{ paddingTop: "30px", height: "93vh" }}>
        <div class="wrapper fadeInDown">
          <div id="formContent">
            <h1>LOG!N </h1>
            <input
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              id="login"
              class="fadeIn second"
              name="login"
              placeholder="Login"
            />
            <input
              onChange={(e) => setPassword(e.target.value)}
              type="text"
              id="password"
              class="fadeIn third"
              name="login"
              placeholder="Password"
            />
            <br />
            <br />
            <input
              onClick={() => addData()}
              type="submit"
              class="fadeIn fourth"
              value="Log In"
            />
            {/* <div id="formFooter">
                        <a class="underlineHover" onClick={() =>
                            props.history.push("/forgot")
                        }>Forgot Password?</a>
                    </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
