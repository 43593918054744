import Sidebar from "../Headerfooter/Sidebar";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from '../BaseUrl'

const Roles = (props) => {
    useEffect(() => {
        const isLoggedIn = localStorage.getItem("newtoken") ? true : false
        if (isLoggedIn===false) {
          props.history.push("/")
        }
      }, []);
    const [roles, setRoles] = useState([]);

    const handleClick = () => {
        props.history.push("/roleadd");
    };
    const handleDelete = (id) => {
        const config = {
            headers: {
                "Content-Type": "Application/json",
                "x-auth-token": `${localStorage.getItem("token")}`,
            },
        };
        axios.delete(baseUrl+`/api/role/${id}`).then((res) => {
            console.log(res);
            getRoles();
        });
    };
    useEffect(() => {
        getRoles();
    }, []);



    const getRoles = () => {
        const config = {
            headers: {
                "Content-Type": "Application/json",
                "x-auth-token": `${localStorage.getItem("token")}`,
            },
        };
        axios.get(baseUrl+"/api/role", config).then((res) => {
            setRoles(res.data.data.filter((i)=>i.roleTitle==="Admin"));
        });
    }


    return (
        <div>
            <div>
                <Sidebar props={props} />
            </div>
            <div className="access-container">
                <div className="access-section">
                    <div className="access-section-header" style={{width:"78%",marginLeft:"22%"}}>
                        <h3>All Roles</h3>
                        <button onClick={() => handleClick()}>+ Add Admin Role</button>
                    </div>
                    <table style={{width:"78%",marginLeft:"20%"}}>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Branch Code</th>
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {roles?.map((item, index) => {
                                return (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{item.roleTitle}</td>
                                        <td>{item.adminbranch}</td>
                                        <td>
                                            <div className="button-group">
                                                <button onClick={() => props.history.push(`/roleedit/${item._id}`)}>View</button>
                                                <button onClick={() => handleDelete(item?._id)}>Delete</button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Roles