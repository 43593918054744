import Sidebar from "../Headerfooter/Sidebar";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from '../BaseUrl'

const User = (props) => {
    useEffect(() => {
        const isLoggedIn = localStorage.getItem("newtoken") ? true : false
        if (isLoggedIn === false) {
            props.history.push("/")
        }
    }, []);
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);

    const handleClick = () => {
        props.history.push("/useradd1");
    };
    const handleDelete = (id) => {
        const config = {
            headers: {
                "Content-Type": "Application/json",
                "x-auth-token": `${localStorage.getItem("token")}`,
            },
        };
        axios.delete(baseUrl+`/api/user/${id}`).then((res) => {
            console.log(res);
            getRoles();
        });
    };
    useEffect(() => {
        getUsers();
    }, []);

    const branch = localStorage.getItem("userbranch")

    const getUsers = () => {
        const config = {
            headers: {
                "Content-Type": "Application/json",
                "x-auth-token": `${localStorage.getItem("token")}`,
            },
        };
        axios.get(baseUrl+"/api/user", config).then((res) => {
            setUsers(res.data.data.filter((i) => i.branch === branch));
        });
    }
    useEffect(() => {
        getRoles();
    }, []);



    const getRoles = () => {
        const config = {
            headers: {
                "Content-Type": "Application/json",
                "x-auth-token": `${localStorage.getItem("token")}`,
            },
        };
        axios.get(baseUrl+"/api/role", config).then((res) => {
            setRoles(res.data.data);
        });
    }

    const getBranch = (x) => {
        const userBranch = roles.filter((i) => i._id === x)[0]?.roleTitle
        return userBranch
    }


    return (
        <div>
            <div>
                <Sidebar props={props} />
            </div>
            <div className="access-container">
                <div className="access-section">
                    <div className="access-section-header" style={{ width: "78%", marginLeft: "22%" }}>
                        <h3>All User</h3>
                        {localStorage.getItem("useradd") === "yes" ? <button onClick={() => handleClick()}>+ Add User</button> : null}
                    </div>
                    <table style={{ width: "78%", marginLeft: "20%" }}>
                        <thead>
                            <tr>
                                <th>S.NO.</th>
                                <th>Name</th>
                                <th>Surname</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Role</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users?.map((item, index) => {
                                return (
                                    <tr key={item._id}>
                                        <td>{index + 1}</td>
                                        <td>{item.firstname}</td>
                                        <td>{item.lastname}</td>
                                        <td>{item.email}</td>
                                        <td>{item.phone}</td>
                                        <td>{getBranch(item.role)}</td>
                                        <td>
                                            <div className="button-group">
                                                <button onClick={() => props.history.push(`/useredit1/${item._id}`)}>View</button>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                {localStorage.getItem("userdelete") === "yes" ? <button onClick={() => handleDelete(item?._id)}>Delete</button> : null}
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default User