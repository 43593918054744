import React, { useState, useEffect } from "react";
import "./style.css";
import sal from "./sal.png";
import logo from "./logo.jpeg";
import Sidebar from "../Headerfooter/Sidebar";
import axios from "axios";

function StudentIcard(props) {
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("newtoken") ? true : false
    if (isLoggedIn===false) {
      props.history.push("/")
    }
  }, []);
  const [postItem, setPostItem] = useState({});
  const eId = localStorage.getItem("icardid");
  const getData = () => {
    axios
      .get(`https://ajiosys.in/api/studentdetails/${eId}`)
      .then((res) => setPostItem(res.data.data));
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <Sidebar props={props} />
        <div class="body-1">
          <div class="main-1">
            <div class="navhead-1">
              <div class="navhead1-1"></div>
              <div class="navhead2-1"></div>
            </div>
            <div class="main2-1">
              <div>
                <img class="img-1" src={logo} />
              </div>
              <div>
                <img class="img-1" src={sal} />
              </div>
            </div>
          </div>
          <div class="sub-1">
            <div class="sub1-1">
              <h3 class="">
                <br />S<br />T<br />U<br />D<br />E<br />N<br />T
              </h3>
            </div>
            <div class="sub2-1">
              <div class="enroll-1">Enroll No. &nbsp; : &nbsp; SA-11PSC01</div>
              <div class="box-1"></div>
              <div class="name-1">{postItem.StudentName}</div>
              <div class="upsc-1">{postItem.Course}</div>
              <div class="details-1">
                Batch&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Morning
                <br />
                Date of
                Adm.&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;01/05/2017{" "}
                <br />
                Valid
                upto&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;03/05/2020
                <br />
                Blood
                Group&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o+
              </div>
              <div class="upfooter-1">
                Palasia &nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Authorised
                Sign
              </div>
            </div>
          </div>
          <div className="sub3-1">
            <p style={{ color: "white" }} className="newp1">
              www.samadhan.com
            </p>
            <p style={{ color: "white" }} className="newp2">
              {" "}
              M.P. No.1 Academy
            </p>
          </div>
          <br></br>
          <button class="btn btn-success">print</button>
        </div>
        {/* <div class="body-2">
          <div class="main-2">
            <div class="navhead-2"></div>
            <div class="main2-2">
              <div>
                <img src={logo} />
              </div>
              <div>
                <h1>THE SAMADHAN ACADEMY</h1>
                <br />
                <h4>
                  IAS / IPS, MP-PSC, MP-SI, SSC, Banking & All Copetitive Exams
                </h4>
              </div>
            </div>
          </div>
          <div class="main3-2">
            <div class="sub1-12">
              <h3 class="">
                S<br />T<br />U<br />D<br />E<br />N<br />T
              </h3>
            </div>
            <div class="main3sub2-2">
              <div class="main3sub3-2">
                <div class="main3sub312-2">
                  <h3>Enroll No. : SA-1/CON/ Palasia</h3>
                  <h3 class="dotted">
                    ..................................................
                  </h3>
                  <h4 class="content1-2">Constable / SSC</h4>
                  <div class="content-2">
                    <h3 class="content1-2">
                      Batch &nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;..............................
                    </h3>
                    <h3 class="content1-2">
                      Date of Adm.
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;..............................
                    </h3>
                    <h3 class="content1-2">
                      Valid
                      Upto&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;..............................
                    </h3>
                  </div>
                </div>

                <div class="box-2">
                  <div class="main3sub31-2"></div>
                  <br />
                  <h3>Authorised sign</h3>
                </div>
              </div>
              <div class="main3sub4-2">
                <p>
                  www.samadhan.com <br />
                  M.P. No.1 Academy
                </p>
              </div>
            </div>
          </div>
          <br></br>
          <br></br>
          <button class="btn btn-success">print</button>
        </div> */}
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
}

export default StudentIcard;
