import React, { useState, useEffect } from "react";
import Sidebar from "../Headerfooter/Sidebar";
import axios from "axios";
import { baseUrl } from '../BaseUrl'

function Dashboard(props) {
  const [todoList, setTodoList] = useState([]);
  const [task, setTask] = useState("");
  const [updateTask, setUpdateTask] = useState({});
  const [showAddInput, setShowAddInput] = useState(false);
  const [showEditInput, setShowEditInput] = useState(false);

  useEffect(() => {
    getData();
  }, []);
  
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("newtoken") ? true : false
    if (isLoggedIn===false) {
      props.history.push("/")
    }
  }, []);
  const getData = () => {
    axios
      .get(baseUrl+"/api/todo")
      .then((res) => setTodoList(res.data.data));
  };

  const postData = () => {
    const item = {
      task: task,
    };
    axios
      .post(baseUrl+"/api/todo", item)
      .then(() => getData())
      .then(() => setShowAddInput(false));
  };

  const updateData = () => {
    axios
      .put(baseUrl+`/api/todo/${updateTask._id}`, updateTask)
      .then(() => getData())
      .then(() => setShowEditInput(false));
  };

  const deleteData = (x) => {
    axios.delete(baseUrl+`/api/todo/${x}`).then(() => getData());
  };

  const handleChange = (e) => {
    setUpdateTask({ ...updateTask, task: e.target.value });
  };

  const handleChange2 = (e, x) => {
    const item = { ...x, completed: e.target.checked };
    axios
      .put(baseUrl+`/api/todo/${x._id}`, item)
      .then(() => getData());
  };

  const editItem = (x) => {
    setUpdateTask(x);
    setShowEditInput(true);
  };
  return (
    <div>
      <Sidebar props={props} />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark"></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right"></ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <section className="content">
              <div className="container-fluid">
                <h3>Students</h3>
                <div className="row">
                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-info">
                      <a className="nav-link">
                        <div className="inner">
                          <h5>Enquiry</h5>
                        </div>
                      </a>

                      <span className="small-box-footer">
                        <span onClick={() => props.history.push("enquiry")}>
                          Add <i className="fa fa-plus" />
                        </span>
                        &nbsp;
                        <span onClick={() => props.history.push("enquirylist")}>
                          {" "}
                          View <i className="fa fa-arrow-circle-right" />
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-success">
                      <a className="nav-link">
                        <div className="inner">
                          <h5>Demo</h5>
                        </div>
                      </a>
                      <span className="small-box-footer">
                        <span onClick={() => props.history.push("demo")}>
                          Add <i className="fa fa-plus" />
                        </span>
                        &nbsp;
                        <span onClick={() => props.history.push("demolist")}>
                          {" "}
                          View <i className="fa fa-arrow-circle-right" />
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-warning">
                      <a className="nav-link">
                        <div className="inner">
                          <h5>Registration</h5>
                        </div>
                      </a>
                      <span className="small-box-footer">
                        <span
                          onClick={() => props.history.push("registration")}
                        >
                          Add <i className="fa fa-plus" />
                        </span>
                        &nbsp;
                        <span
                          onClick={() => props.history.push("registrationlist")}
                        >
                          {" "}
                          View <i className="fa fa-arrow-circle-right" />
                        </span>
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-danger">
                      <a className="nav-link">
                        <div className="inner">
                          <h5>I-card</h5>
                        </div>
                      </a>
                      <span className="small-box-footer">
                        <span onClick={() => props.history.push("icard")}>
                          Add <i className="fa fa-plus" />
                        </span>
                        &nbsp;
                        <span onClick={() => props.history.push("icard")}>
                          {" "}
                          View <i className="fa fa-arrow-circle-right" />
                        </span>
                      </span>
                    </div>
                  </div>

                  {/* <div className="col-lg-3 col-6">
                    <div className="small-box bg-info">
                      <a className="nav-link">
                        <div className="inner">
                          <h5>Attendence</h5>
                        </div>
                      </a>
                      <span className="small-box-footer">
                        <span onClick={() => props.history.push("enquiry")}>
                          Add <i className="fa fa-plus" />
                        </span>
                        &nbsp;
                        <span onClick={() => props.history.push("enquirylist")}>
                          {" "}
                          View <i className="fa fa-arrow-circle-right" />
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-success">
                      <a className="nav-link">
                        <div className="inner">
                          <h5>Notes</h5>
                        </div>
                      </a>
                      <span className="small-box-footer">
                        <span onClick={() => props.history.push("enquiry")}>
                          Add <i className="fa fa-plus" />
                        </span>
                        &nbsp;
                        <span onClick={() => props.history.push("enquirylist")}>
                          {" "}
                          View <i className="fa fa-arrow-circle-right" />
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-warning">
                      <a className="nav-link">
                        <div className="inner">
                          <h5>Exam</h5>
                        </div>
                      </a>
                      <span className="small-box-footer">
                        <span onClick={() => props.history.push("enquiry")}>
                          Add <i className="fa fa-plus" />
                        </span>
                        &nbsp;
                        <span onClick={() => props.history.push("enquirylist")}>
                          {" "}
                          View <i className="fa fa-arrow-circle-right" />
                        </span>
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-danger">
                      <a className="nav-link">
                        <div className="inner">
                          <h5>Result</h5>
                        </div>
                      </a>
                      <span className="small-box-footer">
                        <span onClick={() => props.history.push("enquiry")}>
                          Add <i className="fa fa-plus" />
                        </span>
                        &nbsp;
                        <span onClick={() => props.history.push("enquirylist")}>
                          {" "}
                          View <i className="fa fa-arrow-circle-right" />
                        </span>
                      </span>
                    </div>
                  </div> */}
                </div>
                <h3>Teachers</h3>
                <div className="row">
                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-info">
                      <a className="nav-link">
                        <div className="inner">
                          <h5>Application</h5>
                        </div>
                      </a>
                      <span className="small-box-footer">
                        <span onClick={() => props.history.push("application")}>
                          Add <i className="fa fa-plus" />
                        </span>
                        &nbsp;
                        <span
                          onClick={() => props.history.push("applicationlist")}
                        >
                          {" "}
                          View <i className="fa fa-arrow-circle-right" />
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-success">
                      <a className="nav-link">
                        <div className="inner">
                          <h5>Demo</h5>
                        </div>
                      </a>
                      <span className="small-box-footer">
                        <span onClick={() => props.history.push("staffdemo")}>
                          Add <i className="fa fa-plus" />
                        </span>
                        &nbsp;
                        <span
                          onClick={() => props.history.push("staffdemolist")}
                        >
                          {" "}
                          View <i className="fa fa-arrow-circle-right" />
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-warning">
                      <a className="nav-link">
                        <div className="inner">
                          <h5>Registration</h5>
                        </div>
                      </a>
                      <span className="small-box-footer">
                        <span
                          onClick={() =>
                            props.history.push("staffregistration")
                          }
                        >
                          Add <i className="fa fa-plus" />
                        </span>
                        &nbsp;
                        <span
                          onClick={() =>
                            props.history.push("staffregistrationlist")
                          }
                        >
                          {" "}
                          View <i className="fa fa-arrow-circle-right" />
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-danger">
                      <a className="nav-link">
                        <div className="inner">
                          <h5>Salary</h5>
                        </div>
                      </a>
                      <span className="small-box-footer">
                        <span onClick={() => props.history.push("enquiry")}>
                          Add <i className="fa fa-plus" />
                        </span>
                        &nbsp;
                        <span onClick={() => props.history.push("enquirylist")}>
                          {" "}
                          View <i className="fa fa-arrow-circle-right" />
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <h3>Accounts</h3>
                <div className="row">
                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-info">
                      <a className="nav-link">
                        <div className="inner">
                          <h5>Fees Collection</h5>
                        </div>
                      </a>
                      <span className="small-box-footer">
                        <span onClick={() => props.history.push("enquiry")}>
                          Add <i className="fa fa-plus" />
                        </span>
                        &nbsp;
                        <span onClick={() => props.history.push("enquirylist")}>
                          {" "}
                          View <i className="fa fa-arrow-circle-right" />
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-success">
                      <a className="nav-link">
                        <div className="inner">
                          <h5>Salary Distribution</h5>
                        </div>
                      </a>
                      <span className="small-box-footer">
                        <span onClick={() => props.history.push("enquiry")}>
                          Add <i className="fa fa-plus" />
                        </span>
                        &nbsp;
                        <span onClick={() => props.history.push("enquirylist")}>
                          {" "}
                          View <i className="fa fa-arrow-circle-right" />
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-warning">
                      <a className="nav-link">
                        <div className="inner">
                          <h5>Daily Expenses</h5>
                        </div>
                      </a>
                      <span className="small-box-footer">
                        <span onClick={() => props.history.push("enquiry")}>
                          Add <i className="fa fa-plus" />
                        </span>
                        &nbsp;
                        <span onClick={() => props.history.push("enquirylist")}>
                          {" "}
                          View <i className="fa fa-arrow-circle-right" />
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-danger">
                      <a className="nav-link">
                        <div className="inner">
                          <h5>Handover Amount</h5>
                        </div>
                      </a>
                      <span className="small-box-footer">
                        <span onClick={() => props.history.push("enquiry")}>
                          Add <i className="fa fa-plus" />
                        </span>
                        &nbsp;
                        <span onClick={() => props.history.push("enquirylist")}>
                          {" "}
                          View <i className="fa fa-arrow-circle-right" />
                        </span>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <section className="col-lg-7 connectedSortable">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">
                          <i className="ion ion-clipboard mr-1" />
                          To Do List
                        </h3>
                      </div>

                      <div className="card-body">
                        <ul className="todo-list" data-widget="todo-list">
                          {todoList.map((item) => (
                            <li>
                              <span className="handle">
                                <i className="fa fa-ellipsis-v" />
                                <i className="fa fa-ellipsis-v" />
                              </span>

                              <div className="icheck-primary d-inline ml-2">
                                <input
                                  type="checkbox"
                                  checked={item.completed}
                                  onChange={(e) => handleChange2(e, item)}
                                />
                              </div>

                              <span className="text">{item.task}</span>

                              <small className="badge badge-danger">
                                <i className="far fa-clock" />2 mins
                              </small>

                              <div className="tools">
                                <i
                                  className="fa fa-edit"
                                  onClick={() => editItem(item)}
                                />
                                <i
                                  className="fa fa-trash-o"
                                  onClick={() => deleteData(item._id)}
                                />
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>

                      <div className="card-footer clearfix">
                        {showAddInput ? (
                          <input onChange={(e) => setTask(e.target.value)} />
                        ) : null}
                        {showAddInput ? (
                          <button
                            type="button"
                            className="btn btn-info float-right"
                            onClick={() => postData()}
                          >
                            save item
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-info float-right"
                            onClick={() => setShowAddInput(true)}
                          >
                            <i className="fa fa-plus" />
                            Add item
                          </button>
                        )}
                      </div>
                      {showEditInput ? (
                        <div className="card-footer clearfix">
                          <input
                            onChange={handleChange}
                            value={updateTask.task}
                          />
                          <button
                            type="button"
                            className="btn btn-info float-right"
                            onClick={() => updateData()}
                          >
                            <i className="fa fa-edit" />
                            Update item
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </section>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>

      <aside className="control-sidebar control-sidebar-dark"></aside>
    </div>
  );
}

export default Dashboard;
