import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import Sidebar from "../Headerfooter/Sidebar";
import axios from "axios";
// EnquiryNumber:
// Course:
// StudentName:
// Medium:
// DateofBirth:
// EmailID:
// FathersName:
// MotherName:
// MobileNo:
// Gender:
// Category:
// Address:
// City:
// State:
// Qualification:
// Occupation:
// Reference:
// CourseFees:
// Discount:
// AlternateNo:
// NetAmount:
// ContactNo:
// Remark:
// DemoNumber:
// DemoDate:
// Batch:
// BatchTime:
// EnrollmentNumber:
// EnrollmentDate:
// FatherOccupation:
// FatherContact:
// PIN:
// ActiveStatus:
// Password:
// Photo:
// IDProof:
// AddressProof:

const DatatablePage = (props) => {
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("newtoken") ? true : false
    if (isLoggedIn===false) {
      props.history.push("/")
    }
  }, []);
  const [list, setList] = useState([]);

  const getData = () => {
    axios
      .get("https://ajiosys.in/api/studentdetails")
      .then((res) => setList(res.data.data));
  };
  useEffect(() => {
    getData();
  }, []);
  const delData = (x) => {
    axios
      .delete(`https://ajiosys.in/api/studentdetails/${x}`)
      .then(() => getData());
  };
  const rowData = list
    .filter((i) => i.Demo === true)
    .map((item) => {
      return {
        StudentName: item.StudentName,
        MobileNo: item.MobileNo,
        Course: item.Course,
        Medium: item.Medium,
        DemoDate: item.DemoDate,
        Register: (
          <button
            className={item.Register ? "btn btn-success" : "btn btn-warning"}
            onClick={() => {
              localStorage.setItem("registrationid", item._id);
              props.history.push("registrationedit");
            }}
          >
            <i class="fa fa-external-link" aria-hidden="true"></i>
          </button>
        ),
        View: (
          <button
            className="btn btn-info"
            onClick={() => {
              localStorage.setItem("demoid", item._id);
              props.history.push("demoedit");
            }}
          >
            <i className="fa fa-eye" />
          </button>
        ),
        Delete: (
          localStorage.getItem("studentDemodelete")==="yes"? <button className="btn btn-danger" onClick={() => delData(item._id)}>
            <i className="fa fa-trash" />
          </button>:null
        ),
      };
    });
  const data = {
    columns: [
      {
        label: "Name",
        field: "StudentName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Number",
        field: "MobileNo",
        sort: "asc",
        width: 270,
      },
      {
        label: "Course",
        field: "Course",
        sort: "asc",
        width: 200,
      },
      {
        label: "Medium",
        field: "Medium",
        sort: "asc",
        width: 100,
      },
      {
        label: "Demo Date",
        field: "DemoDate",
        sort: "asc",
        width: 150,
      },

      {
        label: "Register",
        field: "Register",
        sort: "",
        width: 50,
      },
      {
        label: "View",
        field: "View",
        sort: "",
        width: 50,
      },
      {
        label: "Delete",
        field: "Delete",
        sort: "",
        width: 50,
      },
    ],
    rows: rowData,
  };
  return (
    <>
      <Sidebar props={props} />
      <div
        style={{
          marginLeft: window.innerWidth > 700 ? "20%" : null,
          width: window.innerWidth > 700 ? "78%" : "",
        }}
      >
        <MDBDataTable striped bordered small data={data} />
      </div>
    </>
  );
};

export default DatatablePage;
