import React,{useEffect} from 'react'
import Sidebar from "../Headerfooter/Sidebar";

function Profile(props) {
    useEffect(() => {
        const isLoggedIn = localStorage.getItem("newtoken") ? true : false
        if (isLoggedIn===false) {
          props.history.push("/")
        }
      }, []);
    return (
        <div>
            <Sidebar props={props} />
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">

                            </div>
                            {/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right" />
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* Info boxes */}
                        <form method="post" action="https://samadhanacademy.com/samadhanadmin/Enquiry" id="ctl01">
                            <div className="aspNetHidden">
                                <input type="hidden" name="_EVENTTARGET" id="_EVENTTARGET" defaultValue />
                                <input type="hidden" name="_EVENTARGUMENT" id="_EVENTARGUMENT" defaultValue />
                                <input type="hidden" name="_LASTFOCUS" id="_LASTFOCUS" defaultValue />
                                <input type="hidden" name="_VIEWSTATE" id="_VIEWSTATE" defaultValue="c0t9YQZL6Toi/IIxKlYJsW/kM+5endAZgJg9sSB9fenThbFjP7u5I2GZqrRa2MyfhG5a9WjpCfyxbk+gDulARzkCN2nqJ4ENfmkvYTIMvlYzonZF8AgISTIqdoMUqtY1PDv6a1tgXJvAGgWC8BPK75BS9IWbbTTia1zD9b9LQz0=" />
                            </div>
                            <div className="aspNetHidden">
                                <input type="hidden" name="_VIEWSTATEGENERATOR" id="_VIEWSTATEGENERATOR" defaultValue="023F5689" />
                                <input type="hidden" name="_EVENTVALIDATION" id="_EVENTVALIDATION" defaultValue="8fujjIdaR0/+xKhE629IE6RA1kJzVI1iMOR2aZT5q0IRXBF2j2WgWbL7/0E0IUEO8RV/x7s+FmDTD8GOdEi4F0EcZnLfIUsIxPIietdiHf4mOG1sslVfqOoj2pQ8guLWX//ELBSTUnx+VGN5RyaGNr5eEWvdRYMM76KS6kaz6lbasY4pN5cesTsGthPSzcqaw9gQ1fmcVxUVQOMR2jxnFJcDLjqpsTheKcdFxAKLDVEQSFNwYmQIOu8FaOlxOtVtWmpCtLZFu9Jla16NsSvH9cyCglxhkquf/jf/nSQ5mOQhpriPe3HypwW5bTktwdyule2Teoz8fvJv9bakgOFV6DUCk/aOVavXkjluIEzb6XfZNYu9rZXeB5UT6sh8nheI+1qrPDdbOFAPJsITRpvbOv9Jq2xvlRu91edXuKLn8HkRRGGY4mwfEdaUlAtEkSKOY0PV/iUi61kBqoHY6YzheYAjNWXYWB2NQSKUi2TEHKWuDMK/T/tiZR7ueuhGJA9NJ53c0tE2/QN2nDCNRmWZtujrKjCmjD0xzYBfK6e1iwWdjVYV/RfoqRoF0ewh03aZdAITFykPnJzvn+pURRLvZ8/Ghk2jEXh3pRj9NCdpT/XeYNTMH55XgVIsiUwPvIqWE/2iDHeWGT7rOa80VDESlnaOLSzCY99F6KVjed7wkhzb9DJG3Gcf+lqICx7aTj6klY13bWRUyeHExI7eLW+RSrLR0szbXb7bP0TGMsTceOGI8M6bGd2t6Q92TJQTOZmDXHDQLzdqD5neocAFpF+Y3p38d1mk4mnilc/bBbfCUX03FNJ5JmDGZggy6AIFKLVD3dMpzoQd1ss85i9cm36pkUH+Hd8heYQkY3T5ZLUDaHjO36YHhIkHLaeeFwn+Z/MqsD6M4mDQMUpCnvp+HfnzRAB3MDIRMxdn7XfJCU2U0e60EEmfQPgzMLL5GNifVvSMyRV2xLhqP3ahxWbRgFvItlFyQW4rEYUVKbi2jr9CLZ+MtUGXCgofApq+jlSgr6MV" />
                            </div>
                            <section className="content">
                                <div className="container-fluid">
                                    <div className="row">
                                        {/* /.col */}
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-header p-2">
                                                    <h3 style={{ textAlign: "center" }}>PROFILE</h3>
                                                </div>
                                                {/* /.card-header */}
                                                <div className="card-body">
                                                    <div class="container">
                                                        <div className="row" style={{ width: "100%" }}>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <div className="form-group">
                                                                        <button type="button" class="btn btn-primary">STAFF</button>
                                                                    </div>
                                                                </div>
                                                                {/* /.form-group */}
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <button type="button" class="btn btn-primary">STUDENT</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* /.card-body */}</div>
                                            {/* /.nav-tabs-custom */}
                                        </div>
                                        {/* /.col */}
                                    </div>
                                    {/* /.row */}
                                </div>
                                {/* /.container-fluid */}
                            </section>
                            <section className="content">
                                <div className="container-fluid">
                                    <div className="row">
                                        {/* /.col */}
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-header p-2">
                                                    <h3 style={{ textAlign: "center" }}>DOCUMENT</h3>
                                                </div>
                                                {/* /.card-header */}
                                                <div className="card-body">
                                                    <div class="container">
                                                        <div className="row" style={{ width: "100%" }}>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label>Student Name:</label>
                                                                    <input name="ctl00$First$txtEnqNo" type="text" id="First_txtEnqNo" className="form-control" placeholder="Enter Name" />
                                                                </div>
                                                                {/* /.form-group */}
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label>Enroll No:</label>
                                                                    <input name="ctl00$First$txtEnqNo" type="text" id="First_txtEnqNo" className="form-control" placeholder="Enter Number" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label>Date:</label>
                                                                    <input name="ctl00$First$txtEnqNo" type="text" id="First_txtEnqNo" className="form-control" placeholder="Enter Date" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* /.card-body */}</div>
                                            {/* /.nav-tabs-custom */}
                                        </div>
                                        {/* /.col */}
                                    </div>
                                    {/* /.row */}
                                </div>
                                {/* /.container-fluid */}
                            </section>
                            <section className="content">
                                <div className="container-fluid">
                                    <div className="row">
                                        {/* /.col */}
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-header p-2">
                                                    <h3 style={{ textAlign: "center" }}>DOCUMENT</h3>
                                                </div>
                                                {/* /.card-header */}
                                                <div className="card-body">
                                                    <div class="container">
                                                        <div className="row" style={{ width: "100%" }}>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label>Student Name:</label>
                                                                    <input name="ctl00$First$txtEnqNo" type="text" id="First_txtEnqNo" className="form-control" placeholder="Enter Name" />
                                                                </div>
                                                                {/* /.form-group */}
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label>Enroll No:</label>
                                                                    <input name="ctl00$First$txtEnqNo" type="text" id="First_txtEnqNo" className="form-control" placeholder="Enter Number" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label>Date:</label>
                                                                    <input name="ctl00$First$txtEnqNo" type="text" id="First_txtEnqNo" className="form-control" placeholder="Enter Date" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* /.card-body */}</div>
                                            {/* /.nav-tabs-custom */}
                                        </div>
                                        {/* /.col */}
                                    </div>
                                    {/* /.row */}
                                </div>
                                {/* /.container-fluid */}
                            </section>
                            <section className="content">
                                <div className="container-fluid">
                                    <div className="row">
                                        {/* /.col */}
                                       
                                        {/* /.col */}
                                    </div>
                                    {/* /.row */}
                                </div>
                                {/* /.container-fluid */}
                            </section>
                        </form>
                        {/* /.row */}
                        {/* /.row */}
                    </div>
                    {/*/. container-fluid */}
                </section>
                {/* /.content */}
            </div>
        </div>
    )
}

export default Profile