import React,{useEffect} from "react";
import Sidebar from "../Headerfooter/Sidebar";

function InventoryAdd(props) {
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("newtoken") ? true : false
    if (isLoggedIn===false) {
      props.history.push("/")
    }
  }, []);
  return (
    <div>
      <Sidebar props={props} />
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">INVENTORY</h1>
              </div>
              {/* /.col */}
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right" />
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>
        {/* /.content-header */}
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            {/* Info boxes */}
            <form
              method="post"
              action="https://samadhanacademy.com/samadhanadmin/Enquiry"
              id="ctl01"
            >
              <div className="aspNetHidden">
                <input
                  type="hidden"
                  name="__EVENTTARGET"
                  id="__EVENTTARGET"
                  defaultValue
                />
                <input
                  type="hidden"
                  name="__EVENTARGUMENT"
                  id="__EVENTARGUMENT"
                  defaultValue
                />
                <input
                  type="hidden"
                  name="__LASTFOCUS"
                  id="__LASTFOCUS"
                  defaultValue
                />
                <input
                  type="hidden"
                  name="__VIEWSTATE"
                  id="__VIEWSTATE"
                  defaultValue="c0t9YQZL6Toi/IIxKlYJsW/kM+5endAZgJg9sSB9fenThbFjP7u5I2GZqrRa2MyfhG5a9WjpCfyxbk+gDulARzkCN2nqJ4ENfmkvYTIMvlYzonZF8AgISTIqdoMUqtY1PDv6a1tgXJvAGgWC8BPK75BS9IWbbTTia1zD9b9LQz0="
                />
              </div>
              <div className="aspNetHidden">
                <input
                  type="hidden"
                  name="__VIEWSTATEGENERATOR"
                  id="__VIEWSTATEGENERATOR"
                  defaultValue="023F5689"
                />
                <input
                  type="hidden"
                  name="__EVENTVALIDATION"
                  id="__EVENTVALIDATION"
                  defaultValue="8fujjIdaR0/+xKhE629IE6RA1kJzVI1iMOR2aZT5q0IRXBF2j2WgWbL7/0E0IUEO8RV/x7s+FmDTD8GOdEi4F0EcZnLfIUsIxPIietdiHf4mOG1sslVfqOoj2pQ8guLWX//ELBSTUnx+VGN5RyaGNr5eEWvdRYMM76KS6kaz6lbasY4pN5cesTsGthPSzcqaw9gQ1fmcVxUVQOMR2jxnFJcDLjqpsTheKcdFxAKLDVEQSFNwYmQIOu8FaOlxOtVtWmpCtLZFu9Jla16NsSvH9cyCglxhkquf/jf/nSQ5mOQhpriPe3HypwW5bTktwdyule2Teoz8fvJv9bakgOFV6DUCk/aOVavXkjluIEzb6XfZNYu9rZXeB5UT6sh8nheI+1qrPDdbOFAPJsITRpvbOv9Jq2xvlRu91edXuKLn8HkRRGGY4mwfEdaUlAtEkSKOY0PV/iUi61kBqoHY6YzheYAjNWXYWB2NQSKUi2TEHKWuDMK/T/tiZR7ueuhGJA9NJ53c0tE2/QN2nDCNRmWZtujrKjCmjD0xzYBfK6e1iwWdjVYV/RfoqRoF0ewh03aZdAITFykPnJzvn+pURRLvZ8/Ghk2jEXh3pRj9NCdpT/XeYNTMH55XgVIsiUwPvIqWE/2iDHeWGT7rOa80VDESlnaOLSzCY99F6KVjed7wkhzb9DJG3Gcf+lqICx7aTj6klY13bWRUyeHExI7eLW+RSrLR0szbXb7bP0TGMsTceOGI8M6bGd2t6Q92TJQTOZmDXHDQLzdqD5neocAFpF+Y3p38d1mk4mnilc/bBbfCUX03FNJ5JmDGZggy6AIFKLVD3dMpzoQd1ss85i9cm36pkUH+Hd8heYQkY3T5ZLUDaHjO36YHhIkHLaeeFwn+Z/MqsD6M4mDQMUpCnvp+HfnzRAB3MDIRMxdn7XfJCU2U0e60EEmfQPgzMLL5GNifVvSMyRV2xLhqP3ahxWbRgFvItlFyQW4rEYUVKbi2jr9CLZ+MtUGXCgofApq+jlSgr6MV"
                />
              </div>
              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    {/* /.col */}
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-header p-2">
                          <ul className="nav nav-pills">
                            <li className="nav-item">
                              <a
                                className="nav-link active"
                                href="#activity"
                                data-toggle="tab"
                              >
                                ADD
                              </a>
                            </li>
                          </ul>
                        </div>
                        {/* /.card-header */}
                        <div className="card-body">
                          <div className="tab-content">
                            <div className="active tab-pane" id="activity">
                              {/* Post */}
                              <section className="content">
                                <div className="container-fluid">
                                  {/* SELECT2 EXAMPLE */}
                                  <div className="card card-default">
                                    <div className="card-header">
                                      <h3 className="card-title">
                                        Please enter new inventory details:
                                      </h3>
                                      <span
                                        id="First_lblMsg"
                                        style={{
                                          color: "Red",
                                          fontWeight: "bold",
                                        }}
                                      />
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label>ASSET No.:</label>
                                            <input
                                              name="ctl00$First$txtEnqNo"
                                              type="text"
                                              id="First_txtEnqNo"
                                              className="form-control"
                                              placeholder="Enter Number"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label>ASSET TYPE:</label>
                                            <input
                                              name="ctl00$First$txtEnqNo"
                                              type="text"
                                              id="First_txtEnqNo"
                                              className="form-control"
                                              placeholder="Enter Number"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label>ASSET NAME:</label>
                                            <input
                                              name="ctl00$First$txtName"
                                              type="text"
                                              id="First_txtName"
                                              className="form-control"
                                              placeholder="Asset Name"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <div className="form-group">
                                              <label>MODEL:</label>
                                              <input
                                                name="ctl00$First$txtName"
                                                type="text"
                                                id="First_txtName"
                                                className="form-control"
                                                placeholder="Model Name"
                                              />
                                            </div>
                                          </div>
                                          {/* /.form-group */}
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label>QUANTITY:</label>
                                            <input
                                              name="ctl00$First$txtName"
                                              type="text"
                                              id="First_txtName"
                                              className="form-control"
                                              placeholder="Quantity"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label>ROOM/ALLMILA:</label>
                                            <input
                                              name="ctl00$First$txtName"
                                              type="text"
                                              id="First_txtName"
                                              className="form-control"
                                              placeholder="Room/Allmila"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          {/* /.form-group */}
                                          <div className="form-group">
                                            <label>VALUE:</label>
                                            <input
                                              name="ctl00$First$txtFName"
                                              type="text"
                                              id="First_txtFName"
                                              className="form-control"
                                              placeholder="Enter Value"
                                            />
                                          </div>
                                        </div>
                                        {/* /.col */}
                                        <div className="col-md-6">
                                          {/* /.form-group */}
                                          <div class="mb-3">
                                            <label
                                              for="formFile"
                                              class="form-label"
                                            >
                                              Default file input example
                                            </label>
                                            <input
                                              class="form-control"
                                              type="file"
                                              id="formFile"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-6">
                                          {/* /.form-group */}
                                          <div className="form-group">
                                            <div className="card-footer">
                                              <input
                                                type="submit"
                                                name="ctl00$First$btnReg"
                                                defaultValue="Submit"
                                                id="First_btnReg"
                                                className="btn btn-primary"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {/* /.row */}
                                      {/* /.row */}
                                    </div>
                                    {/* /.card-body */}
                                  </div>
                                  {/* /.card */}
                                  {/* SELECT2 EXAMPLE */}
                                  {/* /.card */}
                                  {/* /.row */}
                                </div>
                                {/* /.container-fluid */}
                              </section>
                              {/* /.post */}
                            </div>
                            {/* /.tab-pane */}

                            {/* /.tab-pane */}
                          </div>
                          {/* /.tab-content */}
                        </div>
                        {/* /.card-body */}
                        <div className="card-body">
                          <div className="tab-content">
                            <div className="active tab-pane" id="activity">
                              {/* Post */}
                              <section className="content">
                                <div className="container-fluid">
                                  {/* SELECT2 EXAMPLE */}
                                  <div className="card card-default">
                                    <div className="card-header">
                                      <h3 className="card-title">
                                        INVENTORY USE:
                                      </h3>
                                      <span
                                        id="First_lblMsg"
                                        style={{
                                          color: "Red",
                                          fontWeight: "bold",
                                        }}
                                      />
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label>ASSET No.:</label>
                                            <input
                                              name="ctl00$First$txtEnqNo"
                                              type="text"
                                              id="First_txtEnqNo"
                                              className="form-control"
                                              placeholder="Enter Number"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label>ASSET TYPE:</label>
                                            <input
                                              name="ctl00$First$txtEnqNo"
                                              type="text"
                                              id="First_txtEnqNo"
                                              className="form-control"
                                              placeholder="Enter Number"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label>ASSET NAME:</label>
                                            <input
                                              name="ctl00$First$txtName"
                                              type="text"
                                              id="First_txtName"
                                              className="form-control"
                                              placeholder="Asset Name"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <div className="form-group">
                                              <label>MODEL:</label>
                                              <input
                                                name="ctl00$First$txtName"
                                                type="text"
                                                id="First_txtName"
                                                className="form-control"
                                                placeholder="Model Name"
                                              />
                                            </div>
                                          </div>
                                          {/* /.form-group */}
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label>QUANTITY:</label>
                                            <input
                                              name="ctl00$First$txtName"
                                              type="text"
                                              id="First_txtName"
                                              className="form-control"
                                              placeholder="Quantity"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label>ROOM/ALLMILA:</label>
                                            <input
                                              name="ctl00$First$txtName"
                                              type="text"
                                              id="First_txtName"
                                              className="form-control"
                                              placeholder="Room/Allmila"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          {/* /.form-group */}
                                          <div className="form-group">
                                            <label>VALUE:</label>
                                            <input
                                              name="ctl00$First$txtFName"
                                              type="text"
                                              id="First_txtFName"
                                              className="form-control"
                                              placeholder="Enter Value"
                                            />
                                          </div>
                                        </div>
                                        {/* /.col */}
                                        <div className="col-md-6">
                                          {/* /.form-group */}
                                        </div>

                                        <div className="col-md-6">
                                          {/* /.form-group */}
                                          <div className="form-group">
                                            <div className="card-footer">
                                              <a
                                                class="btn btn-primary"
                                                href="#"
                                                role="button"
                                              >
                                                Save
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {/* /.row */}
                                      {/* /.row */}
                                    </div>
                                    {/* /.card-body */}
                                  </div>
                                  {/* /.card */}
                                  {/* SELECT2 EXAMPLE */}
                                  {/* /.card */}
                                  {/* /.row */}
                                </div>
                                {/* /.container-fluid */}
                              </section>
                              {/* /.post */}
                            </div>
                            {/* /.tab-pane */}

                            {/* /.tab-pane */}
                          </div>
                          {/* /.tab-content */}
                        </div>
                        {/* /.card-body */}
                        <div className="card-body">
                          <div className="tab-content">
                            <div className="active tab-pane" id="activity">
                              {/* Post */}
                              <section className="content">
                                <div className="container-fluid">
                                  {/* SELECT2 EXAMPLE */}
                                  <div className="card card-default">
                                    <div className="card-header">
                                      <h3 className="card-title">
                                        INVENTORY USE:
                                      </h3>
                                      <span
                                        id="First_lblMsg"
                                        style={{
                                          color: "Red",
                                          fontWeight: "bold",
                                        }}
                                      />
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label>ASSET No.:</label>
                                            <input
                                              name="ctl00$First$txtEnqNo"
                                              type="text"
                                              id="First_txtEnqNo"
                                              className="form-control"
                                              placeholder="Enter Number"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label>ASSET NAME:</label>
                                            <input
                                              name="ctl00$First$txtName"
                                              type="text"
                                              id="First_txtName"
                                              className="form-control"
                                              placeholder="Asset Name"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-6">
                                          {/* /.form-group */}
                                          <div className="form-group">
                                            <div className="card-footer">
                                              <a
                                                class="btn btn-primary"
                                                href="#"
                                                role="button"
                                              >
                                                Save
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                        {/* <div className="col-md-12">
                                          <table className="table">
                                            <thead>
                                              <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">First</th>
                                                <th scope="col">Last</th>
                                                <th scope="col">Handle</th>
                                                <th scope="col">#</th>
                                                <th scope="col">First</th>
                                                <th scope="col">Last</th>
                                                <th scope="col">Handle</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <th scope="row">1</th>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                                <td>Mark</td>
                                                <td>Otto</td>
                                                <td>@mdo</td>
                                                <td>Mark</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div> */}
                                      </div>
                                      {/* /.row */}
                                      {/* /.row */}
                                    </div>
                                    {/* /.card-body */}
                                  </div>
                                  {/* /.card */}
                                  {/* SELECT2 EXAMPLE */}
                                  {/* /.card */}
                                  {/* /.row */}
                                </div>
                                {/* /.container-fluid */}
                              </section>
                              {/* /.post */}
                            </div>
                            {/* /.tab-pane */}

                            {/* /.tab-pane */}
                          </div>
                          {/* /.tab-content */}
                        </div>
                      </div>
                      {/* /.nav-tabs-custom */}
                    </div>
                    {/* /.col */}
                  </div>
                  {/* /.row */}
                </div>
                {/* /.container-fluid */}
              </section>
            </form>
            {/* /.row */}
            {/* /.row */}
          </div>
          {/*/. container-fluid */}
        </section>
        {/* /.content */}
      </div>
    </div>
  );
}

export default InventoryAdd;
