import React, { useState, useEffect } from "react";
import Sidebar from "../Headerfooter/Sidebar";
import axios from "axios";

function Demo(props) {
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("newtoken") ? true : false
    if (isLoggedIn===false) {
      props.history.push("/")
    }
  }, []);
  const [list, setList] = useState(1);
  const eno = `SAD-${list}`;
  const getData2 = () => {
    axios
      .get("https://ajiosys.in/api/studentdetails")
      .then((res) =>
        res.data.data.filter((i) => i.Demo === true)
          ? setList(res.data.data.filter((i) => i.Demo === true).length + 1)
          : null
      );
  };

  useEffect(() => {
    getData2();
  }, []);
  useEffect(() => {
    getCourses();
  }, []);
  const getCourses = () => {
    axios
      .get("https://ajiosys.in/api/courses")
      .then((res) => setCourses(res.data.data));
  };

  const [courses, setCourses] = useState([]);
  const [postItem, setPostItem] = useState({});
  const handleChange = (e) => {
    setPostItem({ ...postItem, [e.target.name]: e.target.value });
  };
  const eId = localStorage.getItem("demoid");
  const postData = () => {
    const newNo = postItem.Demo === false ? eno : postItem.DemoNumber;
    const item = { ...postItem, Demo: true, DemoNumber: newNo };
    axios
      .put(`https://ajiosys.in/api/studentdetails/${eId}`, item)
      .then(() => props.history.push("demolist"));
  };

  const getData = () => {
    axios
      .get(`https://ajiosys.in/api/studentdetails/${eId}`)
      .then((res) => setPostItem(res.data.data));
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      {" "}
      <Sidebar props={props} />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <h1 className="m-0 text-dark">Student Demo</h1>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="card-header">
                <h3 className="card-title">
                  Please enter new demo class details:
                </h3>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Demo Number</label>
                      <input
                        value={
                          postItem.Demo === false ? eno : postItem.DemoNumber
                        }
                        readOnly="readOnly"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Demo Date</label>
                      <input
                        name="DemoDate"
                        value={postItem.DemoDate}
                        onChange={handleChange}
                        type="date"
                        className="form-control"
                        placeholder="Demo date"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Student Name</label>
                      <input
                        name="StudentName"
                        value={postItem.StudentName}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Student Name"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    {/* /.form-group */}
                    <div className="form-group">
                      <label>Email ID</label>
                      <input
                        name="EmailID"
                        value={postItem.EmailID}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Email ID"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    {/* /.form-group */}
                    <div className="form-group">
                      <label>Mobile No.</label>
                      <input
                        name="MobileNo"
                        value={postItem.MobileNo}
                        onChange={handleChange}
                        type="number"
                        className="form-control"
                        placeholder="Mobile"
                      />
                    </div>
                    {/* /.form-group */}
                  </div>
                  <div className="col-md-6">
                    {/* /.form-group */}
                    <div className="form-group">
                      <label>Address</label>
                      <input
                        name="Address"
                        value={postItem.Address}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Address"
                      />
                    </div>
                    {/* /.form-group */}
                  </div>
                  <div className="col-md-6">
                    {/* /.form-group */}
                    <div className="form-group">
                      <label>Father's Name</label>
                      <input
                        name="FathersName"
                        value={postItem.FathersName}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Father's Name"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Mother's Name</label>
                      <input
                        name="MotherName"
                        value={postItem.MotherName}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Mother's Name"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Course</label>
                      <select
                        name="Course"
                        value={postItem.Course}
                        onChange={handleChange}
                        className="form-control select2"
                        style={{ width: "100%" }}
                      >
                        <option value="" disabled selected>
                          -Select-
                        </option>
                        {courses.map((item) => (
                          <option value="UPSC">{item.title}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Medium</label>
                      <select
                        name="Medium"
                        value={postItem.Medium}
                        onChange={handleChange}
                        className="form-control select2"
                        style={{ width: "100%" }}
                      >
                        <option value="" disabled selected>
                          -Selec-
                        </option>
                        <option value="Hindi">Hindi</option>
                        <option value="English">English</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Batch</label>
                      <select
                        name="Batch"
                        value={postItem.Batch}
                        onChange={handleChange}
                        className="form-control select2"
                        style={{ width: "100%" }}
                      >
                        <option value="Morning">Morning</option>
                        <option value="Afternoon">Afternoon</option>
                        <option value="Evening">Evening</option>
                      </select>
                    </div>
                    {/* /.form-group */}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Batch Time</label>
                      <input
                        type="time"
                        name="BatchTime"
                        value={postItem.BatchTime}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Batch Time"
                      />
                    </div>
                  </div>

                  <div />
                  <div className="card-footer">
                  {localStorage.getItem("studentDemoedit")==="yes"?<button
                      className="btn btn-primary"
                      onClick={() => postData()}
                    >
                      Update
                    </button>:null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Demo;
